export default {
  endpoints: {
    search: {
      route: "search",
      method: "POST",
    },
    getAllCostCenters: {
      route: "getAllCostCenters",
      method: "POST",
    },
  },
};
