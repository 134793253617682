export default {
  endpoints: {
    getAllDetailed: {
      route: "getAllDetailed",
      method: "POST",
    },
    getPreferredName: {
      route: ":id",
      method: "GET",
    },
    getDetailed: {
      route: "detailed/:id",
      method: "GET",
    },
    create: {
      route: "",
      method: "POST",
    },
    update: {
      route: ":id",
      method: "PUT",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
    resetPassword: {
      route: "password/:id",
      method: "DELETE",
    },
    search: {
      route: "search",
      method: "POST",
    },
    checkNameAvailability: {
      route: "checkUsernameAvailability",
      method: "POST",
    },
    checkEmailAvailability: {
      route: "checkEmailAvailability",
      method: "POST",
    },
    me: {
      route: "me",
      method: "GET",
      ignoreError: true,
    },
  },
};
