import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { EventType } from "@/graphql";

export const tileConfigs: TileConfigType = {
  AuditTile: {
    active: true,
    defaultProps: {
      name: "Audit",
      enabledAudits: [],
      lastAuditColor: "#007BFF",
      secondAuditColor: "#FFD700",
      ninetyDaysColor: "#40E0D0",
      reducingMethod: "",
      groupingMethod: "",
      flags: [],
      eventTypes: [EventType.Issue],
      meta: {
        enabledAudits: [],
      },
    },
    settingsModule: defineAsyncComponent(() => import("./tiles/AuditTileSettings.vue")),
    component: defineAsyncComponent(() => import("./tiles/AuditTile.vue")),
    icon: "fas fa-eye",
  },
};
