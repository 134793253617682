import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/quality/productFamilies",
    name: "QAProductFamilies",
    component: () => import("./views/QAProductFamilies.vue"),
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/productFamily/new",
    name: "QACreateProductFamily",
    component: () => import("./views/QACreateProductFamily.vue"),
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/productFamily/update/:id",
    name: "QAUpdateProductFamily",
    component: () => import("./views/QAUpdateProductFamily.vue"),
    props: (route) => {
      return {
        productFamilyId: parseInt(route.params.id.toString(), 10),
      };
    },
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/errorCatalogs",
    name: "QAErrorCatalogs",
    component: () => import("./views/QAErrorCatalogs.vue"),
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/errorCatalogue/new",
    name: "QACreateErrorCatalogue",
    component: () => import("./views/QACreateErrorCatalogue.vue"),
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/errorCatalogue/update/:id",
    name: "QAUpdateErrorCatalogue",
    component: () => import("./views/QAUpdateErrorCatalogue.vue"),
    props: (route) => {
      return {
        errorCatalogueId: parseInt(route.params.id.toString(), 10),
      };
    },
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/settings",
    name: "QASettings",
    component: () => import("./views/QASettings.vue"),
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/setting/site/:id",
    name: "QASetting",
    component: () => import("./views/QASetting.vue"),
    props: (route) => {
      return {
        siteId: parseInt(route.params.id.toString(), 10),
      };
    },
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/chart/pareto",
    name: "QAChartPareto",
    component: () => import("./views/QAChartPareto.vue"),
  },
  {
    path: "/quality/qaChecklists",
    name: "QAChecklists",
    component: () => import("./views/QAChecklists.vue"),
    meta: {
      rights: ["quality.admin"],
    },
  },
  {
    path: "/quality/qaChecklistCreate/:siteId",
    name: "QACreateChecklist",
    component: () => import("./views/QACreateChecklist.vue"),
    meta: {
      rights: ["quality.admin"],
    },
    props: (route) => {
      return {
        siteId: parseInt(route.params.siteId.toString(), 10),
        copyChecklistId:
          route.query.copyChecklistId != null
            ? parseInt(route.query.copyChecklistId.toString(), 10)
            : null,
      };
    },
  },
  {
    path: "/quality/qaChecklistUpdate/:id",
    name: "QAUpdateChecklist",
    component: () => import("./views/QAUpdateChecklist.vue"),
    meta: {
      rights: ["quality.admin"],
    },
    props: (route) => {
      return {
        id: parseInt(route.params.id.toString(), 10),
      };
    },
  },
];
export default router;
