<template>
  <div
    ref="appDiv"
    :style="`display: grid; grid-template-rows: ${
      isMonitorViewEnabled ? '0' : '3.5em'
    } minmax(0, auto) 0`"
    class="h-100 overflow-y-hidden"
  >
    <div class="header">
      <HeadNavbar v-if="!isMonitorViewEnabled" v-model:show-sidebar="showSidebar" />
    </div>
    <div class="main h-100">
      <SidebarComponent
        v-if="!isMonitorViewEnabled && showSidebar"
        class="doNotPrintMe"
        @open-command-menu="showCommandMenu = true"
      />
      <LISAThemeProvider class="rightContainer">
        <router-view
          ref="mainRouterView"
          class="router-view"
          :class="{ 'background-image': useBackgroundImage }"
        />
      </LISAThemeProvider>
    </div>

    <Placeholder />
    <RFIDScanComponent ref="rfidComponent" />
    <div id="LightBoxPortal" />
    <Toast position="bottom-right" />
    <ConfirmDialog />
    <GalleryService />
    <CommandVue v-if="isCommandMenuEnabled" v-model="showCommandMenu" />
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { DefaultApolloClient } from "@vue/apollo-composable";
import {
  defineComponent,
  provide,
  onMounted,
  ref,
  computed,
  WritableComputedRef,
  InjectionKey,
} from "vue";
import { useRoute } from "vue-router";
import Toast, { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
// eslint-disable-next-line no-restricted-imports
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import SidebarComponent from "@/components/menu/SidebarComponent.vue";
import HeadNavbar from "@/components/HeadNavbar.vue";
import Placeholder from "@/components/shared/PlaceholderComponent.vue";
import lisaBG from "@/assets/logos/lisa.png";
import smoothScroll from "@/functions/smoothScroll";
import { isModuleActive, LicenseModule } from "@/functions/moduleConfig";
import { setTheme } from "./Highcharts";
import { apolloClient } from "./graphql/apollo";
import RFIDScanComponent from "./components/rfid/RFIDScanComponent.vue";
import GalleryService from "./modules/galleryService/components/GalleryService.vue";
import CommandVue from "./modules/commandMenu/views/CommandMenu.vue";
import isModifierSet from "./functions/userModifier";
import { ModifierType } from "./functions/userModifierEnum";
import { setInitialScreenshotContainer } from "./functions/ScreenshotHelper";
import LISAThemeProvider from "./modules/theme/components/LISAThemeProvider.vue";

export const MonitorViewInjectionKey = Symbol("MonitorViewInjectionKey") as InjectionKey<
  WritableComputedRef<boolean>
>;

export default defineComponent({
  name: "App",
  components: {
    ConfirmDialog,
    HeadNavbar,
    SidebarComponent,
    Placeholder,
    RFIDScanComponent,
    Toast,
    GalleryService,
    CommandVue,
    LISAThemeProvider,
  },
  provide() {
    return {
      lisaLogo: () => lisaBG,
      smoothScroll: (top: number | undefined) => smoothScroll(top),
      disableRFID: () => {
        (this.$refs?.rfidComponent as any)?.disableRFID?.();
      },
      enableRFID: () => {
        (this.$refs?.rfidComponent as any)?.enableRFID?.();
      },
    };
  },
  setup() {
    const showCommandMenu = ref(false);
    const monitorViewRef = ref(false);
    const spinnerVisible = ref(false);
    const VITE_APP_MEET_MODULE_ENABLED = ref(isModuleActive(LicenseModule.MEET));
    const route = useRoute();
    const store = useStore();
    const toast = useToast();
    window.$toast = (message: ToastMessageOptions) => {
      /**
       * providing toast to be usable in apollo and api,
       * setting defaults.
       */
      return toast.add({
        life: 3000,
        ...message,
      });
    };
    /**
     * providing i18n to be usable in scripts outside of setup / vue context (apollo, api)
     */
    const i18n = useI18n();
    window.$i18n = i18n as any;

    window.$confirm = useConfirm();
    const isCommandMenuEnabled = computed(() => {
      return isModifierSet(ModifierType.enableSearchBar);
    });

    const isMonitorViewEnabled = computed({
      get() {
        return (route.meta?.hideNavbar as boolean) ?? monitorViewRef.value;
      },
      set(value) {
        monitorViewRef.value = value;
      },
    });
    provide(MonitorViewInjectionKey, isMonitorViewEnabled);

    const useBackgroundImage = computed((): any => {
      return route.meta?.backgroundImage;
    });

    provide(DefaultApolloClient, apolloClient);

    onMounted(async () => {
      if (store.state.login.user != null) {
        setTheme();
      }
      store.dispatch("clientCache/clearEventExportPromise");
    });

    const showSidebar = ref(true);

    const appDiv = ref<HTMLDivElement>();

    setInitialScreenshotContainer(
      computed(() => {
        return appDiv.value!;
      }),
    );

    return {
      appDiv,
      spinnerVisible,
      VITE_APP_MEET_MODULE_ENABLED,
      isMonitorViewEnabled,
      useBackgroundImage,
      showSidebar,
      isCommandMenuEnabled,
      showCommandMenu,
    };
  },
});
</script>

<style scoped>
.rotationTextWrapper {
  line-height: 30px;
  height: 2em;
  overflow: hidden;
  position: absolute;
  text-align: center;
}

.textAnimation {
  animation-name: head;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

@keyframes head {
  0% {
    transform: translateY(0);
  }

  19% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-30px);
  }

  29% {
    transform: translateY(-30px);
  }

  30% {
    transform: translateY(-60px);
  }

  39% {
    transform: translateY(-60px);
  }

  40% {
    transform: translateY(-90px);
  }

  49% {
    transform: translateY(-90px);
  }

  50% {
    transform: translateY(-120px);
  }

  59% {
    transform: translateY(-120px);
  }

  60% {
    transform: translateY(-150px);
  }

  69% {
    transform: translateY(-150px);
  }

  70% {
    transform: translateY(-180px);
  }

  79% {
    transform: translateY(-180px);
  }

  80% {
    transform: translateY(-210px);
  }

  89% {
    transform: translateY(-210px);
  }

  90% {
    transform: translateY(-240px);
  }

  99% {
    transform: translateY(-240px);
  }

  100% {
    transform: translateY(-270px);
  }
}
</style>

<style lang="scss">
body {
  margin: 0;
}

#app {
  height: 100%;
}

/* COMMON STYLES FOR FOLDER LIST */

.sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
  background-color: var(--secondary);
  color: white;
}
</style>
<style>
/* BUTTONS STYLING GLOBAL */
.btn.disabled,
.btn:disabled {
  opacity: 0.5;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>
<style>
.table.b-table thead tr th {
  background-color: var(--primary-contrast-color) !important;
  color: white !important;
}

.b-table .table-b-table-default {
  border: 0;
}

.b-table-sticky-header {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
#app {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.main {
  display: flex;
  width: 100vw;

  .rightContainer {
    position: relative;
    flex-grow: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    > * {
      overflow-y: auto;
    }
  }
}

@media print {
  body {
    height: auto !important;

    div {
      height: auto !important;
      grid-template-rows: 0px minmax(0px, auto) 0px !important;

      .main {
        height: auto !important;

        .rightContainer {
          overflow-x: visible !important;
          padding-top: 0 !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" src="@/assets/css/global.scss"></style>
