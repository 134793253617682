import { ActionTree, GetterTree, MutationTree } from "vuex";
import { DynamicForm } from "./helper/dynamicFormTypes";

type IDynamicForm = any; // TODO:

const localState = () => ({
  dynamicForms: [] as DynamicForm[],
});

export const getters: GetterTree<ReturnType<typeof localState>, any> = {
  getDynamicForms: async (state) => state.dynamicForms,
};

const actions: ActionTree<typeof localState, any> = {
  addForm(context, payload: IDynamicForm) {
    context.commit("addForm", payload);
  },
};

const mutations: MutationTree<ReturnType<typeof localState>> = {
  async addForm(state, payload: IDynamicForm) {
    const newForm: DynamicForm = {
      id: state.dynamicForms.length,
      name: payload.name,
      nodes: [],
      templateId: 0,
    };
    state.dynamicForms.push(newForm);
    return newForm;
  },
};

export const dynamicFormStore = {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};
