export default {
  endpoints: {
    getLocale: {
      route: ":id",
      method: "GET",
    },
    updateLanguage: {
      route: "updateLanguage/:id",
      method: "PUT",
    },
  },
};
