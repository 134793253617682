<template>
  <SidebarMenu
    :relative="true"
    :menu="menu"
    :show-child="collapsed"
    :collapsed="true"
    :show-one-child="true"
    :theme="theme"
    width-collapsed="43px"
    style="z-index: 16"
    @item-click="onItemClick"
    @toggle-collapse="switchCollapse"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { SidebarMenu } from "vue-sidebar-menu";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useUserMenu, MenuEntryType } from "@/functions/useUserMenu";
import isModifierSet from "@/functions/userModifier";
import { ModifierType } from "@/functions/userModifierEnum";

const emit = defineEmits(["openCommandMenu"]);
const { t } = useI18n();
const collapsed = ref(true);

const store = useStore();

const theme = computed((): undefined | string => {
  return store.getters["login/showDarkTheme"] ? undefined : "white-theme";
});

function switchCollapse(collapsedProp: boolean) {
  collapsed.value = collapsedProp;
}
function onItemClick(event: Event, item: MenuEntryType) {
  if (item.callback != null) {
    item.callback(event, item);
  }
}
const mac = navigator.platform?.toUpperCase().indexOf("MAC") >= 0;
const { menu: menuWithoutSearch } = useUserMenu();
const isCommandMenuEnabled = computed(() => {
  return isModifierSet(ModifierType.enableSearchBar);
});

const menu = computed<MenuEntryType[]>(() => {
  const entries: MenuEntryType[] = [];
  if (isCommandMenuEnabled.value === true) {
    entries.push({
      icon: "fas fa-magnifying-glass",
      index: -1,
      title: t("global.search"),
      badge: {
        text: mac ? "⌘ + K" : `${t("modules.commandMenu.views.CommandPlaceholder.ctrl")} + K`,
        class: "vsm--badge_default",
      },
      callback(event: Event, item: MenuEntryType): void {
        emit("openCommandMenu", event, item);
      },
    });
  }
  return [...entries, ...menuWithoutSearch.value];
});
</script>

<style lang="scss">
@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
.v-sidebar-menu.vsm_white-theme {
  background: #fafafa !important;
  border-color: #e9ecef;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  border-right-width: 0.5px;
}

.vsm--menu > .vsm--item > .vsm--link {
  height: 2.1875rem;
}

.vsm_collapsed,
.vsm_expanded {
  .vsm--item {
    .vsm--dropdown {
      .vsm--item {
        .vsm--link_level-2 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          border-bottom: 1px solid lightgrey;

          .vsm--title {
            font-size: 0.75rem;
          }
        }

        .vsm--dropdown {
          padding-top: 0 !important;

          .vsm--link_level-3 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .vsm--title {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }

  .vsm--link_level-2 {
    .vsm--arrow_open {
      display: none;
    }
  }
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  color: #707070 !important;
  height: 30px;
  width: 30px;
}

.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  color: white !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open.vsm--link_level-1 {
  color: white !important;
}

.v-sidebar-menu .vsm--link {
  font-size: 0.875rem !important;
  padding: 0.25rem !important;
}

.v-sidebar-menu .vsm--link.vsm--link_level-3 {
  padding-left: 25px !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--icon {
  font-size: 24px !important;
  background: transparent !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0 0 0 var(--primary-color) inset !important;
  box-shadow: 3px 0 0 0 var(--primary-color) inset !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon,
.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  color: white !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  box-shadow: 3px 0 0 0 var(--primary-color) inset !important;
}

.v-sidebar-menu .vsm--toggle-btn {
  height: 2.5rem !important;
}

.vsm--link {
  transition: none !important;
}

.slide-animation-enter-active,
.slide-animation-leave-active,
.expand-enter-active,
.expand-leave-active,
.fade-animation-enter-active,
.fade-animation-leave-active {
  transition: none !important;
}

.v-sidebar-menu {
  --vsm-primary-color: var(--primary-color);
}
</style>
