import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/hlp/export",
    name: "HLPExport",
    component: () => import("./views/HLPExport.vue"),
    meta: {
      rights: ["hlp.readWrite"],
    },
  },
];

export default router;
