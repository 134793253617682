import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/instance/outgoingList",
    name: "OutgoingInstanceList",
    component: () => import("./views/OutgoingInstanceList.vue"),
    meta: {
      rights: ["instance.admin"],
    },
  },
  {
    path: "/instance/incomingList",
    name: "IncomingInstanceList",
    component: () => import("./views/IncomingInstanceList.vue"),
    meta: {
      rights: ["instance.admin"],
    },
  },
  {
    path: "/instance/incoming/create",
    name: "CreateIncomingInstance",
    component: () => import("./views/CreateIncomingInstance.vue"),
    meta: {
      rights: ["instance.admin"],
    },
  },
  {
    path: "/instance/incoming/update/:id",
    name: "UpdateIncomingInstance",
    component: () => import("./views/UpdateIncomingInstance.vue"),
    props: (route) => {
      return {
        id: Number(route.params.id),
      };
    },
    meta: {
      rights: ["instance.admin"],
    },
  },
  {
    path: "/instance/outgoing/create",
    name: "CreateOutgoingInstance",
    component: () => import("./views/CreateOutgoingInstance.vue"),
    meta: {
      rights: ["instance.admin"],
    },
  },
  {
    path: "/instance/outgoing/update/:id",
    name: "UpdateOutgoingInstance",
    component: () => import("./views/UpdateOutgoingInstance.vue"),
    props: (route) => {
      return {
        id: Number(route.params.id),
      };
    },
    meta: {
      rights: ["instance.admin"],
    },
  },
];

export default router;
