import { ActionTree, GetterTree, MutationTree } from "vuex";
import { confirmModal } from "@/components/shared/form/ConfirmationModal";
import store from "./index";

const localState = {
  taskComment: {
    userId: null as null | number,
    date: null as null | number,
  },
};

export const getters: GetterTree<typeof localState, any> = {
  getTaskCommentatorId: (state) => {
    if (!store.getters["login/isAnonym"]) {
      return store.getters["login/userId"];
    }
    if (state.taskComment.userId == null || state.taskComment.date == null) {
      return undefined;
    }
    if (Date.now() - state.taskComment.date < 900000) {
      return state.taskComment.userId;
    }
    return undefined;
  },
};

const actions: ActionTree<typeof localState, any> = {
  async setTaskCommentatorId(context, payload) {
    if (payload == null || store.getters["login/isAnonym"] !== true) {
      return;
    }
    confirmModal({
      header: window.$i18n?.t("global.confirm"),
      message: window.$i18n?.t("components.shared.searchSelect.UserSelect.saveAnonymUser"),
      acceptLabel: window.$i18n?.t("global.save"),
      acceptIcon: "fas fa-save",
      accept() {
        context.commit("setTaskCommentatorId", {
          date: Date.now(),
          userId: payload,
        });
      },
    });
  },
};

const mutations: MutationTree<typeof localState> = {
  setTaskCommentatorId(state, payload) {
    state.taskComment.date = payload.date;
    state.taskComment.userId = payload.userId;
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};
