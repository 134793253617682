export default {
  endpoints: {
    getEvents: {
      route: "events/:id",
      method: "GET",
    },
    addEvent: {
      route: "addEvent",
      method: "POST",
    },
    updateEvent: {
      route: "updateEvent/:id",
      method: "PUT",
    },
    deleteEvent: {
      route: "deleteEvent/:id",
      method: "DELETE",
    },
    getCostCenter: {
      route: "getCostCenter/:id",
      method: "GET",
    },
  },
};
