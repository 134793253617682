import DomToImage from "dom-to-image-more";
import { ComputedRef, InjectionKey, Ref, computed, inject, provide } from "vue";
import { SQDCTileTypes } from "@/graphql";

export const ScreenshotContainerInjectionKey = Symbol(
  "ScreenshotContainerInjectionKey",
) as InjectionKey<ComputedRef<HTMLElement | undefined>>;

enum EnableAutomaticScreenshotInjectionKeyPayloadComponent {
  "FileComponent" = "FileComponent",
}

type EnableAutomaticScreenshotInjectionKeyPayload = Record<
  EnableAutomaticScreenshotInjectionKeyPayloadComponent,
  {
    enable: boolean;
    fullScreen?: boolean;
  }
>;

export const EnableAutomaticScreenshotInjectionKey = Symbol(
  "ScreenshotContainerInjectionKey",
) as InjectionKey<ComputedRef<EnableAutomaticScreenshotInjectionKeyPayload>>;

let initialScreenshotContainer: ComputedRef<HTMLElement>;

export function setInitialScreenshotContainer(screenshotContainerProp: ComputedRef<HTMLElement>) {
  initialScreenshotContainer = screenshotContainerProp;
  provide(ScreenshotContainerInjectionKey, screenshotContainerProp);
}

/**
 * TODO: remove need for Vue context
 */
export function useScreenshot(container?: ComputedRef<HTMLElement | undefined | null> | undefined) {
  /**
   * move this inject inside a setup context
   * will remove warnings
   */
  const oldScreenshotContainer = inject(
    ScreenshotContainerInjectionKey,
    computed(() => undefined),
  );
  const screenshotContainer = computed(() => container?.value ?? oldScreenshotContainer?.value);

  provide(ScreenshotContainerInjectionKey, screenshotContainer);

  async function getImage(fullScreen = false) {
    if (screenshotContainer.value == null) {
      return;
    }
    const data = await DomToImage.toPng(
      fullScreen ? initialScreenshotContainer.value : screenshotContainer.value,
      { bgcolor: "white" },
    );
    return data;
  }

  async function getBlob(fullScreen = false) {
    if (screenshotContainer.value == null) {
      return;
    }
    const data = await DomToImage.toBlob(
      fullScreen ? initialScreenshotContainer.value : screenshotContainer.value,
      { bgcolor: "white" },
    );
    return data;
  }

  return {
    getImage,
    getBlob,
    downloadImage: async function downloadScreenshot(fileName: string, fullScreen = false) {
      const data = await getImage(fullScreen);

      if (data == null) {
        return;
      }

      const link = document.createElement("a");
      link.download = fileName;
      link.href = data;
      link.click();
      link.remove();
    },
  };
}

export function waitForRef(ref: Ref<any>) {
  const time = new Date().valueOf();

  return new Promise<void>((resolve, reject) => {
    if (ref.value != null) {
      resolve();
    } else {
      const interval: number = window.setInterval(() => {
        if (ref.value) {
          clearInterval(interval);
          resolve();
        }
        if (new Date().valueOf() - time > 1000 * 5) {
          clearInterval(interval);
          reject();
        }
      }, 100);
    }
  });
}

export function isScreenShotIconEnabled(tileType: SQDCTileTypes) {
  return ![
    SQDCTileTypes.IFrameTile,
    SQDCTileTypes.OneDriveTile,
    SQDCTileTypes.PowerBITile,
  ].includes(tileType);
}
