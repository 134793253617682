export default {
  endpoints: {
    getLiable: {
      route: "liableContext",
      method: "POST",
    },
    getName: {
      route: "name/:id",
      method: "GET",
    },
    get: {
      route: ":id",
      method: "GET",
    },
    create: {
      route: "",
      method: "POST",
    },
    update: {
      route: ":id",
      method: "PUT",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
    search: {
      route: "search",
      method: "POST",
    },
  },
};
