import store from "../store";

export default {
  // When the bound element is inserted into the DOM...
  inserted: (el, binding) => {
    // Focus the element
    let permitted = false;
    let rights;
    if (Array.isArray(binding.value)) {
      rights = binding.value;
    } else {
      rights = [binding.value];
    }
    // If rights are given, check them (else return false)
    if (store.state.login.user.accessRights && rights) {
      if (binding.modifiers.some) {
        // Check if the user has some rights (OR)
        permitted = rights.some((right) => store.getters["login/hasAccessRight"](right));
      } else {
        // Check if user has all the requested rights (AND) (default case)
        permitted = rights.every((right) => store.getters["login/hasAccessRight"](right));
      }
      // Check if we should invert the result (and do so if requested)
      if (binding.modifiers.not) {
        permitted = !permitted;
      }
    }
    if (!permitted) {
      el.style.display = "none";
    }
  },
};
