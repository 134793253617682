export default {
  endpoints: {
    getAllDetailed: {
      route: "detailed",
      method: "GET",
    },
    getAll: {
      route: "",
      method: "GET",
    },
    get: {
      route: ":id",
      method: "GET",
    },
    search: {
      route: "search",
      method: "POST",
    },
    saveRight: {
      route: "saveRight",
      method: "POST",
    },
    getUserBySite: {
      route: "userBySite",
      method: "POST",
    },
    create: {
      route: "",
      method: "POST",
    },
    update: {
      route: ":id",
      method: "PUT",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
  },
};
