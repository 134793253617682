import { DateTime } from "luxon";
import { ActionListActionFieldsFragment, EventListEventFieldsFragment } from "@/graphql";
import { ActionFilters, EventFilters } from "./FilterTypes";

export function isEventActive(
  { updatedAt, eventFilters }: EventFilters,
  event: EventListEventFieldsFragment,
  boardId?: number,
) {
  if (updatedAt < DateTime.fromISO(event.updatedAt)) {
    return true;
  }
  return eventFilters.every((filter) => {
    return filter.isEventActive(event, boardId);
  });
}

export function isActionActive(
  { updatedAt, actionFilters }: ActionFilters,
  action: ActionListActionFieldsFragment,
) {
  if (updatedAt < DateTime.fromISO(action.updatedAt)) {
    return true;
  }
  return actionFilters.every((filter) => {
    return filter.isActionActive(action);
  });
}
