<template>
  <InputNumber
    v-bind="{ ...$attrs, ...$props }"
    :locale="$i18n.locale"
    :input-class="inputClassComputed"
    :max-fraction-digits="maxFractionDigits ?? 10"
  />
</template>

<script setup lang="ts">
// eslint-disable-next-line no-restricted-imports
import InputNumber, { InputNumberProps } from "primevue/inputnumber";
import { computed } from "vue";

/**
 * defaults are set because of vue bug with boolean collapsing to false
 * https://github.com/vuejs/core/issues/8576
 */
const props = withDefaults(
  defineProps<
    Omit<InputNumberProps, "locale"> & {
      rightAlign?: boolean;
    }
  >(),
  {
    inputClass: undefined,
    format: undefined,
    useGrouping: undefined,
    allowEmpty: undefined,
  },
);

const inputClassComputed = computed(() => {
  let result = props.rightAlign === true ? "text-right" : undefined;

  if (props.inputClass) {
    if (typeof props.inputClass === "string") {
      result = (result ?? "") + ` ${props.inputClass}`;
    } else if (result == null) {
      return props.inputClass;
    } else {
      return {
        [result]: !!props.rightAlign,
        ...props.inputClass,
      };
    }
  }
  return result;
});
</script>

<style lang="scss" scoped></style>
