export default {
  endpoints: {
    createEntity: {
      route: "createEntity/:id",
      method: "POST",
    },
    createCase: {
      route: "createCase/:id",
      method: "POST",
    },
    updateTemplate: {
      route: "updateTemplate/:id",
      method: "PUT",
    },
    createTemplate: {
      route: "",
      method: "POST",
    },
    getTemplates: {
      route: "",
      method: "GET",
    },
    getTemplate: {
      route: ":id",
      method: "GET",
    },
    getForTile: {
      route: "forTile/:id",
      method: "POST",
    },
    addDatapoint: {
      route: "addDatapoint",
      method: "POST",
    },
    getEntity: {
      route: "getEntity/:id",
      method: "GET",
    },
    searchEntity: {
      route: "searchEntity",
      method: "POST",
    },
    getCase: {
      route: "getCase/:id",
      method: "GET",
    },
    searchCase: {
      route: "searchCase",
      method: "POST",
    },
    deleteTemplate: {
      route: "deleteTemplate/:id",
      method: "DELETE",
    },
  },
};
