import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { translationFunctionCode } from "@/components/menu/SidebarHelper";

export type MenuEntryType = {
  href?: string;
  icon: string;
  index: number;
  title: string;
  badge?: { text: string; class?: string };
  child?: { title: string; child?: { title: string; href: string }[] }[];
  callback?: (event: Event, item: MenuEntryType) => void;
};

export function useUserMenu() {
  const store = useStore();
  const { t } = useI18n();

  const menu = computed((): MenuEntryType[] => {
    return (store.getters["login/userMenu"] ?? []).map((main: any) => {
      return {
        ...main,
        title: t(
          main.translationFunctionCode != null
            ? (translationFunctionCode(main.translationFunctionCode) ??
                `sidebar.${main.title}.title`)
            : `sidebar.${main.title}.title`,
        ),
        href: main.href ?? `/redirect/${main.title}`,
        child: main.child?.map((level1: any) => {
          return {
            ...level1,
            title: t(
              level1.translationFunctionCode != null
                ? (translationFunctionCode(level1.translationFunctionCode) ??
                    `sidebar.${main.title}.children.${level1.title}.title`)
                : `sidebar.${main.title}.children.${level1.title}.title`,
            ),
            child: level1.child?.map((level2: any) => {
              return {
                ...level2,
                title: t(
                  level2.translationFunctionCode != null
                    ? (translationFunctionCode(level2.translationFunctionCode) ??
                        `sidebar.${main.title}.children.${level1.title}.children.${level2.title}.title`)
                    : `sidebar.${main.title}.children.${level1.title}.children.${level2.title}.title`,
                ),
              };
            }),
          };
        }),
      };
    });
  });

  return {
    menu,
  };
}
