import { Router } from "@/typeDefinitions/custom/Router";

const CreateMMMaterial = () => import("./views/create/Material.create.vue");
const UpdateMMMaterial = () => import("./views/update/Material.update.vue");
const MMMaterials = () => import("./views/list/Material.list.vue");

const CreateMMVendor = () => import("./views/create/Vendor.create.vue");
const UpdateMMVendor = () => import("./views/update/Vendor.update.vue");

const MMVendors = () => import("./views/list/Vendor.list.vue");

const CreateMMCommodityCode = () => import("./views/create/CommodityCode.create.vue");
const UpdateMMCommodityCode = () => import("./views/update/CommodityCode.update.vue");
const MMCommodityCodes = () => import("./views/list/CommodityCode.list.vue");

const CreateMMContactPerson = () => import("./views/create/ContactPerson.create.vue");
const UpdateMMContactPerson = () => import("./views/update/ContactPerson.update.vue");
const MMContactPersons = () => import("./views/list/ContactPerson.list.vue");

const MMWarehouses = () => import("./views/list/Warehouse.list.vue");
const CreateMMWarehouse = () => import("./views/create/Warehouse.create.vue");
const UpdateMMWarehouse = () => import("./views/update/Warehouse.update.vue");

const MMStorageLocations = () => import("./views/list/StorageLocation.list.vue");
const CreateMMStorageLocation = () => import("./views/create/StorageLocation.create.vue");
const UpdateMMStorageLocation = () => import("./views/update/StorageLocation.update.vue");

const CreateMMTransportRequest = () => import("./views/create/Transfer.create.vue");

const ConsumeMaterialComponent = () => import("./views/UseMaterial.vue");
const StorageFillComponentComponent = () => import("./modules/StorageFill/StorageFill.index.vue");

const TransportRequestList = () => import("./views/list/TransportRequest.list.vue");
const TransportOrderList = () => import("./views/list/TransportOrder.list.vue");

const MMStockOverview = () => import("./views/list/MMStockOverview.list.vue");
const MMOrderOverview = () => import("./views/list/OrderOverview.list.vue");

const router: Router.LicensedRouterExportType = [
  {
    path: "/mm/mailDistribution",
    name: "MMMailDistribution",
    component: () => import("./modules/mailDistribution/MMMailDistribution.vue"),
    meta: {
      rights: ["mm.admin"],
    },
  },
  {
    path: "/mm/supplyPoints",
    name: "supplyPoints",
    component: () => import("./views/list/SupplyPoint.list.vue"),
  },
  {
    path: "/mm/orderMaterial",
    name: "orderMaterial",
    component: () => import("./components/OrderMaterialComponent.vue"),
  },
  {
    path: "/mm/stockOverview",
    name: "MMStockOverview",
    component: MMStockOverview,
  },
  {
    path: "/mm/orderOverview",
    name: "MMOrderOverview",
    component: MMOrderOverview,
  },
  {
    path: "/mm/transportOrder",
    name: "transportOrderList",
    component: TransportOrderList,
  },
  {
    path: "/mm/transportRequest",
    name: "transportRequestList",
    component: TransportRequestList,
  },
  {
    path: "/mm/storageFill",
    name: "MMStorageFill",
    component: StorageFillComponentComponent,
  },
  {
    path: "/mm/consumMaterial",
    name: "consumMaterial",
    component: ConsumeMaterialComponent,
  },
  {
    path: "/mm/transportRequest/new",
    name: "createTransportRequest",
    component: CreateMMTransportRequest,
  },
  {
    path: "/mm/warehouses/",
    name: "viewWarehouses",
    component: MMWarehouses,
  },
  {
    path: "/mm/warehouse/new",
    name: "CreateMMWarehouse",
    component: CreateMMWarehouse,
  },
  {
    path: "/mm/warehouse/:id",
    name: "UpdateMMWarehouse",
    component: UpdateMMWarehouse,
  },
  {
    path: "/mm/storageLocations/",
    name: "viewStorageLocations",
    component: MMStorageLocations,
  },
  {
    path: "/mm/storageLocation/new",
    name: "CreateMMStorageLocation",
    component: CreateMMStorageLocation,
  },
  {
    path: "/mm/storageLocation/:id",
    name: "updateMMstorageLocations",
    component: UpdateMMStorageLocation,
  },
  {
    path: "/mm/material",
    name: "overviewMaterial",
    component: MMMaterials,
  },
  {
    path: "/mm/material/new",
    name: "materialConfig",
    component: CreateMMMaterial,
  },
  {
    path: "/mm/material/:id",
    name: "UpdateMMMaterial",
    component: UpdateMMMaterial,
  },
  {
    path: "/mm/vendor",
    name: "overviewVendor",
    component: MMVendors,
  },
  {
    path: "/mm/vendor/new",
    name: "CreateVendor",
    component: CreateMMVendor,
  },
  {
    path: "/mm/vendor/:id",
    name: "updateVendor",
    component: UpdateMMVendor,
  },
  {
    path: "/mm/commoditycodes",
    name: "overviewCommoditCodes",
    component: MMCommodityCodes,
  },
  {
    path: "/mm/commoditycodes/new",
    name: "CreateMMCommodityCode",
    component: CreateMMCommodityCode,
  },
  {
    path: "/mm/commoditycodes/:id",
    name: "UpdateMMCommodityCode",
    component: UpdateMMCommodityCode,
  },
  {
    path: "/mm/contactPerson",
    name: "overviewContactPerson",
    component: MMContactPersons,
  },
  {
    path: "/mm/contactPerson/new",
    name: "contactPersonConfigCreate",
    component: CreateMMContactPerson,
  },
  {
    path: "/mm/contactPerson/:id",
    name: "contactPersonConfigUpdate",
    component: UpdateMMContactPerson,
  },
];

export default router;
