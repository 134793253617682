import { RouteRecordRaw } from "vue-router";

const Stamp = () => import("./view/StampView.vue");
const UserShift = () => import("./view/UserShift.vue");
const UserShiftOverview = () => import("./view/UserShiftOverview.vue");
const YearlyStampOverview = () => import("./view/YearlyStampOverview.vue");
const StampUserList = () => import("./view/StampUserList.vue");
const UserShiftTeamPlanning = () => import("./view/UserShiftTeamPlanning.vue");
const UserShiftTeamPlanningOverview = () => import("./view/UserShiftTeamPlanningOverview.vue");
const Holidays = () => import("./view/HolidayList.vue");
const HolidaysOverview = () => import("./view/HolidaysOverview.vue");
const Vacations = () => import("./view/VacationsOverview.vue");
const SiteConfigs = () => import("./view/SiteConfigsOverview.vue");
const CreateSiteConfig = () => import("./view/CreateSiteConfigView.vue");
const UpdateSiteConfig = () => import("./view/UpdateSiteConfigView.vue");
const TimeStampOverviewSubmittedSheets = () =>
  import("./view/TimeStampOverviewSubmittedSheets.vue");

const router: RouteRecordRaw[] = [
  {
    path: "/stamp/internal",
    name: "loggedInStamp",
    component: Stamp,
  },
  {
    path: "/stamp",
    name: "stamp",
    component: Stamp,
    meta: {
      hideNavbar: true,
      backgroundImage: true,
      noAuthNeeded: true,
      notSaveLastPage: true,
    },
  },
  {
    path: "/stamp/stampOverview/userList",
    name: "StampUserList",
    component: StampUserList,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/stampOverview/yearly",
    name: "YearlyStampOverview",
    component: YearlyStampOverview,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/stampOverview/self",
    name: "StampOverviewSelf",
    component: () => import("./view/TCMonthlyStampOverview.vue"),
    props: (route) => {
      return {
        selectedDate: route.query.date,
      };
    },
  },
  {
    path: "/stamp/stampOverview/daily",
    name: "StampOverviewDaily",
    component: () => import("./view/TCDailyStampOverview.vue"),
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/stampOverview/:id",
    name: "StampOverview",
    component: () => import("./view/TCMonthlyStampOverview.vue"),
    props: (route) => {
      return {
        userIdProp: parseInt(route.params.id.toString(), 10),
        selectedDate: route.query.date,
      };
    },
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/userShift/:id",
    name: "UserShift",
    component: UserShift,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/userShiftOverview",
    name: "UserShiftOverview",
    component: UserShiftOverview,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/userShiftAssociation/:id",
    name: "UserShiftTeamPlanning",
    component: UserShiftTeamPlanning,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/userShiftAssociations",
    name: "UserShiftTeamPlanningOverview",
    component: UserShiftTeamPlanningOverview,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/holidays",
    name: "HolidaysOverview",
    component: HolidaysOverview,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/holidays/:id",
    name: "Holidays",
    component: Holidays,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
    props: (route) => {
      return {
        id: Number(route.params.id.toString()),
      };
    },
  },
  {
    path: "/stamp/vacationOverview/:view",
    name: "Vacations",
    component: Vacations,
    props: (route) => ({
      userView: route.params.view === "self",
    }),
  },
  {
    path: "/stamp/submittedSheetsOverview",
    name: "timeStampOverviewSubmittedSheets",
    component: TimeStampOverviewSubmittedSheets,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/siteConfigs",
    name: "SiteConfigs",
    component: SiteConfigs,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/siteConfig",
    name: "CreateSiteConfig",
    component: CreateSiteConfig,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
  },
  {
    path: "/stamp/siteConfig/:id",
    name: "UpdateSiteConfig",
    component: UpdateSiteConfig,
    meta: {
      rights: ["timestampclock.readWrite"],
    },
    props: (route) => {
      return {
        id: Number(route.params.id.toString()),
      };
    },
  },
  {
    path: "/stamp/vacationPlanner/site",
    name: "timeStampVacationPlanner",
    component: () => import("./view/VacationPlanner.vue"),
    meta: {
      rights: ["timestampclock.readWrite"],
    },
    props: (route) => ({
      date: route.query.date,
    }),
  },
  {
    path: "/stamp/vacationPlanner/:id",
    name: "timeStampVacationPlannerUser",
    component: () => import("./view/VacationPlanner.vue"),
    props: (route) => ({
      userId: Number(route.params.id),
      date: route.query.date,
    }),
  },
  {
    path: "/stamp/attendanceList",
    name: "timeStampAttendanceList",
    component: () => import("./view/AttendanceList.vue"),
    meta: {
      rights: ["timestampclock.attendance"],
    },
  },
];

export default router;
