import { FilterEntity } from "../FilterTypes";
import { ActionType, EventType } from "@/graphql";
import { actionIdPrefix, eventIdPrefix } from "../../event/EventActionIdPrefix";

export function getEventTypeFromInput(prefix: string, mode?: FilterEntity) {
  if (mode == null || mode === FilterEntity.ACTION) {
    switch (prefix.toUpperCase()) {
      case actionIdPrefix[ActionType.Task]:
        return "Task";
      case actionIdPrefix[ActionType.PSC]:
        return "PSC";
      case actionIdPrefix[ActionType.IHOrder]:
        return "IHOrder";
      default:
    }
  }

  if (mode == null || mode === FilterEntity.EVENT) {
    switch (prefix.toUpperCase()) {
      case eventIdPrefix[EventType.AuditResult]:
        return "AuditResult";
      case eventIdPrefix[EventType.IHFailureNotice]:
        return "IHFailureNotice";
      case eventIdPrefix[EventType.IHMaintenance]:
        return "IHMaintenance";
      case eventIdPrefix[EventType.Issue]:
        return "Issue";
      case eventIdPrefix[EventType.HLPIdea]:
        return "HLPIdea";
      case eventIdPrefix[EventType.ChangeRequest]:
        return "ChangeRequest";
      case eventIdPrefix[EventType.QualityAssurance]:
        return "QualityAssurance";
      default:
    }
  }
  return null;
}

/**
 * function to check if type is a event or an action
 */
export function isTypeSQDCEvent(type: string) {
  return Object.keys(EventType).includes(type);
}
