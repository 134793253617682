<template>
  <ValidateForm
    :key="name"
    ref="form"
    v-slot="data"
    v-bind="$attrs"
    :validate-on-mount="true"
    :validate-on-blur="true"
    :validate-on-change="true"
    :validate-on-input="true"
    :validate-on-model-update="true"
    @submit.stop="$emit('submit', $event)"
  >
    <slot
      :errors="data.errors"
      :valid="data.meta.valid"
      :invalid="!data.meta.valid"
      :dirty="data.meta.dirty"
      :touched="data.meta.touched"
      :meta="data.meta"
      :data="data"
    ></slot>
  </ValidateForm>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, computed } from "vue";
import { Form as ValidateForm, FormContextKey, useField } from "vee-validate";

export default defineComponent({
  name: "ValidationObserver",
  components: {
    ValidateForm,
  },
  props: {
    noPropagation: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: {
    /**
     *
     * @param value the type is from vee-validate (GenericFormValues) but it could also be the KeyboardEvent
     */
    submit: (value: Record<string, unknown> | KeyboardEvent) => value,
  },
  setup(props) {
    const form = ref<InstanceType<typeof ValidateForm>>();

    const { setValue } = useField(
      computed(() => `ObserverField:${props.name}`),
      { required: true },
      {
        initialValue: true,
      },
    );
    setValue(true);

    onMounted(() => {
      watch(
        // @ts-expect-error is used to provide valid context to an upper observer
        form.value?.$.provides[FormContextKey].meta,
        (meta) => {
          setValue(props.noPropagation === true ? true : meta?.valid);
        },
        {
          deep: true,
        },
      );
    });

    function reset() {
      form.value?.resetForm?.();
    }
    async function validate() {
      const data = await form.value?.validate?.();
      return data?.valid ?? false;
    }
    return {
      form,
      reset,
      validate,
    };
  },
});
</script>
