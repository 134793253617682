export default {
  endpoints: {
    // Templates
    getTemplates: {
      route: "template",
      method: "GET",
    },
    getTemplate: {
      route: "template/:id",
      method: "GET",
    },
    createTemplate: {
      route: "template",
      method: "POST",
    },
    updateTemplate: {
      route: "template/:id",
      method: "PUT",
    },
    deleteTemplate: {
      route: "template/:id",
      method: "DELETE",
    },

    // Boards
    getBoardHierarchy: {
      route: "boardHierarchy",
      method: "GET",
    },
    getBoardForSettings: {
      route: "boardForSettings/:id",
      method: "GET",
    },
    deleteBoard: {
      route: "board/:id",
      method: "DELETE",
    },

    // Tile
    updateTile: {
      route: "tile/:id",
      method: "PUT",
    },
    createTile: {
      route: "tile",
      method: "POST",
    },
    deleteTile: {
      route: "tile/:id",
      method: "DELETE",
    },
    searchFreeSpaceInColumn: {
      route: "tile/searchFreeSpaceInColumn",
      method: "POST",
    },
    // Layout
    updateLayout: {
      route: "layout",
      method: "PUT",
    },
    // deprecated
    searchTemplateByName: {
      route: "template/searchByName",
      method: "POST",
    },
    searchBoardByName: {
      route: "board/searchByName",
      method: "POST",
    },
    getBoard: {
      route: "getBoard/:id",
      method: "POST",
    },
  },
};
