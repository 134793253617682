<template>
  <Galleria
    v-model:visible="visible"
    :active-index="activeIndexRef"
    :value="images"
    :num-visible="images.length"
    container-style="min-width: 70vw; height: 90vh"
    :circular="true"
    :full-screen="true"
    :show-item-navigators="true"
    :base-z-index="1400"
    :auto-play="false"
    :pt="{
      content: {
        style: {
          height: '100%',
        },
      },
      itemsContainer: {
        style: {
          height: '100%',
          background: 'var(--surface-0)',
        },
      },
      thumbnails: {
        style: {
          'border-top': '1px solid black',
          'z-index': '1400',
        },
      },
      thumbnailContent: {
        background: 'var(--surface-100) !important',
      },
      thumbnailItems: {
        style: {
          overflow: 'hidden',
        },
      },
    }"
    :show-thumbnail-navigators="false"
    @update:active-index="backToNormalSize"
    @update:visible="backToNormalSize"
  >
    <template #item="slotProps">
      <div
        style="z-index: 1300 !important"
        class="h-100 w-100"
        @mousemove.prevent="handleDrag"
        @wheel="handleWheel"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @mouseup.prevent="endDrag"
        @mouseleave.prevent="endDrag"
      >
        <img
          v-if="slotProps.item.type === 'image'"
          :src="slotProps.item.itemImageSrc"
          :alt="slotProps.item.alt"
          class="itemDimensions"
          :style="{
            transform: `translate(${imagePosition.x}px, ${imagePosition.y}px) scale(${scale}) `,
            cursor: isDragging ? 'grabbing' : 'grab',
            'box-shadow': '0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important',
          }"
          @mousedown.prevent="startDrag"
          @touchstart="onTouchStart"
        />

        <video
          v-else
          class="itemDimensions"
          :src="slotProps.item.itemImageSrc"
          autoplay="true"
          controls
          muted
        ></video>
      </div>
    </template>
    <template #thumbnail="slotProps">
      <div>
        <img
          v-if="slotProps.item.type === 'image'"
          :src="slotProps.item.thumbnailImageSrc ?? slotProps.item.itemImageSrc"
          :alt="slotProps.item.alt"
          class="thumbnailDimensions"
        />
        <video
          v-else
          class="thumbnailDimensions"
          :src="slotProps.item.itemImageSrc"
          controls="false"
          muted
        ></video>
      </div>
    </template>
    <template #previousitemicon
      ><div @click="backToNormalSize">
        <i class="fas fa-chevron-left fa-2xl"></i></div
    ></template>
    <template #nextitemicon
      ><div @click="backToNormalSize">
        <i class="fas fa-chevron-right fa-2xl"></i></div
    ></template>
  </Galleria>
</template>

<script setup lang="ts">
/**
 * Usage:
 *
 * This is always available but not rendered when not visible.
 * You can manipulate it using the store.
 * Simply provide the data as shown below and it magically comes to life!
 *
 *
 *      store.dispatch("login/mutateGalleryServiceData", {
         visible: true,
         images: [{
              itemImageSrc: <minioURI>,
              thumbnailImageSrc: <minioURI *optional>,
              type: "image" // "video" | "image"
            }] as GalleryImage),
         ),
      });
 */
import { computed, nextTick, watch, ref } from "vue";

import Galleria from "primevue/galleria";
import { useStore } from "vuex";
import { GalleryImage } from "@/store/login";

const ZOOM_SPEED = 0.1;
const scale = ref(1);
let startDistance = 0;
const cursorPositionX = ref(0);
const cursorPositionY = ref(0);
const imagePosition = ref({ x: 0, y: 0 });
const isDragging = ref(false);
const lastMousePosition = ref({ x: 0, y: 0 });
const isZooming = ref(true);
const store = useStore();

const startDrag = (event: MouseEvent) => {
  isZooming.value = false;
  lastMousePosition.value = {
    x: event.clientX,
    y: event.clientY,
  };
  cursorPositionX.value = event.clientX;
  cursorPositionY.value = event.clientY;
  isDragging.value = true;
};

const handleDrag = (e: MouseEvent) => {
  if (!isDragging.value) return;
  const diff = {
    x: lastMousePosition.value.x - e.clientX,
    y: lastMousePosition.value.y - e.clientY,
  };
  imagePosition.value.x -= diff.x / 1;
  imagePosition.value.y -= diff.y / 1;
  lastMousePosition.value = {
    x: e.clientX,
    y: e.clientY,
  };
};

const endDrag = () => {
  isDragging.value = false;
};
const handleWheel = (e: WheelEvent) => {
  isZooming.value = false;
  if (e.deltaY > 0) {
    isZooming.value = false;
    const newZoom = scale.value <= 2 ? scale.value + ZOOM_SPEED : scale.value;
    if (scale.value <= 2) {
      scale.value = newZoom;
    }
  } else {
    isZooming.value = false;
    const newZoom = scale.value >= 0.2 ? scale.value - ZOOM_SPEED : scale.value;
    if (scale.value >= 0.2) {
      scale.value = newZoom;
    }
  }
};

const onTouchStart = (event: TouchEvent) => {
  isZooming.value = false;
  if (event.touches.length >= 2) {
    // if 2 touches then its a zoom
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    startDistance = Math.sqrt(dx * dx + dy * dy);
  } else if (event.touches.length === 1) {
    // if one touch then its a shift
    const touch = event.touches[0];
    lastMousePosition.value = {
      x: touch.clientX,
      y: touch.clientY,
    };
    isDragging.value = true;
  }
};

const onTouchMove = (event: TouchEvent) => {
  if (event.touches.length >= 2) {
    // if 2 touches then its a zoom
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    const currentDistance = Math.sqrt(dx * dx + dy * dy);
    const delta = currentDistance - startDistance;
    const newScale = scale.value + delta * 0.01;
    scale.value = Math.max(0.1, newScale);
    startDistance = currentDistance;
  } else if (event.touches.length === 1) {
    // if one touch then its a shift
    const touch = event.touches[0];
    const diff = {
      x: lastMousePosition.value.x - touch.clientX,
      y: lastMousePosition.value.y - touch.clientY,
    };
    imagePosition.value.x -= diff.x / scale.value;
    imagePosition.value.y -= diff.y / scale.value;
    lastMousePosition.value = {
      x: touch.clientX,
      y: touch.clientY,
    };
  }
};

const onTouchEnd = () => {
  startDistance = 0;
  isDragging.value = false;
};

function resetZoom() {
  scale.value = 1;
}

function backToNormalSize() {
  imagePosition.value = { x: 0, y: 0 };
  isZooming.value = false;
  resetZoom();
}
const visible = computed<boolean>({
  get() {
    return store.getters["login/getGalleryServiceData"].visible;
  },
  set(v: boolean) {
    store.dispatch("login/mutateGalleryServiceData", { visible: v });
  },
});
const images = computed<GalleryImage[]>(() => store.getters["login/getGalleryServiceData"].images);

const activeIndex = computed<number>(
  () => store.getters["login/getGalleryServiceData"].indexOfFirstElement,
);
const activeIndexRef = ref(activeIndex.value);

function handleKeyUp(e: KeyboardEvent) {
  if (e.key === "Escape") {
    visible.value = false;
  }
  if (e.key === "ArrowRight") {
    backToNormalSize();
    if (activeIndexRef.value === images.value.length - 1) {
      activeIndexRef.value = 0;
    } else {
      activeIndexRef.value += 1;
    }
  }
  if (e.key === "ArrowLeft") {
    backToNormalSize();
    if (activeIndexRef.value === 0) {
      activeIndexRef.value = images.value.length - 1;
    } else {
      activeIndexRef.value -= 1;
    }
  }
}
function detachListener(mask?: Element) {
  window.removeEventListener("mousemove", handleDrag);
  window.removeEventListener("mouseup", endDrag);
  window.removeEventListener("mousemove", handleDrag);
  window.removeEventListener("mouseup", endDrag);
  mask?.removeEventListener(
    "click",
    (e) => {
      if ((e.target as Element).classList.contains("p-galleria-mask")) {
        visible.value = false;
        detachListener(mask);
      }
    },
    false,
  );
  document.removeEventListener("keyup", handleKeyUp, false);
}

function attachListener(mask?: Element) {
  window.addEventListener("mousemove", handleDrag);
  window.addEventListener("mouseup", endDrag);
  mask?.addEventListener(
    "click",
    (e) => {
      if ((e.target as Element).classList.contains("p-galleria-mask")) {
        visible.value = false;
        detachListener(mask);
      }
    },
    false,
  );
  document.addEventListener("keyup", handleKeyUp, false);
}

watch(visible, async (val) => {
  await nextTick();
  if (val === true) {
    isZooming.value = true;
    const masks = document.querySelectorAll(".p-galleria-mask");
    backToNormalSize();
    attachListener(masks[0]);
    activeIndexRef.value = activeIndex.value;
  } else {
    const masks = document.querySelectorAll(".p-galleria-mask");
    detachListener(masks[0]);
  }
});
</script>
<style>
.p-galleria-mask {
  z-index: 20000 !important;
}
</style>
<style scoped>
.itemDimensions {
  max-width: 100%;
  display: block;
  max-height: 80vh !important;
}
.thumbnailDimensions {
  display: block;
  max-width: 80px;
  max-height: 100px;
  pointer-events: none;
}
</style>
<style scoped></style>
