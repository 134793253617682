import { ActionTree, GetterTree, MutationTree } from "vuex";

const localState = {
  activeRoomName: null as null | string,
  isMinimized: false as boolean,
};

const actions: ActionTree<typeof localState, any> = {
  updateActiveRoomName(context, payload) {
    context.commit("setActiveRoomName", payload);
  },
  updateIsMinimized(context, payload) {
    context.commit("setIsMinimized", payload);
  },
};

const mutations: MutationTree<typeof localState> = {
  setActiveRoomName(state, activeRoomName) {
    state.activeRoomName = activeRoomName;
    // If the room name changes, show the window
    state.isMinimized = false;
  },
  setIsMinimized(state, isMinimized) {
    state.isMinimized = isMinimized;
  },
};

const getters: GetterTree<typeof localState, any> = {
  activeRoomName: (state) => state.activeRoomName,
  isMinimized: (state) => state.isMinimized,
};

export default {
  namespaced: true,
  state: localState,
  actions,
  mutations,
  getters,
};
