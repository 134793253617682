import { RouteRecordRaw } from "vue-router";

const router: RouteRecordRaw[] = [
  {
    path: "/shifts/",
    name: "ShiftScheduleTemplateList",
    component: () => import("@/modules/shift/views/ShiftScheduleTemplateList.vue"),
  },
  {
    path: "/shifts/:id?",
    name: "ShiftScheduleTemplate",
    component: () => import("@/modules/shift/views/ShiftScheduleTemplate.vue"),
    props(route) {
      return {
        id: Number.isNaN(Number(route.params.id)) ? undefined : Number(route.params.id),
      };
    },
  },
  {
    path: "/shiftSchedule",
    name: "ShiftSchedule",
    component: () => import("@/modules/shift/views/ShiftSchedule.vue"),
  },
];

export default router;
