import type { DateTimeFormatOptions } from "luxon";

const options: Record<string, DateTimeFormatOptions> = Object.freeze({
  date: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  dayShort: {
    weekday: "short",
  },
  dateShort: {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  },
  dateLong: {
    year: "numeric",
    month: "short",
    day: "2-digit",
  },
  dateWeekdayShort: {
    weekday: "short",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  },
  dateWeekday: {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  dateWeekdayLong: {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  dateWeekdayExtraLong: {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
  },
  weekdayLong: {
    weekday: "long",
  },
  weekdayShort: {
    weekday: "short",
  },
  time: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  timeShort: {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  },
  timeShortMS: {
    minute: "2-digit",
    second: "2-digit",
  },
  datetimeShort: {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  },
  datetime: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  },
  datetimeLong: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  monthShort: {
    month: "short",
    year: "numeric",
  },
  monthLong: {
    month: "long",
    year: "numeric",
  },
  onlyMonthLong: {
    month: "long",
  },
  onlyMonthShort: {
    month: "short",
  },
  month: {
    year: "numeric",
    month: "short",
  },
  year: {
    year: "numeric",
  },
  // Used for BaseTile history mode
  years: {
    year: "numeric",
  },
  months: {
    month: "short",
    year: "numeric",
  },
  days: {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  },
  day: {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  },
  // Used for data table
  secondDT: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  minuteDT: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  hourDT: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  dayDT: {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  },
  monthDT: {
    month: "short",
    year: "numeric",
  },
  yearDT: {
    year: "numeric",
  },
   
  business_yearDT: {
    year: "numeric",
  },
  // Used for Calendar
  calendarFormat: {
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  },
});

export default options;
