export default {
  endpoints: {
    getHolidays: {
      route: "holidays/:id",
      method: "GET",
    },
    createHoliday: {
      route: "holiday",
      method: "POST",
    },
    deleteHoliday: {
      route: "holiday/:id",
      method: "DELETE",
    },
    getVacations: {
      route: "vacation/get",
      method: "POST",
    },
    createVacation: {
      route: "vacation",
      method: "POST",
    },
    updateVacation: {
      route: "vacation/:id",
      method: "PUT",
    },
    submitMonth: {
      route: "timeSheet/monthlySubmit",
      method: "POST",
    },
    getSubmittedMonth: {
      route: "timeSheet/getMonthlySubmitted",
      method: "POST",
    },
    getAllSubmittedTimeSheets: {
      route: "timeSheet/getSubmitted/:id",
      method: "POST",
    },
    approveTimeSheet: {
      route: "timeSheet/:id",
      method: "PUT",
    },
  },
};
