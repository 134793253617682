import { ActionTree, GetterTree, MutationTree } from "vuex";

import $api from "../api/api";

const localState = {
  shiftSchedules: {} as { [key: number]: shift.Schedule },
  shifts: {} as { [key: string]: shift.Shift },
};

export const getters: GetterTree<typeof localState, any> = {
  shiftSchedule: (state: typeof localState) => (shiftScheduleId: number) => {
    if (state.shiftSchedules[shiftScheduleId] instanceof Promise) {
      return null;
    }
    return state.shiftSchedules[shiftScheduleId];
  },
};

const actions: ActionTree<typeof localState, any> = {
  async get({ state, commit }, shiftScheduleId) {
    if (state.shiftSchedules[shiftScheduleId]) {
      return state.shiftSchedules[shiftScheduleId];
    }

    const fetchPromise = $api.shiftSchedules.get(shiftScheduleId);
    fetchPromise.id = shiftScheduleId;
    commit("setShiftSchedule", fetchPromise);
    const shiftSchedule = await fetchPromise;
    commit("setShiftSchedule", shiftSchedule);
    return shiftSchedule;
  },

  async getShifts({ state, commit }, options) {
    const key = `${options.scheduleId}|${options.from}|${options.to}`;

    if (state.shifts[key]) {
      return state.shifts[key];
    }

    const fetchPromise = $api.shiftSchedules.getShifts(options);

    commit("setShifts", { shifts: fetchPromise, key });
    const shifts = await fetchPromise;

    commit("setShifts", { shifts, key });
    return shifts;
  },
};

const mutations: MutationTree<typeof localState> = {
  setShiftSchedule(state, shiftSchedule) {
    state.shiftSchedules[shiftSchedule.id] = shiftSchedule;
  },
  setShifts(state, { shifts, key }) {
    state.shifts[key] = shifts;
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};
