export default {
  endpoints: {
    getSites: {
      route: "sites",
      method: "GET",
    },
    fetchIssueOverview: {
      route: "dataForIssueOverview",
      method: "POST",
    },
    fetchSiteAnalytic: {
      route: "dataForSiteAnalytic",
      method: "POST",
    },
    fetchDLZ: {
      route: "dataForDLZ",
      method: "POST",
    },
    fetchExport: {
      route: "dataForExport",
      method: "POST",
    },
    getSettingForSection: {
      route: "settings/:id",
      method: "GET",
    },
    saveSettingForSection: {
      route: "settings/:id",
      method: "POST",
    },
  },
};
