import { ChangeRequestStatus } from "@/graphql";
import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/changeRequest/create",
    name: "CreateChangeRequest",
    component: () => import("./views/CR/CreateCR.vue"),
    meta: {
      rights: ["changeRequest.write"],
    },
  },
  {
    path: "/changeRequest/review/:id",
    name: "ChangeRequestReview",
    component: () => import("./views/CR/ReviewCR.vue"),
    props: (route) => {
      return {
        changeRequestId: parseInt(route.params.id.toString(), 10),
      };
    },
    meta: {
      rights: ["changeRequest.write"],
    },
  },
  {
    path: "/changeRequest/analyze/:id",
    name: "AnalyzeChangeRequest",
    component: () => import("./views/CR/AnalyzeCR.vue"),
    props: (route) => {
      return {
        changeRequestId: parseInt(route.params.id.toString(), 10),
      };
    },
    meta: {
      rights: ["changeRequest.write"],
    },
  },
  {
    path: "/changeRequest/overview/submitted&edited",
    name: "ChangeRequestOverviewSubmittedEdited",
    component: () => import("./views/CR/CRSubmittedOverview.vue"),
    props: () => {
      return {
        changeRequestsStatus: ChangeRequestStatus.SUBMITTED,
      };
    },
    meta: {
      rights: ["changeRequest.read"],
    },
  },
  {
    path: "/changeRequest/overview/returned",
    name: "ChangeRequestOverviewReturned",
    component: () => import("./views/CR/CRReturnedOverview.vue"),
    props: () => {
      return {
        changeRequestsStatus: ChangeRequestStatus.RETURNED,
      };
    },
    meta: {
      rights: ["changeRequest.read"],
    },
  },
  {
    path: "/changeRequest/overview/opened",
    name: "ChangeRequestOverviewOpened",
    component: () => import("./views/CR/CROpenedOverview.vue"),
    props: () => {
      return {
        changeRequestsStatus: ChangeRequestStatus.OPENED,
      };
    },
    meta: {
      rights: ["changeRequest.read"],
    },
  },
  {
    path: "/changeRequest/overview/closed",
    name: "ChangeRequestOverviewClosed",
    component: () => import("./views/ClosedOverview.vue"),
    props: () => {
      return {
        changeRequestsStatus: ChangeRequestStatus.CLOSED,
      };
    },
    meta: {
      rights: ["changeRequest.read"],
    },
  },
  {
    path: "/changeRequest/overview/all",
    name: "ChangeRequestOverviewAll",
    component: () => import("./views/AllOverview.vue"),
    props: () => {
      return {
        changeRequestsStatus: "",
      };
    },
    meta: {
      rights: ["changeRequest.read"],
    },
  },
  {
    path: "/changeRequest/error/register",
    name: "RegisterError",
    component: () => import("./views/error/RegisterError.vue"),
  },
  {
    path: "/changeRequest/error/review/:id",
    name: "ReviewError",
    component: () => import("./views/error/ReviewError.vue"),
    props: (route) => {
      return {
        changeRequestId: parseInt(route.params.id.toString(), 10),
      };
    },
  },
  {
    path: "/changeRequest/overview/errors",
    name: "ErrorsOverview",
    component: () => import("./views/error/ErrorsOverview.vue"),
  },
];
export default router;
