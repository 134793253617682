/**
 * scroll smoothly to a element in the DOM.
 * @returns promise that gets resolved when scrolling is complete
 */
export default async (targetPosition = 0) => {
  window.scrollTo({
    top: targetPosition,
    behavior: "smooth",
  });

  return new Promise<void>((resolve, reject) => {
    const failed = setTimeout(() => {
      reject();
    }, 2000);

    const scrollHandler = () => {
      if (self.pageYOffset === targetPosition) {
        window.removeEventListener("scroll", scrollHandler);
        clearTimeout(failed);
        resolve();
      }
    };
    if (self.pageYOffset === targetPosition) {
      clearTimeout(failed);
      resolve();
    } else {
      window.addEventListener("scroll", scrollHandler);
    }
  });
};
