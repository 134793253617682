import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/bde/dashboardOverview",
    name: "BDEDashboardOverview",
    component: () => import("./views/BDEDashboardOverview.vue"),
    meta: {
      rights: ["bde.write"],
    },
  },
  {
    path: "/bde/dashboardOverviewSmall",
    name: "BDEDashboardOverviewSmall",
    component: () => import("./views/BDEDashboardOverview.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: () => ({
      activeBreakpoint: true,
    }),
  },
  {
    path: "/bde/dashboard",
    name: "BDEDashboard",
    component: () => import("./views/BDEDashboard.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: (route) => {
      return {
        activeBreakpoint: route.query.activeBreakpoint === "true",
        tpId: Number(route.query.tpId),
        spectator: route.query.spectator === "true",
      };
    },
  },
  {
    path: "/bde/upload",
    name: "BDEUpload",
    component: () => import("./views/BDEUpload.vue"),
    meta: {
      rights: ["bde.write"],
    },
  },
  {
    path: "/bde/bdeEventRelations/:tpId",
    name: "BDEEventRelations",
    component: () => import("./views/BDEEventRelations.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        tpId: Number(route.params.tpId),
      };
    },
  },
  {
    path: "/bde/postProductionScreenOverview",
    name: "postProductionScreenOverview",
    component: () => import("./views/BDEDashboardOverview.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: {
      postProductionScreen: true,
    },
  },
  {
    path: "/bde/postProductionScreen",
    name: "BDEPostProductionScreen",
    component: () => import("./views/BDEPostProductionScreen.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: (route) => {
      return {
        tpId: Number(route.query.tpId),
      };
    },
  },
  {
    path: "/mqtt/deviceList",
    name: "MQTTDeviceList",
    component: () => import("./views/MQTTDeviceList.vue"),
    meta: {
      rights: ["bde.admin"],
    },
  },
  {
    path: "/mqtt/device/create/:siteId",
    name: "MQTTCreateDevice",
    component: () => import("./views/MQTTDeviceCreate.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        siteId: Number(route.params.siteId),
        tpId: Number(route.query.tpId),
      };
    },
  },
  {
    path: "/mqtt/device/update/:siteId/:tpId",
    name: "MQTTDeviceUpdate",
    component: () => import("./views/MQTTDeviceUpdate.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        tpId: Number(route.params.tpId),
        siteId: Number(route.params.siteId),
      };
    },
  },
  {
    path: "/bde/setting/update/:siteId/:tpId",
    name: "BDESettingUpdate",
    component: () => import("./views/BDESettingUpdate.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        siteId: Number(route.params.siteId),
        tpId: Number(route.params.tpId),
      };
    },
  },
  {
    path: "/mqtt/rule/:tpId",
    name: "MQTTSetRule",
    component: () => import("./views/MQTTSetRule.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        tpId: Number(route.params.tpId),
      };
    },
  },
  {
    path: "/bde/customerRequirementOverview/:productionPlanTemplateId",
    name: "BDECustomerRequirementsOverviewWithTemplate",
    component: () => import("./views/BDECustomerRequirementsOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
      };
    },
  },
  {
    path: "/bde/customerRequirementOverview",
    name: "BDECustomerRequirementsOverview",
    component: () => import("./views/BDEViewsOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: () => {
      return {
        detailRoute: "bde/customerRequirementOverview",
      };
    },
  },
  {
    path: "/bde/operatorOverview",
    name: "BDEOperatorOverview",
    component: () => import("./views/BDEOperatorOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
  },
  {
    path: "/bde/events/edit/:tpId",
    name: "BDEEventEdit",
    component: () => import("./views/BDEEventEdit.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        tpId: Number(route.params.tpId),
      };
    },
  },
  {
    path: "/bde/checklistCreate/:productionPlanTemplateId",
    name: "BDECreateChecklistConnection",
    component: () => import("./views/BDECreateChecklistConnection.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
      };
    },
  },
  {
    path: "/bde/checklistUpdate/:id",
    name: "BDEUpdateChecklistConnection",
    component: () => import("./views/BDEUpdateChecklistConnection.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        id: Number(route.params.id),
      };
    },
  },
  {
    path: "/bde/productionTimeline/:tpId",
    name: "BDEProductionTimeline",
    component: () => import("./views/BDEProductionTimeline.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        tpId: Number(route.params.tpId),
        siteId: Number(route.query.siteId),
      };
    },
  },
  {
    path: "/bde/productionPlanTemplateOverview",
    name: "BDEProductionPlanTemplateOverview",
    component: () => import("./views/BDEViewsOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: () => {
      return {
        detailRoute: "bde/productionPlanTemplate",
        createTo: "BDEProductionPlanTemplateCreate",
      };
    },
  },
  {
    path: "/bde/checklistOverview/:productionPlanTemplateId",
    name: "BDEChecklistOverviewSelect",
    component: () => import("./views/BDEChecklistOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
      };
    },
  },
  {
    path: "/bde/checklistOverview",
    name: "BDEChecklistOverview",
    component: () => import("./views/BDEViewsOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: () => {
      return {
        detailRoute: "bde/checklistOverview",
      };
    },
  },
  {
    path: "/bde/productionPlanTemplate/new/:siteId",
    name: "BDEProductionPlanTemplateCreate",
    component: () => import("./views/BDEProductionPlanTemplateCreate.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        siteId: Number(route.params.siteId),
      };
    },
  },
  {
    path: "/bde/productionPlanTemplate/:id",
    name: "BDEProductionPlanTemplateUpdate",
    component: () => import("./views/BDEProductionPlanTemplateUpdate.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        id: Number(route.params.id),
      };
    },
  },
  {
    path: "/bde/viewsOverview",
    name: "BDEViewsOverview",
    component: () => import("./views/BDEViewsOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: () => {
      return {
        detailRoute: "bde/view",
      };
    },
  },
  {
    path: "/bde/statusOverview",
    name: "BDEStatusOverview",
    component: () => import("./views/BDEViewsOverview.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: () => {
      return {
        detailRoute: "bde/status",
      };
    },
  },
  {
    path: "/bde/status/:productionPlanTemplateId",
    name: "BDEStatusSettings",
    component: () => import("./views/BDEStatusSettings.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
      };
    },
  },
  {
    path: "/bde/view/:productionPlanTemplateId",
    name: "BDEViewUpsert",
    component: () => import("./views/BDEViewUpsert.vue"),
    meta: {
      rights: ["bde.admin"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
      };
    },
  },
  {
    path: "/bde/operative/views",
    name: "BDEViewTpSelect",
    component: () => import("./views/BDEOperativeViewTpSelect.vue"),
    meta: {
      rights: ["bde.write"],
    },
  },
  {
    path: "/bde/operativeTP/view/:viewId/:productionPlanTemplateId/:isDashboard",
    name: "BDEViewSelect",
    component: () => import("./views/BDEOperativeViewSelect.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
        viewId: Number(route.params.viewId),
        isDashboard: route.params.isDashboard === "true",
      };
    },
  },
  {
    path: "/bde/operative/view/:viewId/:tpId",
    name: "BDEOperativeView",
    component: () => import("./views/BDEOperativeView.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: (route) => {
      return {
        tpId: Number(route.params.tpId),
        viewId: Number(route.params.viewId),
      };
    },
  },

  {
    path: "/bde/label",
    name: "BDELabelOverview",
    component: () => import("./views/BDEViewsOverview.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: () => {
      return {
        detailRoute: "bde/label-overview",
      };
    },
  },
  {
    path: "/bde/label/:productionPlanTemplateId",
    children: [
      {
        path: "create",
        name: "BDELabelCreate",
        component: () => import("./views/BDELabel/BDELabelCreate.vue"),
        meta: {
          rights: ["bde.write"],
        },
        props: (route) => {
          return {
            productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
          };
        },
      },
      {
        path: ":id",
        name: "BDELabelUpdate",
        component: () => import("./views/BDELabel/BDELabelUpdate.vue"),
        meta: {
          rights: ["bde.write"],
        },
        props: (route) => {
          return {
            id: Number(route.params.id),
            productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
          };
        },
      },
    ],
  },
  {
    path: "/bde/label-overview/:productionPlanTemplateId",
    name: "BDELabelOverviewTemplate",
    component: () => import("./views/BDELabel/BDELabelOverview.vue"),
    meta: {
      rights: ["bde.write"],
    },
    props: (route) => {
      return {
        productionPlanTemplateId: Number(route.params.productionPlanTemplateId),
      };
    },
  },
];

export default router;
