import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/ishikawa/new",
    name: "ishikawaCreate",
    component: () => import("./views/CreateIshikawa.vue"),
    props: { isNewIshikawa: true },
  },
  {
    path: "/ishikawa/edit/:id",
    name: "ishikawaEdit",
    component: () => import("./views/EditIshikawa.vue"),
    props: (route: { params: { id: number } }) => ({ ishikawaId: Number(route.params.id) }),
  },
  {
    path: "/ishikawa/overview",
    name: "ishikawaOverview",
    component: () => import("./views/OverviewIshikawa.vue"),
  },
];

export default router;
