import { Router } from "@/typeDefinitions/custom/Router";

const main = () => import("./views/ReportingMainWindow.vue");

const router: Router.LicensedRouterExportType = [
  {
    path: "/reporting",
    name: "Reporting",
    component: main,
  },
];

export default router;
