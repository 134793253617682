import * as Sentry from "@sentry/vue";
import { App } from "vue";
import router from "./router";
import { ENV } from "@/ENV";

export function generateTransactionId() {
  return Math.random().toString(36).slice(2);
}

/**
 * a runtime static transaction id.
 */
const runtimeStaticTransactionId = generateTransactionId();

export function transactionId() {
  return `${runtimeStaticTransactionId}_${generateTransactionId()}`;
}
export function initializeSentry(app: App<Element>, user?: { id: number }) {
  if (import.meta.env.PROD && !import.meta.env.BASE_URL.includes("deploy-preview")) {
    Sentry.init({
      app,
      dsn: "https://cbb7798fd0864865975a471366668b18@o1035142.ingest.sentry.io/6001913",
      integrations: [
        Sentry.browserTracingIntegration({
          router,
          tracingOrigins: ["localhost", "lisapp.de", /^\//],
          traceFetch: false,
        }),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [],
      logErrors: true,
      environment: ENV.VITE_APP_INSTANCE_KEY === "intern" ? "staging" : import.meta.env.MODE,
      enabled: import.meta.env.PROD,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      release: import.meta.env.__VERSION__,
      ignoreErrors: [
        // This error is not relevant, we'll ignore it
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        "ResizeObserver loop limit exceeded",
        // Remove the errors that occur as redacted in production.
        "REDACTED",
        // "Unable to fetch dynamic resource" is a very common issue that happens if there was a LISA update deployed in the mean time.
        "Unable to fetch dynamic resource",
      ],
    });
    Sentry.setTag("instance", ENV.VITE_APP_INSTANCE_KEY);
    if (user) {
      Sentry.setUser({
        id: `${user.id}`,
      });
    }
  }
}
