<template>
  <div>
    <div class="field" :class="{ 'mb-0': small }">
      <label v-if="displayName != null && !hideLabel" :for="name" :class="labelClass">
        {{ displayName }}
        <InfoTooltipVue v-if="tooltipOptions" class="ml-1" v-bind="tooltipOptions" />
        <slot name="displayNameAppend" />
      </label>
      <component
        :is="$slots['prepend'] != null || $slots['append'] != null ? InputGroup : 'div'"
        :class="{
          'mb-0': small,
        }"
      >
        <InputGroupAddon v-if="$slots['prepend'] != null" class="p-0">
          <slot name="prepend" />
        </InputGroupAddon>

        <slot
          :model-value="customModel"
          :required="isRequired"
          :disabled="formDisabled || disabled"
          :class="{ 'p-valid': state === true, 'p-invalid': state === false }"
          :state="state"
          :valid="state !== false"
          :submit="inputFrameProps.submit"
        >
          <component
            :is="inputTypeComputed"
            :id="name"
            v-model="customModel"
            :required="isRequired"
            :name="name"
            class="w-100"
            :class="{ 'p-valid': state === true, 'p-invalid': state === false }"
            :disabled="formDisabled || disabled"
            :placeholder="placeholder"
            :readonly="readonly"
            v-bind="$attrs"
          />
        </slot>
        <InputGroupAddon v-if="$slots['append'] != null" class="p-0" :class="appendClass">
          <slot name="append" />
        </InputGroupAddon>
      </component>
      <small v-if="invalidFeedback != null" style="color: var(--lisa-invalid)">
        {{ invalidFeedback }}
      </small>
      <small v-else-if="description">{{ description }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, PropType, VueElement, watch } from "vue";
import InputText from "primevue/inputtext";
import { useField } from "vee-validate";
import InfoTooltipVue from "../InfoTooltip.vue";
import { InputFrameInjectionKey } from "./InputFrameMode";
import LISAInputNumber from "@/components/primevueWrapper/LISAInputNumber.vue";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputGroup from "primevue/inputgroup";

const props = defineProps({
  modelValue: {
    type: [Object, String, Number, Array, Date, Boolean],
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  displayName: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    required: false,
    default: undefined,
  },
  rules: {
    type: Object as PropType<Record<string, unknown>>,
    required: false,
    default: () => ({}),
  },
  description: {
    type: String,
    required: false,
    default: undefined,
  },
  inputType: {
    type: Object as PropType<VueElement>,
    required: false,
    default: undefined,
  },
  tooltipOptions: {
    type: Object as PropType<InstanceType<typeof InfoTooltipVue>["$props"]>,
    required: false,
    default: null,
  },
  labelClass: {
    type: [String, Object],
    required: false,
    default: "",
  },
  type: {
    type: String as PropType<"number" | "text">,
    required: false,
    default: null,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    required: false,
    default: false,
  },
  appendClass: {
    type: [String, Object],
    required: false,
    default: "",
  },
  showErrorMessageAlways: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const inputFrameProps = inject(
  InputFrameInjectionKey,
  computed(() => ({ disable: false, submit: () => {} })),
);
const formDisabled = computed(() => inputFrameProps.value.disable);

const {
  errorMessage,
  value: customModel,
  meta,
  // eslint-disable-next-line vue/no-setup-props-destructure
} = useField(
  computed(() => props.name),
  computed(() => props.rules),
  {
    initialValue: props.modelValue,
    label: computed(() => `"${props.displayName}"`),
  },
);

watch(customModel, (value) => {
  if (customModel.value === props.modelValue) {
    return;
  }
  emit("update:modelValue", value);
});

watch(
  computed(() => props.modelValue),
  (value) => {
    if (customModel.value === value) {
      return;
    }
    customModel.value = value;
  },
);

const state = computed(() => {
  return Object.keys(props.rules).length > 0 ? meta.valid : undefined;
});

const invalidFeedback = computed(() => {
  if (state.value != null || props.showErrorMessageAlways) {
    if (errorMessage.value != null) {
      return errorMessage.value;
    }
    return undefined;
  }
  return undefined;
});

const inputTypeComputed = computed(() => {
  if (props.inputType) {
    return props.inputType;
  }
  if (props.type === "number") {
    return LISAInputNumber;
  }
  return InputText;
});

const isRequired = computed(() => props.rules != null && "required" in props.rules);
</script>

<style lang="scss" scoped></style>
