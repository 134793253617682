import { Router } from "@/typeDefinitions/custom/Router";

const CoreCompetenceEditor = () => import("./views/coreCompetences.vue");
const CompetenceMatrix = () => import("./views/competenceMatrix.vue");

const router: Router.LicensedRouterExportType = [
  {
    path: "/coreCompetenceEditor",
    name: "coreCompetenceEditor",
    component: CoreCompetenceEditor,
    meta: {
      rights: ["department.competences.write"],
    },
  },
  {
    path: "/competenceMatrix/:id",
    name: "competenceMatrix",
    component: CompetenceMatrix,
  },
];

export default router;
