export default {
  endpoints: {
    getAll: {
      route: "mail",
      method: "GET",
    },
    get: {
      route: "mail/:id",
      method: "GET",
    },
    create: {
      route: "mail",
      method: "POST",
    },
    update: {
      route: "mail",
      method: "PUT",
    },
    delete: {
      route: "mail/:id",
      method: "DELETE",
    },
  },
};
