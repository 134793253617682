import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { EventType } from "@/graphql";

export const tileConfigs: TileConfigType = {
  PowerBITile: {
    active: true,
    defaultProps: {
      name: "PowerBITile",
      eventTypes: [EventType.Issue],
      meta: {
        url: "",
        pageName: "",
        navContentPaneEnabled: true,
        filterPaneEnabled: true,
        filters: [],
      },
    },
    settingsModule: defineAsyncComponent(() => import("./components/PowerBITileSettings.vue")),
    component: defineAsyncComponent(() => import("./components/PowerBITile.vue")),
    icon: "fas fa-chart-simple",
  },
};
