<template>
  <div class="lisaBadge border-round" :style="colorProperties">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import chroma from "chroma-js";

const props = defineProps({
  color: {
    type: String,
    default: "#FFFFFF00",
  },
});
const colorProperties = computed(() => {
  const color = chroma.valid(props.color) ? chroma(props.color) : null;
  return {
    background: props.color,
    border: `1px solid ${color?.darken() ?? "lightGray"}`,
    color: color != null && color.luminance() < 0.5 ? "white" : "black",
  };
});
</script>

<style lang="scss" scoped>
.lisaBadge {
  display: inline-block;
  padding: 2.5px 7px;
  font-size: 1em;
  white-space: nowrap;
}
</style>
