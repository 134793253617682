<template>
  <div class="flex justify-content-between" @click.stop>
    <ValidationProvider v-slot="{ valid }" :model-value="selectedType" name="type" vid="type">
      <PrimeVueSelect
        v-model="selectedType"
        :class="valid ? 'p-valid' : 'p-invalid'"
        class="mr-2"
        auto-filter-focus
        filter
        show-clear
        :option-label="labelFormatter"
        :placeholder="$t('global.all')"
        :options="options.types"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ valid }"
      :model-value="selectedIDs"
      name="ids"
      :rules="{ requiredArrayEntryIfNot: selectedType == null }"
      immediate
    >
      <MultiSelect
        ref="idFilterRef"
        v-model="selectedIDs"
        class="w-100"
        display="chip"
        filter
        option-value="value"
        option-label="label"
        auto-filter-focus
        :class="valid ? 'p-valid' : 'p-invalid'"
        :options="options.ids"
        @click.stop
        @filter="searchChange"
        @change="toggle"
      />
    </ValidationProvider>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import MultiSelect from "primevue/multiselect";
import PrimeVueSelect from "primevue/select";
import IDFilter from "../filterImplementations/IDFilter";
import { FilterEntity } from "../FilterTypes";
import { getEventTypeFromInput } from "./FilterUIHelper";
import refilterEventsWithout from "./RefilterEventsWithout";
import ValidationProvider from "@/modules/veevalidate/components/ValidationProvider.vue";

const props = defineProps({
  mode: {
    required: true,
    type: Number as PropType<FilterEntity>,
  },
});
const { t } = useI18n();

const filteredEvents = refilterEventsWithout((filter) => !(filter instanceof IDFilter));
const selectedIDs = ref<number[]>([]);
const selectedType = ref<string | undefined>();
function labelFormatter(text: string) {
  return t(`global.EABTypes.${text}`);
}
const options = computed(() => {
  const types: Set<string> = new Set();
  const ids: Set<{ value: number; label: string }> = new Set();

  switch (props.mode) {
    case FilterEntity.EVENT:
      filteredEvents.value.activeEvents.forEach((event) => {
        types.add(event.__typename);
        if (selectedType.value == null || selectedType.value === event.__typename) {
          ids.add({ value: event.id, label: event.id.toString() });
        }
      });
      break;
    case FilterEntity.ACTION:
      filteredEvents.value.activeEvents
        .flatMap((event) => event.actions)
        .forEach((action) => {
          if (action.__typename) {
            types.add(action.__typename);
          }
          if (selectedType.value == null || selectedType.value === action.__typename) {
            ids.add({ value: action.id, label: action.id.toString() });
          }
        });
      break;
    default:
      return { types: [], ids: [] };
  }
  return {
    types: Array.from(types),
    ids: Array.from(ids).sort((a, b) => a.value - b.value),
  };
});

const idFilterRef = ref<InstanceType<typeof MultiSelect>>();

function searchChange(event: { originalEvent: Event; value: string }) {
  if (event.value.length > 0) {
    const eventActionType = getEventTypeFromInput(event.value[0], props.mode);
    if (eventActionType != null && options.value.types.includes(eventActionType)) {
      selectedType.value = eventActionType;
    }
  }
  if (idFilterRef.value != null && "filterValue" in idFilterRef.value) {
    idFilterRef.value.filterValue = event.value.replace(/\D/g, "") as any;
  }
}
function toggle() {
  idFilterRef.value?.hide();
}

function getFilter() {
  return new IDFilter(selectedIDs.value, selectedType.value);
}
function applyFilter(filter: IDFilter) {
  selectedIDs.value = filter.ids;
  selectedType.value = filter.type;
}
defineExpose({
  getFilter,
  applyFilter,
});
</script>

<style lang="scss" scoped></style>
