<template>
  <LModal
    :visible="modelValue"
    :title="compoundTitle"
    size="lg"
    auto-focus-button="ok"
    ok-variant="success"
    @update:visible="$emit('update:modelValue', false)"
    @ok="stampIn"
    @cancel="close"
  >
    <template #default="{ submit }">
      <div class="text-center bold">
        <span v-if="data.state === 'STAMPED_OUT'">
          {{ $t("components.rfid.doYouWantToStampIn") }}
        </span>
        <span v-else>{{ $t("components.rfid.doYouWantToStampOut") }}</span>
      </div>
      <div v-if="data.state === 'STAMPED_IN'">
        <hr />
        <span class="font-bold">{{ $t("components.rfid.header.breakTimeLabel") }}</span>
        <div>
          <div class="grid">
            <div class="col-3">
              <div class="flex flex-column">
                <LISAButton
                  v-for="time in breakTimeMutations"
                  :key="'timeButton' + time"
                  class="text-left text-overflow-ellipsis"
                  @click="mutateBreakTime('minus', time)"
                >
                  - {{ $t("views.rfid.durationInMinutesShort", { time }) }}
                </LISAButton>
              </div>
            </div>
            <div class="text-center col"><Calendar v-model="breakTimeDate" time-only inline /></div>
            <div class="text-right col-3">
              <div class="flex flex-column">
                <LISAButton
                  v-for="time in breakTimeMutations"
                  :key="'add' + time"
                  class="text-left text-overflow-ellipsis"
                  @click="mutateBreakTime('plus', time)"
                >
                  + {{ $t("views.rfid.durationInMinutesShort", { time }) }}
                </LISAButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InputRow
        v-model="comment"
        :name="commentTagName"
        :display-name="$t('license.timeStampClock.Stamp.comment')"
      >
        <template #default="args">
          <PrimeVueTextarea
            v-bind="args"
            v-model="comment"
            class="w-100"
            rows="8"
            @keydown.meta.enter="submit"
            @keydown.ctrl.enter="submit"
            @keyup="resetTimer"
          />
        </template>
      </InputRow>
      <hr />
      <template v-if="data.stampData">
        <span class="font-bold">{{ $t("components.rfid.header.lastStamps") }}</span>
        <table class="w-100">
          <thead>
            <tr>
              <th>{{ $t("components.rfid.fields.stampedIn") }}</th>
              <th>{{ $t("components.rfid.fields.stampedOut") }}</th>
              <th>{{ $t("components.rfid.fields.breakTime") }}</th>
              <th>{{ $t("components.rfid.fields.duration") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="stamp in data.stampData[0].stampData" :key="stamp.id">
              <td>{{ formatDate(stamp.timestampIn) }}</td>
              <td>
                <span v-if="stamp.timestampOut">{{ formatDate(stamp.timestampOut) }}</span>
                <span v-else style="color: lightgray">
                  <span :key="timerKey">{{ formatDate(new Date().toISOString()) }}</span>
                </span>
              </td>
              <td class="text-right">
                {{ stamp.break == null ? "-" : formatDuration(stamp.break) }}
              </td>
              <td class="text-right">{{ formatDuration(stamp.total ?? null) }}</td>
            </tr>
            <tr
              v-if="lastStamp && lastStamp.timestampOut == null"
              class="footer"
              style="color: gray"
            >
              <td></td>
              <td>~{{ $t("components.rfid.fields.total") }}</td>
              <td class="text-right"></td>
              <td :key="timerKey" class="text-right">~{{ formatDuration(expectedTotal) }}</td>
            </tr>
            <tr class="footer">
              <td></td>
              <td>{{ $t("components.rfid.fields.total") }}</td>
              <td class="text-right">{{ formatDuration(cumulatedTimes.break) }}</td>
              <td class="text-right">{{ formatDuration(cumulatedTimes.work) }}</td>
            </tr>
            <tr class="footer">
              <td></td>
              <td>{{ $t("components.rfid.fields.timeShould") }}</td>
              <td></td>
              <td class="text-right">{{ formatDuration(cumulatedTimes.workTimeShould) }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <hr />
    </template>
    <template #modal-ok>
      <i class="fas fa-lock mr-1" />
      <span v-if="data.state === TCStampState.STAMPED_OUT">{{
        $t("components.rfid.fields.stampIn")
      }}</span>
      <span v-else>{{ $t("components.rfid.fields.stampOut") }}</span>
    </template>

    <template #modal-cancel>
      <i class="fas fa-times mr-1"></i>
      {{ $t("global.cancel") }} (
      <VueCountdown
        ref="rfidCountdown"
        v-slot="{ seconds }"
        :key="countDownRerenderHack"
        :time="countdownTime"
        @progress="updateTime"
      >
        {{ seconds }}
      </VueCountdown>
      )
    </template>
  </LModal>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { DateTime, Duration } from "luxon";
import { v4 as getNewUUID } from "uuid";
import { useI18n } from "vue-i18n";
import LISAButton from "@LISAPrime/LISAButton.vue";
import { useToast } from "primevue/usetoast";
import PrimeVueTextarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import {
  TCGetStampStateForLoginQuery,
  TCGetStampStateForRfidQuery,
  useTCStampUserWithLoginMutation,
  useTCStampUserWithRfidMutation,
  TCStampState,
} from "@/graphql";
import LModal from "../LModal.vue";
import { LISA_DATE_FULL } from "@/i18n";
import { CombinedLoginPayload } from "@/license/timeStampClock/helper/TCTypes";
import { formatDuration } from "@/license/timeStampClock/helper/FormatDuration";
import InputRow from "@/components/shared/form/InputRow.vue";

const defaultTimerTimeoutTime = 30000;

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  data: {
    type: Object as () =>
      | TCGetStampStateForRfidQuery["TCGetStampStateForRfid"]
      | TCGetStampStateForLoginQuery["TCGetStampStateForLogin"],
    required: true,
  },
  login: {
    type: Object as () => CombinedLoginPayload,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);
const toast = useToast();
const { t } = useI18n();
const compoundTitle = computed(
  () => `${t("components.rfid.header.greeting")} ${props.data.user.preferredName}`,
);
const breakTimeMutations = ref([15, 30, 60]);
const breakTime = ref("00:00");

const countDownRerenderHack = ref(0);
const timerKey = ref(0);
function updateTime() {
  timerKey.value += 1;
}
const countdownTime = ref(defaultTimerTimeoutTime);
const timeout = ref<NodeJS.Timeout>();
const cumulatedTimes = computed(() => ({
  break: props.data
    .stampData![0].stampData.map((el) => el.break ?? 0)
    .reduce((lhs, rhs) => lhs + rhs, 0),
  work: props.data
    .stampData![0].stampData.map((el) => el.total ?? 0)
    .reduce((lhs, rhs) => lhs + rhs, 0),
  workTimeShould: props.data.stampData![0].workingTimeShould ?? 0,
}));

const lastStamp = computed(
  () =>
    props.data?.stampData?.[0].stampData?.[(props.data?.stampData?.[0].stampData.length ?? 1) - 1],
);

const expectedTotal = computed(() => {
  const start = DateTime.fromISO(lastStamp.value?.timestampIn ?? new Date().toISOString());
  const end = DateTime.fromISO(lastStamp.value?.timestampOut ?? new Date().toISOString());
  return end.diff(start).toMillis() + cumulatedTimes.value.work;
});

const { mutate: stampMutation } = useTCStampUserWithRfidMutation({});
const { mutate: stampLoginMutation } = useTCStampUserWithLoginMutation({});

const comment = ref("");

async function stampIn() {
  try {
    if ("code" in props.login) {
      await stampMutation({
        payload: {
          code: props.login.code,
          comment: comment.value,
          break: props.data.state === TCStampState.STAMPED_IN ? breakTime.value : undefined,
        },
      });
    }
    if ("username" in props.login) {
      await stampLoginMutation({
        payload: {
          username: props.login.username,
          password: props.login.password,
          comment: comment.value,
          break: props.data.state === TCStampState.STAMPED_IN ? breakTime.value : undefined,
        },
      });
    }
    toast.add({
      severity: "success",
      summary: t("global.information"),
      detail:
        props.data.state === TCStampState.STAMPED_OUT
          ? t("components.rfid.fields.stampedIn")
          : t("components.rfid.fields.stampedOut"),
      life: 5000,
    });
  } finally {
    emit("update:modelValue", false);
  }
}
function close() {
  emit("update:modelValue", false);
}

function resetTimer() {
  countDownRerenderHack.value += 1;
  clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    close();
  }, defaultTimerTimeoutTime);
}

function formatDate(date?: string) {
  if (date == null) {
    return "";
  }
  return DateTime.fromISO(date).toLocaleString(LISA_DATE_FULL);
}

const mutateBreakTime = (mod: "plus" | "minus", amount: number) => {
  const time = Duration.fromISOTime(breakTime.value)[mod]({ minutes: amount });
  if (time.valueOf() < 0) {
    breakTime.value = "00:00";
  } else {
    breakTime.value = time.toFormat("hh:mm");
  }
  resetTimer();
};

const breakTimeDate = computed({
  get() {
    return DateTime.fromFormat(breakTime.value, "hh:mm").toJSDate();
  },
  set(val: Date) {
    breakTime.value = DateTime.fromJSDate(val).toFormat("hh:mm");
  },
});

onMounted(() => {
  clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    close();
  }, defaultTimerTimeoutTime);
});

const commentTagName = getNewUUID();
</script>

<style lang="scss" scoped>
.footer {
  background-color: rgba(122, 122, 122, 0.1);
  font-weight: bold;
}

th {
  vertical-align: top !important;
  background: var(--primary-color) !important;
  color: var(--primary-contrast-color) !important;
}
td {
  padding: 8px;
}
tr:nth-child(even) {
  background-color: var(--surface-100);
}
</style>
