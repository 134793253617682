<template>
  <main class="box">
    <img v-if="show" class="image" :src="prepareImageURLs(image)" @click="hide" />
  </main>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { imageURLFromUUID } from "@/functions/imageURLFromUUID";
import isModifierSet from "@/functions/userModifier";
import { ModifierType } from "@/functions/userModifierEnum";

const visible = ref(true);

const store = useStore();

const image = computed(() => {
  return store.state.systemSettings.image;
});

const show = computed((): boolean => {
  return isModifierSet(ModifierType.clippy) && visible.value;
});

function hide() {
  visible.value = !visible.value;
}

function prepareImageURLs(newValue: string) {
  if (newValue) {
    return imageURLFromUUID(newValue);
  }
  return "";
}
</script>

<style scoped>
.box {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9001;
  width: 200px;
  height: 200px;
  pointer-events: none;
}

.image {
  z-index: 9001;
  float: right;
  max-width: 100%;
  max-height: 100%;
  pointer-events: all;
}
</style>
