import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { EventType } from "@/graphql";
import { LISAGraphAPI } from "./utils/useOneDrive";

const oneDrive = new LISAGraphAPI();

export const tileConfigs: TileConfigType = {
  OneDriveTile: {
    active: oneDrive.isConfigValid,
    defaultProps: {
      name: "OneDriveTile",
      eventTypes: [EventType.Issue],
      meta: {
        shares: [],
      },
    },
    settingsModule: defineAsyncComponent(() => import("./components/OneDriveTileSettings.vue")),
    component: defineAsyncComponent(() => import("./components/OneDriveTile.vue")),
    icon: "fas fa-cloud",
  },
};
