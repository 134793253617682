import { ActionTree, GetterTree, MutationTree } from "vuex";
import { MMStorageFillBasket, MMPreferredOrderData } from "@/license/mm/typeDefinitions";

import $api from "../api/api";

const localState = () => ({
  systemTree: {} as { [keys: number]: any },
  users: [] as main.User[],
  boards: [] as sqdc.Board[],
  lastLocale: null as null | string,
  translationInputLocale: "de",
  mm: {
    materialsStorage: [] as MMStorageFillBasket[],
    preferredOrderSettings: null as MMPreferredOrderData | null,
  },
  colors: [] as string[],
  lastUsedRoutes: [], // TODO: implement
  eventExport: {
    siteIds: [] as number[],
    boardIds: {} as Record<number, number[]>,
    promises: [] as {
      id: string;
      promise: Promise<any>;
      range: { start: string; end: string };
      resolved: boolean;
    }[],
  },
});

export const getters: GetterTree<ReturnType<typeof localState>, any> = {
  getLastLocale: (state) => state.lastLocale,
  systemTreeState: (state) => state.systemTree,
  getUsers: (state) => state.users,
  getBoards: (state) => state.boards,
  getTranslationInputLocale: (state) => state.translationInputLocale,
  getMMMaterialsStorage: (state) => state.mm.materialsStorage,
  getMMPreferredOrderSettings: (state) => state.mm.preferredOrderSettings,
  getRecentColorsForColorPicker: (state) => state.colors,
  getEventExportData: (state) => {
    return state.eventExport;
  },
};

const actions: ActionTree<typeof localState, any> = {
  /** eventExport stuff */
  setEventExportConfiguration(context, payload) {
    context.commit("setEventExportConfiguration", payload);
  },
  removeEventExportPromise(context, payload) {
    context.commit("removeEventExportPromise", payload);
  },
  clearEventExportPromise(context, payload) {
    context.commit("clearEventExportPromise", payload);
  },
  updateEventExportPromise(context, payload) {
    context.commit("updateEventExportPromise", payload);
  },
  addEventExportPromise(context, payload) {
    context.commit("addEventExportPromise", payload);
  },
  setTranslationInputLocale(context, payload) {
    context.commit("setTranslationInputLocale", payload);
  },
  setRecentColorsForColorPicker(context, payload) {
    context.commit("setRecentColorsForColorPicker", payload);
  },
  setMMPreferredOrderSettings(context, payload) {
    context.commit("setMMPreferredOrderSettings", payload);
  },
  setMMMaterialsStorage(context, payload: MMStorageFillBasket[]) {
    context.commit("setMMMaterialsStorage", payload);
  },
  setLastLocale(context, payload) {
    context.commit("setLastLocale", payload);
  },
  upsertBoard(context, payload) {
    context.commit("upsertBoard", payload);
  },
  setSystemTreeNode(context, payload) {
    context.commit("setSystemTreeNode", payload);
  },
  async fetchUsers(context, payload) {
    // @ts-expect-error will not be typed
    const users = context.state?.users.map((el) => el.id);
    const diff = payload.filter((id: number) => !users.includes(id));
    if (diff.length !== 0) {
      const newUsers = await $api.users.search({ explicit: diff });
      context.commit("setUsers", newUsers);
    }
  },
};

const mutations: MutationTree<ReturnType<typeof localState>> = {
  clearEventExportPromise(state) {
    state.eventExport.promises = [];
  },
  updateEventExportPromise(state, payload) {
    const index = state.eventExport.promises.findIndex((el) => el.id === payload.id);
    if (index > -1) {
      state.eventExport.promises[index] = {
        ...state.eventExport.promises[index],
        ...payload,
      };
    }
  },
  removeEventExportPromise(state, payload) {
    state.eventExport.promises = state.eventExport.promises.filter((el) => el.id !== payload);
  },
  addEventExportPromise(state, payload) {
    state.eventExport.promises.push(payload);
  },
  setEventExportConfiguration(state, payload) {
    state.eventExport.boardIds = payload.boardIds;
    state.eventExport.siteIds = payload.siteIds;
  },
  setMMPreferredOrderSettings(state, payload) {
    state.mm.preferredOrderSettings = payload;
  },
  setRecentColorsForColorPicker(state, payload) {
    state.colors = payload;
  },
  setMMMaterialsStorage(state, payload) {
    state.mm.materialsStorage = payload;
  },
  setTranslationInputLocale(state, payload) {
    state.translationInputLocale = payload;
  },
  setLastLocale(state, payload) {
    state.lastLocale = payload;
  },
  upsertBoard(state, payload) {
    const board = state.boards.findIndex((el) => el.id === payload.id);
    if (board > -1) {
      state.boards[board] = payload;
    } else {
      state.boards.push(payload);
    }
  },
  setUsers(state, payload) {
    payload.forEach((user: main.User) => {
      const exist = state.users.find((el) => el.id === user.id);
      if (exist == null) {
        state.users.push(user);
      }
    });
  },
  setSystemTreeNode(state, newState) {
    if (state.systemTree[newState.id]) {
      state.systemTree[newState.id].isExpanded = newState.isExpanded;
      return;
    }
    state.systemTree[newState.id] = { isExpanded: false };
    state.systemTree[newState.id].isExpanded = newState.isExpanded;
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};
