import { ENV } from "@/ENV";

export function isIntern() {
  return ENV.VITE_APP_INSTANCE_KEY === "intern";
}

export function isProduction() {
  return isIntern() || import.meta.env.MODE === "production";
}

export function isStaging() {
  return isIntern() === false && import.meta.env.MODE === "staging";
}

export function isDevelopment() {
  return import.meta.env.MODE === "development";
}
