import type { useI18n } from "vue-i18n";
import { actionIdPrefix, eventIdPrefix } from "@/components/event-action/event/EventActionIdPrefix";
import {
  GSSearchGloballyQuery,
  GSSearchResultActionMeta,
  GSSearchResultEventMeta,
  GSSearchResultTileMeta,
  GSSearchResultType,
} from "@/graphql";

export function getSearchResultName(
  searchResult: GSSearchGloballyQuery["GSSearchGlobally"][0],
  t: ReturnType<typeof useI18n>["t"],
) {
  /* eslint-disable no-case-declarations */
  switch (searchResult.type) {
    case GSSearchResultType.EVENT:
      const eventMeta = searchResult.meta as GSSearchResultEventMeta;
      return t("modules.commandMenu.views.CommandMenu.event", {
        eventId: `${eventIdPrefix[eventMeta.eventType]}${eventMeta.eventId}`,
        description: `${searchResult.meta.boardName} [${t("global.id")}:${
          searchResult.meta.boardId
        }]`,
      });
    case GSSearchResultType.ACTION:
      const actionMeta = searchResult.meta as GSSearchResultActionMeta;
      return t("modules.commandMenu.views.CommandMenu.action", {
        eventId: `${eventIdPrefix[actionMeta.eventType]}${actionMeta.eventId}`,
        actionId: `${actionIdPrefix[actionMeta.actionType]}${actionMeta.actionId}`,
        description: `${searchResult.meta.boardName} [${t("global.id")}:${
          searchResult.meta.boardId
        }]`,
      });
    case GSSearchResultType.TILE:
      const tileMeta = searchResult.meta as GSSearchResultTileMeta;
      const tileName = tileMeta.tileName.substring(0, 20);
      return t("modules.commandMenu.views.CommandMenu.tile", {
        tileName: `"${tileName}${tileMeta.tileName.length > 20 ? "..." : ""}"`,
        tileId: tileMeta.tileId,
        boardName: `"${tileMeta.boardName?.substring(0, 20 + 20 - tileName.length)}${
          tileMeta.boardName != null && tileMeta.boardName?.length > 20 + 20 - tileName.length
            ? "..."
            : ""
        }"`,
        boardId: tileMeta.boardId,
      });
    default:
      return "";
    /* eslint-enable no-case-declarations */
  }
}
