export default {
  endpoints: {
    get: {
      route: ":id",
      method: "GET",
    },
    getAll: {
      route: "search",
      method: "POST",
    },
    getReferences: {
      route: "allReferences/:id",
      method: "GET",
    },
    searchByName: {
      route: "search",
      method: "POST",
    },
    create: {
      route: "",
      method: "POST",
    },
    update: {
      route: ":id",
      method: "PUT",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
  },
};
