/* eslint-disable no-console */
import type Keycloak from "keycloak-js";
import { wsLink } from "@/graphql/apollo";

export async function startRefreshKeycloakAccessTokenInterval(
  keycloak: Keycloak,
  _forceRestart = false,
) {
  console.info(
    "%c[%cLISAAuth%c]: %cInitializing OAuth Refresh Module.",
    "color: white;",
    "color: orange; font-weight: bold",
    "color: white;",
    "color: inherit",
  );
  async function refresh() {
    console.info(
      "%c[%cLISAAuth%c]: %cRefresh Token.",
      "color: white;",
      "color: orange; font-weight: bold",
      "color: white;",
      "color: inherit",
    );
    if (keycloak.isTokenExpired(70)) {
      await keycloak.updateToken(-1);
      wsLink.client.terminate();
    }
  }

  let refreshInterval: number | null = null;
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
  // Token Refresh
  refreshInterval = window.setInterval(refresh, 5000);
  refresh();
}
