import { isModuleActive } from "@/functions/moduleConfig";

// import main api definitions
const apiDefinitions: {
  [key: string]: any;
} = {};

const apis: Record<string, { default: any }> = import.meta.glob("./*.js", {
  eager: true,
});

Object.keys(apis).forEach((module) => {
  if (!module.startsWith("./api")) {
    const path = `${module.slice(2).slice(0, -3)}`;
    apiDefinitions[path] = apis[module].default;
    apiDefinitions[path].path = path;
  }
});

const licenseApis: Record<string, { default: any }> = import.meta.glob("../license/**/api/*.js", {
  eager: true,
});

// import license api definitions
Object.entries(licenseApis).forEach(([path, module]) => {
  if (isModuleActive(path.slice(2).slice(0, -3).split("/")[2])) {
    const loadedModule = module;
    const path1 = path.slice(2).split("/")[2];
    const [path2] = path.slice(0, -3).split("/").slice(-1);
    apiDefinitions[`${path1}$${path2.charAt(0).toUpperCase() + path2.slice(1)}`] =
      loadedModule.default;
    apiDefinitions[`${path1}$${path2.charAt(0).toUpperCase() + path2.slice(1)}`].path =
      `${path1}/${path2}`;
  }
});

export default apiDefinitions;
