import { RouteRecordRaw } from "vue-router";

const router: RouteRecordRaw[] = [
  {
    path: "/sqdc/EABShorthandList",
    name: "EABShorthandList",
    component: () => import("./views/EABShorthandActions.vue"),
  },
  {
    path: "/sqdc/createEABShorthand",
    name: "CreateEABShorthand",
    component: () => import("./views/CreateEABShorthand.vue"),
  },
  {
    path: "/sqdc/updateEABShorthand/:id",
    name: "UpdateEABShorthand",
    component: () => import("./views/UpdateEABShorthand.vue"),
    props: (route) => {
      return {
        shorthandId: parseInt(route.params.id.toString(), 10),
      };
    },
  },
  {
    path: "/sqdc/dataMaintenance",
    name: "DataMaintenance",
    component: () => import("./views/DataMaintenance.vue"),
    meta: {
      rights: ["sqdc.dataMaintenance"],
    },
  },
  {
    path: "/sqdc/dataMaintenanceNew",
    name: "DataMaintenanceNew",
    component: () => import("./views/DataMaintenanceNew.vue"),
    meta: {
      rights: ["sqdc.dataMaintenance"],
    },
  },
];

export default router;
