<template>
  <div class="commandMenu">
    <div v-FocusTrap :class="{ 'p-3': !inline }">
      <InputGroup class="w-100">
        <InputGroupAddon class="p-0">
          <i class="pi pi-search" />
        </InputGroupAddon>
        <InputText
          v-model="searchText"
          v-Tooltip.focus.top="overlayText"
          :size="!inline ? 'large' : undefined"
          :autofocus="true"
          :class="{ 'p-valid': state === true, 'p-invalid': state === false }"
          :placeholder="$t('modules.commandMenu.views.CommandMenu.search')"
          class="w-100"
        />
      </InputGroup>
    </div>
    <Listbox
      ref="listboxRef"
      v-model="selectedResult"
      option-label="name"
      :options="results"
      @click="$emit('selected')"
    >
      <template #option="slotProps">
        <div class="flex align-items-center">
          <i
            class="mr-2"
            :class="getIcon(slotProps.option.searchResultType, slotProps.option.meta)"
          />
          <div>{{ slotProps.option.name }}</div>
        </div>
      </template>
    </Listbox>
    <div v-if="inline">
      <h3>{{ $t("modules.commandMenu.views.CommandMenu.description") }}</h3>
      {{ overlayText }}
    </div>
    <Popover
      v-else
      ref="overlayTextRef"
      :dismissable="false"
      :show-close-icon="inline"
      style="width: 50rem"
    >
      <h3>{{ $t("modules.commandMenu.views.CommandMenu.description") }}</h3>
      {{ overlayText }}
    </Popover>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import Listbox from "primevue/listbox";
import { useI18n } from "vue-i18n";
import vFocusTrap from "primevue/focustrap";
import InputText from "primevue/inputtext";
import vTooltip from "primevue/tooltip";
import {
  GSSearchResultActionMeta,
  GSSearchResultEventMeta,
  GSSearchResultTileMeta,
  GSSearchResultType,
} from "@/graphql";
import { tileConfigs } from "@/modules/tiles/tileConfigs";
import { ResultEntryType, SearchOptionMetaType } from "../helper/CommandMenuType";
import Popover from "primevue/popover";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";

const { t } = useI18n();
const emit = defineEmits<{
  selected: [];
}>();

const props = withDefaults(
  defineProps<{
    results: ResultEntryType<any>[];
    inline?: boolean;
    state?: boolean;
  }>(),
  {
    inline: false,
    state: undefined,
  },
);

const selectedResult = defineModel("selectedResult", {
  type: Object as () => ResultEntryType<any> | undefined,
  required: false,
  default: undefined,
});

const searchText = defineModel("searchText", {
  type: String,
  required: true,
});

const icons: Record<
  GSSearchResultType,
  string | ((searchResult: SearchOptionMetaType<any>) => string)
> = {
  [GSSearchResultType.MENU]: "fas fa-fighter-jet",
  [GSSearchResultType.EVENT]: "fas fa-spray-can",
  [GSSearchResultType.ACTION]: "far fa-comment",
  [GSSearchResultType.LOADING]: "fas fa-spinner fa-spin",
  [GSSearchResultType.EASTER_EGG]: "fas fa-dragon",
  [GSSearchResultType.TILE]: (searchResult: SearchOptionMetaType<any>) => {
    const meta = searchResult as GSSearchResultTileMeta;

    return tileConfigs[meta.tileType]?.icon ?? "";
  },
};

function getIcon(resultType: GSSearchResultType, meta: SearchOptionMetaType<any>) {
  const icon = icons[resultType as GSSearchResultType];
  if (typeof icon === "string") {
    return icons[resultType as GSSearchResultType];
  }
  return icon(meta);
}

const overlayText = ref<string>();

const overlayTextRef = ref<InstanceType<typeof Popover>>();
const listboxRef = ref<InstanceType<typeof Listbox> & { $el: HTMLElement }>();

function setOverlayText() {
  switch (selectedResult.value?.searchResultType) {
    case GSSearchResultType.EVENT:
      overlayText.value = (selectedResult.value.meta as GSSearchResultEventMeta).description;
      break;
    case GSSearchResultType.ACTION:
      overlayText.value = (selectedResult.value.meta as GSSearchResultActionMeta).description;
      break;
    case GSSearchResultType.TILE:
      // eslint-disable-next-line no-case-declarations
      const tileMeta = selectedResult.value.meta as GSSearchResultTileMeta;
      overlayText.value = t("modules.commandMenu.views.CommandMenu.tile", {
        tileName: tileMeta.tileName,
        tileId: tileMeta.tileId,
        boardName: tileMeta.boardName,
        boardId: tileMeta.boardId,
      });
      break;
    default:
      overlayText.value = undefined;
      break;
  }
  if (overlayText.value != null) {
    overlayTextRef.value?.hide();
    nextTick(() => {
      overlayTextRef.value?.show(new Event("click"), listboxRef.value?.$el);
    });
  } else {
    overlayTextRef.value?.hide();
  }
}

watch(
  computed(() => props.results),
  () => {
    if (props.results.length === 0) {
      selectedResult.value = undefined;
    } else if (selectedResult.value == null || !props.results.includes(selectedResult.value)) {
      [selectedResult.value] = props.results;
    }
  },
);

watch(
  computed(() => selectedResult.value),
  () => setOverlayText(),
);

function keyPressed(e: KeyboardEvent) {
  if (e.key === "Enter") {
    emit("selected");
    return;
  }
  let index = selectedResult.value?.index ?? 1;
  if (e.key === "ArrowUp") {
    if (index <= 0) {
      index = props.results.length - 1;
    } else {
      index -= 1;
    }
    selectedResult.value = props.results[index];
  }
  if (e.key === "ArrowDown") {
    if (index >= props.results.length - 1) {
      index = 0;
    } else {
      index += 1;
    }
    selectedResult.value = props.results[index];
  }
}

function globalKeyHandler(e: KeyboardEvent) {
  keyPressed(e);
}

onMounted(() => {
  window.addEventListener("keydown", globalKeyHandler, true);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", globalKeyHandler, true);
});
</script>

<style lang="scss" scoped>
.commandMenu {
  background-color: white;
  border-radius: 10px;

  .results {
    border-top: 1px solid #ccc;
    padding: 1em;
  }
}
</style>
