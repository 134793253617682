import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/audit",
    name: "AuditOverview",
    component: () => import("./views/AuditList.vue"),
  },
  {
    path: "/AuditResultList/:id",
    name: "AuditResultList",
    component: () => import("./views/AuditResultList.vue"),
  },
  {
    path: "/CreateAudit",
    name: "CreateAudit",
    component: () => import("./views/CreateAudit.vue"),
  },
  {
    path: "/UpdateAudit/:id",
    name: "UpdateAudit",
    component: () => import("./views/UpdateAudit.vue"),
    props: (route) => ({
      id: route.params?.id ? Number(route.params.id) : undefined,
    }),
  },
  {
    path: "/auditResult/:id",
    name: "AuditResult",
    component: () => import("./views/AuditResult.vue"),
  },
  {
    path: "/appstore",
    name: "AppStore",
    component: () => import("./views/AppStore.vue"),
  },
];

export default router;
