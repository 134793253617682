import { createApp, onMounted, provide, h, computed } from "vue";
import PrimeVue, { usePrimeVue } from "primevue/config";
// eslint-disable-next-line no-restricted-imports
import { useConfirm } from "primevue/useconfirm";
import "@fortawesome/fontawesome-free/css/all.css";
import "./veeValidate";
import "flag-icons/css/flag-icons.min.css";

import Vue2TouchEvents from "vue2-touch-events";
import responsive from "vue-responsive";
import VueKonva from "vue-konva";

import VueGridLayout from "vue3-drr-grid-layout";
import "vue3-drr-grid-layout/dist/style.css";

import VueLazyLoad from "vue-lazyload";
import "./assets/css/tree.css";
import ConfirmationService from "primevue/confirmationservice";
import ToastService, { ToastServiceMethods } from "primevue/toastservice";
import { useI18n } from "vue-i18n";
import { Settings } from "luxon";
import AccessRightDirective from "@/functions/accessRightDirective";

import PortalToBreadcrumbs from "@/components/shared/PortalToBreadcrumbs.vue";

import router from "@/router";
import App from "@/App.vue";
import { initializeSentry } from "./sentry";
import api from "@/api/api";

import { TimeZoneInjectionKey } from "./functions/timezoneUtil";
import i18n from "./i18n";
import store from "./store";

import HighchartsVue from "./Highcharts";

import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";

const MyPreset = definePreset(Aura, {
  //Your customizations, see the following sections for examples
  primitive: {
    borderRadius: {
      none: "0",
      xs: "2px",
      sm: "4px",
      md: "4px",
      lg: "4px",
      xl: "6px",
    },
  },
  semantic: {
    primary: {
      50: "{blue.50}",
      100: "{blue.100}",
      200: "{blue.200}",
      300: "{blue.300}",
      400: "{blue.400}",
      500: "{blue.500}",
      600: "{blue.600}",
      700: "{blue.700}",
      800: "{blue.800}",
      900: "{blue.900}",
      950: "{blue.950}",
    },
  },
});

import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css"; // utility classes
import { startRefreshKeycloakAccessTokenInterval } from "./keycloak/KeycloakRefresher";
import keycloakConfig, { KeycloakInjectionKey } from "./keycloak/KeycloakConfig";
import { initKeycloak } from "./keycloak/Keycloak";
import { OneDriveConfigInjectionKey, oneDriveConfig } from "./modules/oneDrive/utils/useOneDrive";
import { ToastMessageOptions } from "primevue/toast";

Settings.throwOnInvalid = true;

initKeycloak()
  .then(async ({ keycloak, auth }) => {
    if (auth === false) {
      window.location.replace(keycloakConfig.url);
    } else {
      const user = await store.dispatch("login/recoverSession");
      const app = createApp({
        setup() {
          window.$primevue = usePrimeVue();

          /** provide timezone */
          provide(
            TimeZoneInjectionKey,
            computed(() => Intl.DateTimeFormat().resolvedOptions().timeZone),
          );

          /** provide onedrive */
          provide(OneDriveConfigInjectionKey, oneDriveConfig);

          /** provide keycloak */
          provide(KeycloakInjectionKey, keycloak);
          onMounted(async () => {
            store.dispatch("language/setLocale", user.language);
            store.dispatch("systemSettings/fetchColors");
            store.dispatch("systemSettings/fetchColors");
            store.dispatch("systemSettings/fetchModuleFolders");
            store.dispatch("systemSettings/fetchBusinessYear");
            store.dispatch("systemSettings/fetchImage");
            store.dispatch("systemSettings/fetchLisaChartSettings");
            store.dispatch("systemSettings/fetchShowEisenhowerPriority");
            store.dispatch("systemSettings/fetchPLBSort");
            if (
              user.changelogNotification &&
              typeof import.meta.env.__VERSION__ === "string" &&
              user.lastLoginVersion.trim() !== import.meta.env.__VERSION__.trim() &&
              import.meta.env.PROD
            ) {
              router.push("/changelog/new");
            }
          });
          startRefreshKeycloakAccessTokenInterval(keycloak);
        },
        render: () => h(App),
      });

      app.use(store);
      app.use(i18n);
      app.use(router);
      app.use(ConfirmationService);

      app.use(PrimeVue, {
        ripple: false,
        theme: {
          preset: MyPreset,
          options: {
            prefix: "",
            // forbids dark mode
            darkModeSelector: ".fake-dark-selector", // trying to also force a non-usage of the dark mode
            cssLayer: {
              name: "primevue",
              // Enable PrimeVue CSS layer and configure the tailwind styles to have higher specificity with layering
              order: "tailwind-base, primevue, tailwind-utilities",
            },
          },
        },
      });
      app.use(VueGridLayout);

      // @ts-expect-error invalid type
      app.use(VueKonva);
      app.use(VueLazyLoad);

      initializeSentry(app, user);
      app.component("PortalBreadcrumbs", PortalToBreadcrumbs);
      app.use(ToastService);

      app.directive("access-right", AccessRightDirective);

      app.use(Vue2TouchEvents, {
        disableClick: true,
      });
      app.use(responsive);

      // @ts-expect-error invalid type
      app.use(HighchartsVue);
      // initializeBootstrap();

      if (import.meta.env.DEV) {
        app.config.performance = true;
      } else {
        app.config.performance = false;
        // app.config.globalProperties = false;
      }

      app.config.globalProperties.$$moment = (first: string, ...args: never[]) => {
        if (typeof first === "string") {
          return app.config.globalProperties.$moment(Date.parse(first), ...args);
        }
        return app.config.globalProperties.$moment(first, ...args);
      };

      app.config.globalProperties.$api = api;

      app.mount("#app");
    }
  })
  .catch((err: any) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });

interface EyeDropper {
  open: () => Promise<ColorSelectionResult>;
}

interface EyeDropperConstructor {
  new (): EyeDropper;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

declare global {
  interface Window {
    $toast?: (
      message: Required<Pick<ToastMessageOptions, "summary" | "detail">> &
        Omit<ToastMessageOptions, "summary" | "detail">,
    ) => ReturnType<ToastServiceMethods["add"]>;
    $i18n?: ReturnType<typeof useI18n>;
    $confirm?: ReturnType<typeof useConfirm>;
    $primevue?: ReturnType<typeof usePrimeVue>;
    EyeDropper: EyeDropperConstructor;
  }
  interface Document {
    scannerDetectionData: undefined | any;
  }
}
