import { NewsQueryTime } from "@/graphql";
import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/newsTab/:id",
    name: "NewsTabs",
    component: () => import("@/modules/news/views/NewsTabs.vue"),
    meta: {
      rights: ["news.write"],
    },
    props: (route) => {
      switch (route.params.id) {
        case "newsPlanned":
          return { queryTime: NewsQueryTime.FUTURE };
        case "newsArchiv":
          return { queryTime: NewsQueryTime.PAST };
        case "newsActive":
        default:
          return { queryTime: NewsQueryTime.CURRENT };
      }
    },
  },
  {
    path: "/news/new",
    name: "CreateNews",
    component: () => import("./views/CreateNews.vue"),
    meta: {
      rights: ["news.write"],
    },
  },
  {
    path: "/news/edit/:id",
    name: "EditNews",
    component: () => import("./views/UpdateNews.vue"),
    meta: {
      rights: ["news.write"],
    },
    props: (route) => ({ newsId: Number(route.params.id) }),
  },
];

export default router;
