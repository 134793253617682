import { ENV } from "@/ENV";

export enum LicenseModule {
  ASSIGNMENT,
  AUDIT,
  BIS,
  HLP,
  IDEA,
  MEET,
  REPORTING,
  TIMESTAMPCLOCK,
  COMPETENCEMATRIX,
  MM,
  LISACHART,
  IH,
  CHECKLIST,
  WORKFLOW,
  CUSTOMERMANAGEMENT,
  MQTT,
  INSTANCE,
  BDE,
  QUALITY,
}

// $t("global.module.ASSIGNMENT")
// $t("global.module.AUDIT")
// $t("global.module.BIS")
// $t("global.module.HLP")
// $t("global.module.IDEA")
// $t("global.module.MEET")
// $t("global.module.REPORTING")
// $t("global.module.TIMESTAMPCLOCK")
// $t("global.module.COMPETENCEMATRIX")
// $t("global.module.MM")
// $t("global.module.LISACHART")
// $t("global.module.IH")
// $t("global.module.CHECKLIST")
// $t("global.module.WORKFLOW")
// $t("global.module.CUSTOMERMANAGEMENT")
// $t("global.module.MQTT")
// $t("global.module.INSTANCE")
// $t("global.module.BDE")
// $t("global.module.QUALITY")
// $t("global.module.COMMON")

export const moduleEnabled: Record<LicenseModule, boolean> = {
  // All modules in /license have to appear here because of vite's production building
  [LicenseModule.ASSIGNMENT]: false,
  [LicenseModule.AUDIT]: ENV.VITE_APP_AUDIT_MODULE_ENABLED === "true",
  [LicenseModule.BIS]: ENV.VITE_APP_BIS_MODULE_ENABLED === "true",
  [LicenseModule.HLP]: ENV.VITE_APP_HLP_MODULE_ENABLED === "true",
  [LicenseModule.IDEA]: ENV.VITE_APP_IDEA_MODULE_ENABLED === "true",
  [LicenseModule.MEET]: true,
  [LicenseModule.REPORTING]: ENV.VITE_APP_REPORTING_MODULE_ENABLED === "true",
  [LicenseModule.TIMESTAMPCLOCK]: ENV.VITE_APP_TIMESTAMPCLOCK_MODULE_ENABLED === "true",
  [LicenseModule.COMPETENCEMATRIX]: ENV.VITE_APP_COMPETENCEMATRIX_MODULE_ENABLED === "true",
  [LicenseModule.MM]: ENV.VITE_APP_MM_MODULE_ENABLED === "true",
  [LicenseModule.LISACHART]: true,
  [LicenseModule.IH]: ENV.VITE_APP_IH_MODULE_ENABLED === "true",
  [LicenseModule.CHECKLIST]: true,
  [LicenseModule.WORKFLOW]: ENV.VITE_APP_WORKFLOW_MODULE_ENABLED === "true",
  [LicenseModule.CUSTOMERMANAGEMENT]: ENV.VITE_APP_CUSTOMERMANAGEMENT_MODULE_ENABLED === "true",
  [LicenseModule.MQTT]: ENV.VITE_APP_MQTT_MODULE_ENABLED === "true",
  [LicenseModule.INSTANCE]: ENV.VITE_APP_INSTANCE_MODULE_ENABLED === "true",
  [LicenseModule.BDE]: ENV.VITE_APP_BDE_MODULE_ENABLED === "true",
  [LicenseModule.QUALITY]: ENV.VITE_APP_QUALITY_MODULE_ENABLED === "true",
};

export const alwaysActiveModules = [
  LicenseModule.ASSIGNMENT,
  LicenseModule.MEET,
  LicenseModule.LISACHART,
  LicenseModule.CHECKLIST,
];

export function isModuleActive(module: LicenseModule | string) {
  let targetModule: LicenseModule;
  if (typeof module === "number") {
    targetModule = module;
  } else if (
    typeof module === "string" &&
    Object.values(LicenseModule).includes(module.toUpperCase()) === false
  ) {
    throw Error(`unknown license module requested: ${module}`);
  } else {
    // type of module is now string with a valid licenseModule string
    targetModule = LicenseModule[module.toUpperCase() as keyof typeof LicenseModule];
  }
  return moduleEnabled[targetModule];
}
