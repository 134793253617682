import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { ChartTileDisplayType, EventType } from "@/graphql";

export const tileConfigs: TileConfigType = {
  ChartTile: {
    active: true,
    defaultProps: {
      name: "Chart",
      eventTypes: [EventType.Issue],
      chartConn: {
        chartLayoutId: null,
        aggregate: false,
        aggregateSite: false,
        aggSiteIds: [],
        aggCCIds: [],
      },
      display: ChartTileDisplayType.DEFAULT,
    },
    settingsModule: defineAsyncComponent(
      () => import("./components/Tile/Settings/ChartTileSettings.vue"),
    ),
    component: defineAsyncComponent(() => import("./components/Tile/ChartTile.vue")),
    icon: "fas fa-chart-line",
  },
};
