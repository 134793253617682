import { RouteRecordRaw } from "vue-router";

const router: RouteRecordRaw[] = [
  {
    path: "/df/template/",
    name: "DFTemplate",
    children: [
      {
        path: "",
        name: "DFTemplateList",
        component: () => import("./views/DynamicFormTemplateList.vue"),
      },
      {
        path: "create",
        name: "DFTemplateCreate",
        component: () => import("./views/DFTemplateCreate.vue"),
      },
      {
        path: ":id",
        name: "DFTemplateUpdate",
        component: () => import("./views/DFTemplateUpdate.vue"),
        props: (p) => ({ id: Number(p.params.id) }),
      },
    ],
  },
];
export default router;
