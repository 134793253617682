import moment from "moment";
import { ActionTree, GetterTree, MutationTree } from "vuex";

import $api from "../api/api";

type ColorType = {
  name: string;
  hex: string;
};

const localState = {
  colorPicker: {
    designColors: [] as ColorType[],
    standardColors: [] as ColorType[],
    standardColorsFull: [] as ColorType[],
  },
  businessYearStart: 10,
  plbSort: [] as { key: string; value: any }[],
  moduleFolders: {},
  image: null,
  archiveWithoutTasks: null,
  showEisenhowerPriority: false,
  changeRequestBoardId: 1,
  lisaChartSettings: {
    hideXAxisLabelsMinified: false,
    hideYAxisLabelsMinified: false,
  },
  delegateEscalateRequired: true,
};

export type SystemSettingsStoreState = typeof localState;

export const getters: GetterTree<typeof localState, any> = {
  getDelegateEscalateRequired: (state) => {
    return state.delegateEscalateRequired;
  },
  getArchiveWithoutTasks: (state) => {
    return state.archiveWithoutTasks;
  },
  getShowEisenhowerPriority: (state) => {
    return state.showEisenhowerPriority;
  },
  getPLBSort: (state) => {
    return state.plbSort;
  },
  getBusinessYear: (state) => {
    return (today = moment()) => {
      const start = moment(today).subtract(1, "years").format("YYYY");
      return moment(`${start}-${state.businessYearStart}`);
    };
  },
  getBusinessYearStartMonth: (state) => {
    if (state.businessYearStart < 10) {
      return `0${state.businessYearStart}`;
    }
    return state.businessYearStart;
  },
  getColors: (state) => {
    return state.colorPicker;
  },
  getModuleFolders: (state) => {
    return state.moduleFolders;
  },
  getChangeRequestBoardId: (state) => {
    return state.changeRequestBoardId;
  },
  getLISAChartSettings: (state) => {
    return state.lisaChartSettings;
  },
};

const actions: ActionTree<typeof localState, any> = {
  async fetchDelegateEscalateRequired(context) {
    const response = await $api.systemSettings.getDelegateEscalateRequired();
    if (response?.value == null) {
      return;
    }
    context.commit("updateDelegateEscalateRequired", response.value);
  },
  async fetchShowEisenhowerPriority(context) {
    const response = await $api.systemSettings.getShowEisenhowerPriority();
    if (response == null) {
      return;
    }
    context.commit("updateShowEisenhowerPriority", response);
  },
  async fetchPLBSort(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("plbSort");
    if (response == null) {
      return;
    }
    context.commit("updatePLBSort", response);
  },
  async fetchModuleFolders(context) {
    const response = await $api.systemSettings.getModuleFolders();
    if (response?.meta == null) {
      return;
    }
    context.commit("updateModuleFolders", response.meta);
  },
  async fetchColors(context) {
    const response = await $api.systemSettings.getColors();
    if (response == null) {
      return;
    }
    context.commit("updateColors", response);
  },
  async fetchBusinessYear(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("businessYear");
    if (response?.value == null) {
      return;
    }
    context.commit("updateBusinessYear", response.value || 10);
  },
  async fetchImage(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("placeholderImage");
    if (response?.value == null) {
      return;
    }
    context.commit("updateImage", response.value);
  },
  async updateColors(context, payload) {
    const response = await $api.systemSettings.updateColors(0, payload);
    if (response == null) {
      return;
    }
    context.commit("updateColors", response);
  },
  async fetchArchiveWithoutTasks(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("archiveWithoutTasks");
    if (response?.value == null) {
      return;
    }
    context.commit("updateArchiveWithoutTasks", response.value);
  },
  async fetchChangeRequestBoardId(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("changeRequestBoardId");
    if (response?.value == null) {
      return;
    }
    context.commit("updateChangeRequestBoardId", response.value);
  },
  async fetchLisaChartSettings(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("lisachart");
    if (response == null) {
      return;
    }
    context.commit("updateLISAChartSettings", response);
  },
};

const mutations: MutationTree<typeof localState> = {
  updateDelegateEscalateRequired(state, payload) {
    state.delegateEscalateRequired = payload;
  },
  updateShowEisenhowerPriority(state, payload) {
    state.showEisenhowerPriority = payload;
  },
  updateArchiveWithoutTasks(state, payload) {
    state.archiveWithoutTasks = payload;
  },
  updateColors(state, payload) {
    state.colorPicker = payload;
  },
  updateImage(state, payload) {
    state.image = payload;
  },
  updateBusinessYear(state, payload) {
    state.businessYearStart = payload;
  },
  updateModuleFolders(state, payload) {
    state.moduleFolders = payload;
  },
  updateChangeRequestBoardId(state, payload) {
    state.changeRequestBoardId = payload;
  },
  updateLISAChartSettings(state, payload) {
    if (Array.isArray(payload)) {
      state.lisaChartSettings = Object.fromEntries(
        payload.map((el: { name: string; value: any }) => {
          return [el.name, el.value];
        }),
      ) as any;
    }
  },
  updatePLBSort(state, payload) {
    state.plbSort = payload;
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};
