import { computed, ComputedRef, inject, InjectionKey, Ref } from "vue";
import { DateTime } from "luxon";
import injectAsserted from "@/functions/assert/injectAsserted";
import { EventListEventFieldsFragment } from "@/graphql";
import { isActionActive, isEventActive } from "./Filter";
import { ActionFilterInjectionKey, EventFilterInjectionKey } from "./FilterTypes";
import { BoardInjectionKey } from "@/views/event-action/EventActionBoardInjectionKeys";

type UnpackInjectionKey<I> = I extends InjectionKey<infer T> ? T : never;

export default function useFilteredEvents(
  events: Readonly<Ref<Readonly<EventListEventFieldsFragment[]>>>,
  override?: {
    eventFilters: UnpackInjectionKey<typeof EventFilterInjectionKey>;
    actionFilters: UnpackInjectionKey<typeof ActionFilterInjectionKey>;
    boardId?: ComputedRef<number>;
  },
) {
  /**
   * move this inject inside a setup context
   * will remove warnings
   */
  const eventFilterList = override?.eventFilters ?? injectAsserted(EventFilterInjectionKey);
  const actionFilterList = override?.actionFilters ?? injectAsserted(ActionFilterInjectionKey);
  const board = inject(BoardInjectionKey, null);

  return computed(() => {
    const activeEvents = events.value.filter((event) => {
      const eventIsRecent = eventFilterList.value.updatedAt < DateTime.fromISO(event.updatedAt);

      if (eventIsRecent) {
        return true;
      }

      const actionFiltersAreEmpty = actionFilterList.value.actionFilters.length === 0;

      const actionIsActive = event.actions.some((action) => {
        return isActionActive(actionFilterList.value, action);
      });
      const eventIsActive = isEventActive(
        eventFilterList.value,
        event,
        override?.boardId?.value ?? board?.value?.id,
      );
      return (actionFiltersAreEmpty || actionIsActive) && eventIsActive;
    });

    return {
      activeEvents,
      numberOf: {
        events: activeEvents.length,
        actions: activeEvents
          .flatMap((event) => event.actions)
          .filter((action) => isActionActive(actionFilterList.value, action)).length,
      },
    };
  });
}
