import { ENV } from "@/ENV";
import store from "@/store";

/**
 * Checks if only visible for internal use
 * Visible instances localhost, dev, intern
 * Visible User (userId: 1 aka LisaAdmin)
 * @returns boolean to get special admin rights
 */
export function isSpecialAdmin() {
  return (
    store.getters["login/userId"] === 1 ||
    ENV.VITE_APP_INSTANCE_KEY === "intern" ||
    ENV.VITE_APP_INSTANCE_KEY === "dev" ||
    document.location.hostname === "localhost"
  );
}
