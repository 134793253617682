export default {
  endpoints: {
    getURL: {
      route: "put",
      method: "GET",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
    getAll: {
      route: "/",
      method: "GET",
    },
    uploadPDF: {
      route: "uploadPDF/",
      method: "POST",
    },
  },
};
