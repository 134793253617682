import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/idea/settingsOverview",
    name: "SettingsOverview",
    component: () => import("./views/IdeaSiteList.vue"),
    meta: {
      rights: ["hlp.readWrite"],
    },
  },
  {
    path: "/idea/config/:siteId",
    name: "HLPConfig",
    component: () => import("./views/IdeaConfig.vue"),
    meta: {
      rights: ["hlp.readWrite"],
    },
    props: (route) => {
      return {
        new: route.params.siteId === "new",
        siteId: route.params.siteId === "new" ? undefined : Number(route.params.siteId),
      };
    },
  },
  {
    path: "/idea/categoryList",
    name: "HLPCategoryList",
    component: () => import("./views/IdeaCategoryList.vue"),
    meta: {
      rights: ["hlp.readWrite"],
    },
  },
  {
    path: "/idea/category/:id",
    name: "HLPCategory",
    component: () => import("./views/IdeaCategory.vue"),
    meta: {
      rights: ["hlp.readWrite"],
    },
    props: (route) => {
      const newMode = route.params.id.toString() === "new";
      return {
        id: newMode ? undefined : parseInt(route.params.id.toString(), 10),
        newMode,
      };
    },
  },
];

export default router;
