<template>
  <Menubar
    class="doNotPrintMe h-100"
    style="border-radius: 0px"
    :class="{
      prod: !environment.isDevelopment && !environment.isStaging,
      staging: environment.isStaging,
      dev: environment.isDevelopment,
    }"
  >
    <template #start>
      <div class="flex">
        <img class="logo hideOnMobile" :src="lisaImage" @click="goToInitialPage" />
        <div id="breadcrumbs" class="ellipsis breadcrumbs breadcrumb hideOnMobile" />
        <div class="showOnMobile">
          <LISAButton
            class="p-button-sm p-button-outlined p-2"
            style="color: var(--primary-contrast-color) !important"
            @click="$emit('update:showSidebar', !showSidebar)"
          >
            <i class="fas fa-bars" />
          </LISAButton>
        </div>
      </div>
    </template>
    <template #end>
      <div class="flex align-items-center">
        <div id="boardTime" class="mr-3 hideOnMobile"></div>

        <div class="flex align-items-center">
          <MeetItem v-if="VITE_APP_MEET_MODULE_ENABLED" :board-id="currentBoardId" class="mr-2" />
          <LISAButton
            type="button"
            icon="fas fa-question"
            class="p-button-rounded p-button-secondary mr-2"
            @click="toggleHelpMenu"
          />
          <PrimeMenu
            ref="helpMenu"
            class="HeadNavBarScoped"
            :model="helpItems"
            :popup="true"
            style="font-size: 0.8em; width: 20em"
          />

          <Avatar
            :picture-id="pictureId"
            :avatar-size="37.71"
            :name="preferredName"
            class="hoverClick"
            @click="toggleUserMenu"
          />

          <PrimeMenu
            ref="userMenu"
            :model="userItems"
            class="HeadNavBarScoped"
            :popup="true"
            style="font-size: 0.8em; width: 20em"
          />
        </div>
      </div>
    </template>
  </Menubar>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Menubar from "primevue/menubar";
import PrimeMenu from "primevue/menu";
import { useI18n } from "vue-i18n";
import { ref, computed, onMounted } from "vue";
import type { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import Avatar from "@/components/AvatarImage.vue";
import lisalogo from "@/assets/logos/lisa.png";
import MeetItem from "@/license/meet/MeetItem.vue";
import { isModuleActive, LicenseModule } from "@/functions/moduleConfig";
import { languageNames, flagMap } from "../language";
import isKey from "@/functions/isKey";
import { ENV } from "@/ENV";
import LISAButton from "./shared/primevue/LISAButton.vue";
import { isDevelopment, isStaging } from "../functions/environment";

defineProps({
  showSidebar: {
    type: Boolean,
    required: true,
  },
});
defineEmits({ "update:showSidebar": (show: boolean) => show });

const helpMenu = ref<InstanceType<typeof PrimeMenu>>();
const userMenu = ref<InstanceType<typeof PrimeMenu>>();
const toggleHelpMenu = (event: any) => {
  helpMenu.value?.toggle(event);
};
const toggleUserMenu = (event: any) => {
  userMenu.value?.toggle(event);
};

const { t, locale } = useI18n();
const router = useRouter();
const showLanguages = ref(false);
const store = useStore();

const environment = computed(() => {
  return {
    isDevelopment: isDevelopment(),
    isStaging: isStaging(),
  };
});

const currentBoardId = computed(() => {
  return parseInt(router.currentRoute.value.params?.boardId as string, 10);
});
const initialPage = computed(() => {
  return store.state.login.user?.initialPage;
});

const preferredName = computed(() => {
  return store.state.login.user?.preferredName;
});
const pictureId = computed(() => {
  return store.getters["login/profilePictureId"];
});

const site = computed(() => {
  return store.state.login.user?.site?.name ?? "";
});
const department = computed(() => {
  return store.state.login.user?.department?.name ?? "";
});
function isDepartmentThere() {
  if (department.value) {
    return true;
  }
  return false;
}
function createLinkAndLeave(link = "") {
  if (link === "") {
    return;
  }
  const clickableLink = document.createElement("a");
  clickableLink.href = link;
  clickableLink.target = "_blank";
  clickableLink.click();
}
function openDocumentation() {
  if (document.location.hostname === "localhost") {
    createLinkAndLeave("https://wiki.lisapp.de/");
  } else if (ENV.VITE_APP_WIKI_URL) {
    createLinkAndLeave(ENV.VITE_APP_WIKI_URL);
  } else if (ENV.VITE_APP_INSTANCE_KEY === "dev") {
    createLinkAndLeave(`https://wiki.lisapp.de/login/lisa-development-dev`);
  } else if (document.location.hostname.startsWith("deploy-preview-")) {
    createLinkAndLeave(
      `https://wiki.lisapp.de/login/lisa-deploy-preview-${ENV.VITE_APP_INSTANCE_KEY}`,
    );
  } else if (document.location.hostname.startsWith("staging-")) {
    createLinkAndLeave(`https://wiki.lisapp.de/login/lisa-staging-${ENV.VITE_APP_INSTANCE_KEY}`);
  } else {
    createLinkAndLeave(`https://wiki.lisapp.de/login/lisa-production-${ENV.VITE_APP_INSTANCE_KEY}`);
  }
}
function openImprint() {
  createLinkAndLeave(`https://frisch-solutions.com/${locale.value}/impressum/`);
}
function openPrivacyStatement() {
  createLinkAndLeave(`https://frisch-solutions.com/${locale.value}/datenschutz/`);
}
function goToInitialPage() {
  if (!router.currentRoute.value.fullPath.includes(initialPage.value)) {
    router.push(initialPage.value);
  }
}
async function changeLanguage(language: string) {
  store.dispatch("language/setLocale", language);
  showLanguages.value = false;
}
async function logout() {
  await store.dispatch("login/logout");
}
function sendEmail(address: any) {
  window.open(`mailto:${address}`, "_blank");
}
const helpItems = computed<MenuItem[]>(() => [
  {
    label: "Help-Desk",

    items: [
      {
        separator: true,
      },
      {
        label: "+49 241 51537043",
        icon: "pi pi-phone",
        command: () => {
          window.open("tel:+4924151537043", "_blank");
        },
      },
      {
        label: "+49 241 51537043",
        icon: "fab fa-whatsapp fa-lg",
        command: () => {
          window.open("https://wa.me/4924151537043", "_blank");
        },
      },
      {
        label: "support@frisch-solutions.com",
        icon: "pi pi-envelope",
        command: () => {
          sendEmail("support@frisch-solutions.com");
        },
        style: "p-menuitem-link",
      },
      {
        separator: true,
      },
      {
        label: "Changelog",
        command: () => {
          router.push("/changelog");
        },
      },
      {
        separator: true,
      },
      {
        label: t("components.HeadNavbar.documentation"),
        command: () => {
          openDocumentation();
        },
      },
      {
        label: t("components.HeadNavbar.imprint"),
        command: () => {
          openImprint();
        },
      },
      {
        label: t("components.HeadNavbar.privacyStatement"),
        command: () => {
          openPrivacyStatement();
        },
      },
    ],
  },
]);

const userItems = computed<MenuItem[]>(() => [
  {
    label: preferredName.value,
    items: [
      {
        separator: true,
      },
      {
        label: t("components.HeadNavbar.UserSettings"),
        icon: "fas fa-user-cog",
        command: () =>
          router.push(`/user/${store.state.login.user ? store.state.login.user.id : ""}`),
      },
      {
        label: site.value,
        icon: "fas fa-industry",
      },
      {
        label: department.value,
        icon: "pi pi-phone",
        visible: isDepartmentThere(),
      },
      {
        separator: true,
      },
      {
        icon: (flagMap as any)[locale.value],
        label: `${t("components.HeadNavbar.Language")} (${locale.value})`,
        class: "noClose",
        command: () => {
          showLanguages.value = !showLanguages.value;
        },
      },
      ...(showLanguages.value
        ? Object.entries(languageNames).map(([key, label]) => {
            return {
              label,
              icon: isKey(flagMap, key) ? flagMap[key] : undefined,
              command: () => changeLanguage(key),
            };
          })
        : []),

      {
        separator: true,
      },
      {
        label: t("components.HeadNavbar.logout"),
        icon: "fas fa-sign-out",
        command: () => logout(),
      },
    ],
  },
]);

onMounted(() => {
  // replace onClick on user menu to prevent closing on language dropdown
  const originalItemClick = userMenu.value?.itemClick;

  if (userMenu.value != null) {
    userMenu.value.itemClick = function clickFunc(ev: MenuItemCommandEvent) {
      if (ev.item.class === "noClose") {
        if (ev.item.disabled) {
          return;
        }
        if (ev.item.command) {
          ev.item.command(ev);
        }
      } else {
        originalItemClick?.(ev);
      }
    };
  }
});

const lisaImage = lisalogo;
const VITE_APP_MEET_MODULE_ENABLED = isModuleActive(LicenseModule.MEET);
</script>

<style media="screen" lang="scss" scoped>
.dev {
  background: var(--error-color) !important;
}

.staging {
  background: var(--info-color) !important;
}

.prod {
  background: var(--primary-color) !important;
}

.logo {
  height: 2.4em;
  cursor: pointer;
  width: 9.47em;
  background-color: var(--surface-0);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.125rem;
}
</style>

<style lang="scss">
.noUnderline > button {
  text-decoration: none !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.help-div {
  height: 36px;
  width: 36px;
}

.HeadNavBarScoped {
  .p-submenu-header {
    font-size: 1.5em !important;
  }
}
</style>
<style media="screen" scoped>
.breadcrumbs {
  position: absolute;

  left: 50%;
  transform: translate(-50%);
}
</style>

<style scoped>
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.53rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.hoverClick:hover {
  cursor: pointer;
  filter: brightness(50%);
  transition: all 0.1s ease-out;
}
</style>
