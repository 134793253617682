export default {
  endpoints: {
    getAll: {
      route: "/",
      method: "GET",
    },
    get: {
      route: ":id",
      method: "GET",
    },
    getPath: {
      route: "path/:id",
      method: "GET",
    },
    create: {
      route: "/",
      method: "POST",
      ignoreError: true,
    },
    update: {
      route: ":id",
      method: "PUT",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
  },
};
