export default {
  endpoints: {
    search: {
      route: "search",
      method: "POST",
    },
    get: {
      route: ":id",
      method: "GET",
    },
  },
};
