import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/data/export",
    name: "LISADataExport",
    component: () => import("./views/LISADataExport.vue"),
    meta: {
      rights: ["systemSettings.export"],
    },
  },
];
export default router;
