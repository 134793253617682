export default {
  endpoints: {
    getUserShiftPlan: {
      route: "plan/:id",
      method: "GET",
    },
    saveUserShiftPlan: {
      route: "plan",
      method: "PUT",
    },
    createUserShiftPlan: {
      route: "plan",
      method: "POST",
    },
    deleteUserShiftPlan: {
      route: "plan/:id",
      method: "DELETE",
    },
    getAllUser: {
      route: "allUser/:id",
      method: "GET",
    },
    getAllTeams: {
      route: "teams",
      method: "GET",
    },
    createTeam: {
      route: "teams",
      method: "POST",
    },
    getTeam: {
      route: "teams/:id",
      method: "GET",
    },
    saveTeam: {
      route: "team/:id",
      method: "PUT",
    },
    deleteTeam: {
      route: "team/:id",
      method: "DELETE",
    },
    search: {
      route: "search",
      method: "POST",
    },
    getName: {
      route: "getName/:id",
      method: "GET",
    },
    getLastTeamTime: {
      route: "team/getLastTime/:id",
      method: "POST",
    },
  },
};
