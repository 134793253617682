import { keycloak } from "@/keycloak/Keycloak";

export function imageURLFromUUID(
  pictureId: string,
  queryParams: { name: string; value: string }[] = [],
) {
  let url = `/api/minio/get/${pictureId}`;
  if (keycloak.token) {
    url += `?token=${keycloak.token}${queryParams ? "&" : ""}${queryParams
      ?.map((el) => `${el.name}=${el.value}`)
      .join("&")}`;
    return url;
  }
  return `${url}?${queryParams?.map((el) => `${el.name}=${el.value}`).join("&")}`;
}
