 
import { merge } from "lodash";

const allJSON = import.meta.glob("./**/*.json");

const sortedByLanguageKey = Object.entries(allJSON).reduce((prev, [path, promise]) => {
  // Create a path array
  const fixedPathArray = path.split("/");
  // Get the languageKey
  const languageKey = fixedPathArray[1].replace("-", "_");
  // Fix the languageKey in the array
  fixedPathArray[1] = languageKey;
  // Convert the array back into an string
  const fixedPath = fixedPathArray.join("/");

  if (languageKey in prev) {
    prev[languageKey].push([fixedPath, promise]);
  } else {
    prev[languageKey] = [[fixedPath, promise]];
  }
  return prev;
}, {} as Record<string, Array<[string, typeof allJSON[string]]>>);


export function parseLanguageToObject(languages: [string, any][]) {
  // Languages is an object with the filename as key and the resolved module as object.
  const languageArray = languages.map(([path, module]) => {
    // get the filename without the extension from the path.
    // So, convert "./en-GB/api.json" to "api"
    const filename = path
      .split("/") // split for each path
      .pop() // get the last entry
      ?.slice(0, -5); // cut off the ".json"
    // An array of the path the file is contained in, inclusive the filename
    const pathArray = path.split("/");
    // Remove the ./
    pathArray.shift();
    // Remove the file
    pathArray.pop();
    // add the filename if available
    if (filename != null) {
      pathArray.push(filename);
    }
    // Merge the path array into an object, with the module as value
    return pathArray.reduceRight((obj, next) => ({ [next]: obj }), module.default);
  });
  // merge all the files together in a single object
  const merged = languageArray.reduce((prev, curr) => {
    return merge(prev, curr);
  }, {});
  return merged;
}

/**
 * This object contains a key for each language, that is a function which resolves to a promise, returning the respective language
 * */
const loaderObject = Object.fromEntries(
  Object.keys(sortedByLanguageKey).map((key) => {
    return [
      key,
      async () => {
        const lang = sortedByLanguageKey[key];
        const langPromises = lang.map(async ([path, promise]) => {
          return [path, await promise()] as [string, { [key: string]: any }];
        });
        const languageValues = await Promise.all(langPromises);
        return parseLanguageToObject(languageValues);
      },
    ];
  }),
);

export const defaultLanguage = "de";
export const defaultLanguageMessages = parseLanguageToObject(
  Object.entries(import.meta.glob("./de/**/*.json", { eager: true })),
);

export default loaderObject;
export const languageNames = Object.freeze({
  de: "Deutsch",
  de_CH: "Deutsch (Schweiz)",
  en: "English (US)",
  en_GB: "English (UK)",
  fr: "Français",
  es: "Español",
  pt: "Português",
  hu: "Magyar",
  pl: "Polski",
  it: "Italiano",
  uk_UA: "українська",
});

export const flagMap = Object.freeze({
  de: "fi fi-de",
  de_CH: "fi fi-ch",
  en: "fi fi-us",
  en_GB: "fi fi-gb",
  fr: "fi fi-fr",
  es: "fi fi-es",
  pt: "fi fi-pt",
  hu: "fi fi-hu",
  pl: "fi fi-pl",
  it: "fi fi-it",
  uk_UA: "fi fi-ua"
});
