import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { SQDCBoard } from "@/graphql";
import router from "@/router";
import store from "@/store";
import api from "@/api/api";

interface SQDCBoardWithRoot extends SQDCBoard {
  root: SQDCBoard;
}

const flattenHierarchy = (boards: SQDCBoard[]): SQDCBoardWithRoot[] => {
  return boards.flatMap((board) => {
    if (board.children) {
      return [
        {
          ...board,
          root: board,
        },
        ...flattenHierarchy(board.children).map((childBoard) => ({ ...childBoard, root: board })),
      ];
    }
    return [
      {
        ...board,
        root: board,
      },
    ];
  });
};

const goTo404 = (next: NavigationGuardNext) => {
  next({ name: "pageNotFound" });
};

const getHierarchy = async () => api.sqdc.getBoardHierarchy();

const setStore = async (boardId: number, siteId: number) => {
  await store.dispatch("login/updatePreferredSite", siteId);
  await store.dispatch("login/updatePreferredBoard", boardId);
};

const navigateToDefault = async (
  next: NavigationGuardNext,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => {
  const siteId = store.getters["login/preferredSite"];
  const preferredBoardId = store.getters["login/preferredBoard"];
  const hierarchy = await getHierarchy();
  const flattenhierarchy = flattenHierarchy(hierarchy);

  const routeId = router.currentRoute.value.params.id;

  if (parseInt((routeId as string) ?? 0, 10) === preferredBoardId) {
    if (from.name === "KPIBoard" && to.name === "KPIBoard") {
      router.go(0);
      next(false);
    }
  }

  if (preferredBoardId != null) {
    const board = flattenhierarchy.find((el) => el.id === preferredBoardId) ?? null;
    if (board) {
      await setStore(board.id, board.siteId);
      next({
        name: to.name ?? "KPIBoard",
        params: {
          id: board.id.toString(),
        },
      });
    } else {
      await setStore(hierarchy[0].root.id, hierarchy[0].root.siteId);
      next({
        name: to.name ?? "KPIBoard",
        params: {
          id: hierarchy[0].root.id.toString(),
        },
      });
    }
  } else if (siteId != null) {
    const board = flattenhierarchy.find((el) => el.id === preferredBoardId) ?? null;
    if (board != null) {
      await setStore(board.id, board.siteId);
      next({
        name: to.name ?? "KPIBoard",
        params: {
          id: board.id.toString(),
        },
      });
    } else {
      await setStore(hierarchy[0].root.id, hierarchy[0].root.siteId);
      next({
        name: to.name ?? "KPIBoard",
        params: {
          id: hierarchy[0].root.id.toString(),
        },
      });
    }
  } else {
    await setStore(hierarchy[0].root.id, hierarchy[0].root.siteId);
    next({
      name: to.name ?? "KPIBoard",
      params: {
        id: hierarchy[0].root.id.toString(),
      },
    });
  }
};

const navigateWithId = async (
  boardId: number,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  // check if Board in current Hierarchy;
  const hierarchy = await getHierarchy();
  const flatHierarchy = flattenHierarchy(hierarchy);
  const board = flatHierarchy.find((el) => el.id === boardId) ?? null;

  if (board == null) {
    goTo404(next);
  } else if (
    from.params.id === to.params.id &&
    to.name === "KPIBoard" &&
    from.name === "KPIBoard"
  ) {
    router.go(0);
    next(false);
  } else {
    await setStore(boardId, board.siteId);
    next();
  }
};

export const checkId = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to?.params?.id && typeof to.params.id === "string") {
    // id is given, check if it is valid
    await navigateWithId(parseInt(to.params.id, 10), to, from, next);
  } else {
    // no id is given, try to guess
    await navigateToDefault(next, to, from);
  }
};
