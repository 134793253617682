<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }"> {{ text }} </FilterBadge>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import IDFilter from "../../filterImplementations/IDFilter";
import FilterBadge from "./FilterBadge.vue";

const props = defineProps({
  filter: {
    type: Object as PropType<IDFilter>,
    required: true,
  },
});
const { t } = useI18n();
const text = computed(() => {
  const typeText = props.filter.type ? t(`global.EABTypes.${props.filter.type}`) : t(`global.all`);
  return `${typeText}${props.filter.ids.length > 0 ? " | " : ""}${props.filter.ids.join(", ")}`;
});
</script>

<style lang="scss" scoped></style>
