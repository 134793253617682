import { Router } from "@/typeDefinitions/custom/Router";

const AssignmentLayoutDetail = () => import("./views/AssignmentLayoutDetail.vue");

const router: Router.LicensedRouterExportType = [
  {
    path: "/assignmentLayoutOverview",
    name: "AssignmentLayoutOverview",
    component: () => import("./views/AssignmentLayoutOverview.vue"),
    meta: {
      rights: ["assignment.admin"],
    },
  },
  {
    path: "/AssignmentLayout/create",
    name: "CreateAssignmentLayout",
    component: AssignmentLayoutDetail,
    meta: {
      rights: ["assignment.admin"],
    },
  },
  {
    path: "/AssignmentLayout/detail/:layoutId",
    name: "UpdateAssignmentLayout",
    component: AssignmentLayoutDetail,
    meta: {
      rights: ["assignment.admin"],
    },
    props: (route) => {
      return {
        layoutId: Number(route.params.layoutId),
      };
    },
  },
];

export default router;
