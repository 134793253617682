import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/customerManagement/",
    name: "CMList",
    component: () => import("./views/CMList.vue"),
    meta: {
      rights: [],
    },
  },
  {
    path: "/customerManagement/new",
    name: "CMCreate",
    component: () => import("./views/CMCreate.vue"),
    meta: {
      rights: [],
    },
  },
  {
    path: "/customerManagement/:id",
    name: "CMDetail",
    component: () => import("./views/CMDetail.vue"),
    meta: {
      rights: [],
    },
    props: (route) => {
      return {
        id: parseInt(route.params.id.toString(), 10),
      };
    },
  },
];

export default router;
