import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { ChartTimeFrameStartFormats, ChartInterval, IHParetoChartType } from "@/graphql";

export const tileConfigs: TileConfigType = {
  IHWeakPointParetoTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () =>
        import(
          "@/modules/tiles/components/ihWeakPointParetoTile/IHWeakPointParetoTileSettings.vue"
        ),
    ),
    defaultProps: {
      name: "IHWeakPointParetoTile",
      meta: {
        hierarchyLevel: 2,
        timeFrame: ChartInterval.MONTH,
        timeFrameStart: ChartTimeFrameStartFormats.CURRENT,
        timeFrameCount: 1,
        chartType: IHParetoChartType.FAULT_TIME,
        ihAllowNullValues: false,
      },
      eventTypes: [],
    },
    component: defineAsyncComponent(
      () => import("@/modules/tiles/components/ihWeakPointParetoTile/IHWeakPointParetoTile.vue"),
    ),
    icon: "fas fa-tools",
  },
};
