import { ActionTree, GetterTree, MutationTree } from "vuex";
import { ActionFilter, EventFilter } from "@/components/event-action/filter/FilterTypes";
import { ArchiveRange } from "@/graphql";
import { DataTableSortMeta } from "primevue/datatable";

export type SearchListFilterCacheEntry = {
  search: string;
  searchOptionsFuzzy: boolean;
  selected: string[];
};

export type EABFilterCacheEntry = {
  eventFilters: EventFilter[];
  actionFilters: ActionFilter[];
  archiveRange: ArchiveRange | undefined;
};

const localState = {
  searchList: {} as Record<
    string,
    SearchListFilterCacheEntry | Record<number, SearchListFilterCacheEntry>
  >,
  eab: {} as Record<number, EABFilterCacheEntry>,
  ideaExportFilter: [] as number[],
  bdeView: {} as Record<string, Record<number, DataTableSortMeta[]>>,
};

const actions: ActionTree<typeof localState, any> = {
  updateSearchListFilter(context, payload) {
    context.commit("setSearchListFilter", payload);
  },
  updateEABFilter(context, payload) {
    context.commit("setEABFilter", payload);
  },
  updateIdeaExportFilter(context, payload) {
    context.commit("setIdeaExportFilter", payload);
  },
  updateBDEViewFilter(context, payload) {
    context.commit("setBDEViewFilter", payload);
  },
};

const mutations: MutationTree<typeof localState> = {
  setSearchListFilter(
    state,
    payload: { key: string; siteId?: number; filterCache: SearchListFilterCacheEntry },
  ) {
    if (payload.siteId == null) {
      state.searchList[payload.key] = payload.filterCache;
    } else {
      if (state.searchList[payload.key] == null) {
        state.searchList[payload.key] = {};
      }
      (state.searchList[payload.key] as Record<number, SearchListFilterCacheEntry>)[
        payload.siteId
      ] = payload.filterCache;
    }
  },
  setEABFilter(state, payload: { boardId: number; filterCache: EABFilterCacheEntry }) {
    state.eab[payload.boardId] = payload.filterCache;
  },
  setIdeaExportFilter(state, payload: number[]) {
    state.ideaExportFilter = payload;
  },
  setBDEViewFilter(
    state,
    payload: { key: string; tpId: number; filterCache: DataTableSortMeta[] },
  ) {
    if (state.bdeView[payload.key] == null) {
      state.bdeView[payload.key] = {};
    }
    (state.bdeView[payload.key] as Record<number, DataTableSortMeta[]>)[payload.tpId] =
      payload.filterCache;
  },
};

const getters: GetterTree<typeof localState, any> = {
  getSearchListFilter: (state) => (key: string, siteId?: number) => {
    const cache = state.searchList[key];
    if (siteId == null || cache == null) {
      // If there is no siteId, we return the cache, also if it is null.
      return cache;
    }
    return (cache as Record<number, SearchListFilterCacheEntry>)[siteId];
  },
  getEABFilter: (state) => (boardId: number) => state.eab[boardId],
  getIdeaExportFilter: (state) => state.ideaExportFilter,
  getBDEViewFilter: (state) => (key: string, tpId: number) => {
    const cache = state.searchList[key];
    if (cache == null) {
      return null;
    }
    return (cache as Record<number, DataTableSortMeta[]>)[tpId];
  },
};

export default {
  namespaced: true,
  state: localState,
  actions,
  mutations,
  getters,
};
