import { PublicClientApplication, Configuration, AuthenticationResult } from "@azure/msal-browser";
import axios from "axios";
import { InjectionKey, Ref, ref } from "vue";
import { RRule, Weekday } from "rrule";
import { DateTime } from "luxon";
import { ENV } from "@/ENV";
import keycloakConfig from "@/keycloak/KeycloakConfig";
import { keycloak } from "@/keycloak/Keycloak";

export const oneDriveConfig = ref<{
  initialized: boolean;
  accessToken: string | null;
}>({
  initialized: false,
  accessToken: null,
});

type KeyCloakBrokerTokenResponse = {
  access_token: string;
  expires_in: number;
};

async function getTokenFromKeycloak() {
  try {
    const res = await axios.get<KeyCloakBrokerTokenResponse>(
      `${keycloakConfig.url}/auth/realms/${keycloakConfig.realmName}/${ENV.VITE_APP_OD_PROVIDER}/token`,
      {
        headers: {
          Authorization: `Bearer ${keycloak?.token}`,
        },
      },
    );
    if (res.data.expires_in < 1) {
      return null;
    }
    return res.data.access_token;
  } catch (_e: unknown) {
    return null;
  }
}

function buildMSALConfig(): Configuration {
  return {
    auth: {
      clientId: ENV.VITE_APP_OD_CLIENT_ID,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: "/",
      authority: ENV.VITE_APP_OD_AUTHORITY,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  } as Configuration;
}

function initializeMsal(config: Configuration): PublicClientApplication {
  return new PublicClientApplication(config);
}

export function resolveIconForFile(mimeType?: string) {
  switch (mimeType) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return {
        class: "fas fa-file-word",
        color: "#2b579a",
      };
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return {
        class: "fas fa-file-excel",
        color: "#217346",
      };
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return {
        class: "fas fa-file-powerpoint",
        color: "#d24726",
      };
    case "application/pdf":
      return {
        class: "fas fa-file-pdf",
        color: "#e70008",
      };
    case "image/jpeg":
    case "image/png":
      return {
        class: "fas fa-file-image",
      };
    case "video/mp4":
      return {
        class: "fas fa-file-video",
      };
    case "audio/mpeg":
      return {
        class: "fas fa-file-audio",
      };
    default:
      return {
        class: "fas fa-file",
      };
  }
}

interface MSGraphErrorBase {
  error: {
    code: string;
    message: string;
  };
}

export interface MSGraphAccessError extends MSGraphErrorBase {
  error: {
    code: "AccessDenied";
    message: string;
  };
}

export type MSGraphError = MSGraphAccessError;

export class MSGraphAccessDeniedError extends Error {
  constructor(msg?: string) {
    super(msg || "Access Denied");

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, MSGraphAccessDeniedError.prototype);
  }
}
export type OneDriveItem = {
  id: string;
  name: string;
  lastModifiedDateTime: string;
  parentReference: {
    driveId: string;
    name: string;
  };
  lastModifiedBy: {
    user: {
      displayName: string;
    };
  };
  webUrl: string;
  folder?: {
    childCount: number;
  };
  file?: {
    mimeType: string;
  };
  embed?: string;
};

type OneDriveDriveItemRequest = OneDriveItem | MSGraphError;

type OneDriveChildrenRequest =
  | {
      value: OneDriveItem[];
    }
  | MSGraphError;

type OneDrivePreviewRequest =
  | {
      getUrl: string;
    }
  | MSGraphError;

export type ResolveShareReturnType = {
  folder: OneDriveItem;
  files: OneDriveItem[];
};

export type GraphEvent = {
  id: string;
  hasAttachments: boolean;
  subject: string;
  isAllDay: boolean;
  isCancelled: boolean;
  body: {
    contentType: string;
    content: string;
  };
  isOnlineMeeting: boolean;
  webLink: string;
  location: {
    displayName: string;
  };
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  attendees: {
    type: "required" | "optional" | "resource";
    status: {
      response:
        | "none"
        | "organizer"
        | "tentativelyAccepted"
        | "accepted"
        | "declined"
        | "notResponded";
    };
    emailAddress: {
      address: string;
      name: string;
    };
  }[];
  onlineMeeting?: {
    joinUrl: string;
    tollNumber: string;
  };
  recurrence?: {
    pattern: {
      type: string;
      interval: number;
      month: number;
      dayOfMonth: number;
      daysOfWeek: string[];
      firstDayOfWeek: string;
      index: string;
    };
    range: {
      type: string;
      startDate: string;
      endDate: string;
      recurrenceTimeZone: string;
      numberOfOccurrences: number;
    };
  };
};

export function convertMSGraphRecurrenceToRRule(
  outlookEvent: NonNullable<GraphEvent["recurrence"]>,
): string {
  const { pattern, range } = outlookEvent;

  // Setup RRule options
  const options: any = {
    freq: RRule.WEEKLY,
    interval: pattern.interval,
    dtstart: new Date(range.startDate),
  };

  if (pattern.type === "daily") {
    options.freq = RRule.DAILY;
  }
  if (pattern.type === "monthly") {
    options.freq = RRule.MONTHLY;
  }
  if (pattern.type === "yearly") {
    options.freq = RRule.YEARLY;
  }

  // Parse days of week
  if (pattern.daysOfWeek && pattern.daysOfWeek.length > 0) {
    const weekdays: Weekday[] = pattern.daysOfWeek.map((day) => {
      switch (day.toLowerCase()) {
        case "sunday":
          return RRule.SU;
        case "monday":
          return RRule.MO;
        case "tuesday":
          return RRule.TU;
        case "wednesday":
          return RRule.WE;
        case "thursday":
          return RRule.TH;
        case "friday":
          return RRule.FR;
        case "saturday":
          return RRule.SA;
        default:
          throw new Error(`Invalid day of week: ${day}`);
      }
    });
    options.byweekday = weekdays;
  }

  // Parse start date and end date
  if (range.endDate !== "0001-01-01") {
    options.until = new Date(range.endDate);
  } else {
    /**
     * if the recurrence has no end date, we set it to 5 years from now because the recurrence plugin can not handle infinite recurrences
     * TODO: github issue
     */
    options.until = DateTime.now().plus({ years: 5 }).toJSDate();
  }

  // Create RRule instance
  const rrule = new RRule(options);

  // Return RRule string representation
  return rrule.toString();
}

export const OneDriveConfigInjectionKey = Symbol("OneDriveConfigInjectionKey") as InjectionKey<
  Ref<{
    initialized: boolean;
    accessToken: string | null;
  }>
>;

export class LISAGraphAPI {
  private azureClient: PublicClientApplication | null = null;
  private scopes = ["Files.Read.All", "Group.Read.All"];
  private azureInit = false;
  private accessToken: string | null = null;
  private validConfig = false;

  constructor() {
    this.validConfig = ENV.VITE_APP_OD_CLIENT_ID.length > 0 && ENV.VITE_APP_OD_AUTHORITY.length > 0;
  }

  get isConfigValid() {
    return this.validConfig;
  }

  private checkInit() {
    if (!this.azureInit) {
      throw new Error("Azure client not initialized");
    }
  }

  public async getTokenSilent(ignoreInit = false) {
    if (!ignoreInit) {
      this.checkInit();
    }
    try {
      const keycloakBrokerToken = await getTokenFromKeycloak();
      if (keycloakBrokerToken != null) {
        this.accessToken = keycloakBrokerToken;
        oneDriveConfig.value.accessToken = this.accessToken;
        return;
      }
    } catch (_e) {
      // nothing
    }
    try {
      const auth = await this.azureClient!.acquireTokenSilent({ scopes: this.scopes });
      this.azureClient!.setActiveAccount(auth.account);
      this.accessToken = auth.accessToken;
      oneDriveConfig.value.accessToken = this.accessToken;
    } catch (_e) {
      // nothing
    }
  }

  public async initialize(): Promise<boolean> {
    this.azureClient = initializeMsal(buildMSALConfig());
    await this.azureClient.initialize();
    this.azureInit = true;
    await this.getTokenSilent();
    return (this.accessToken || "").length > 0;
  }

  public async authorize() {
    this.checkInit();
    let auth: AuthenticationResult | null = null;
    try {
      auth = await this.azureClient!.loginPopup({
        scopes: this.scopes,
        prompt: "select_account",
      });
    } finally {
      if (auth) {
        this.azureClient!.setActiveAccount(auth.account);
        this.accessToken = auth.accessToken;
        oneDriveConfig.value.accessToken = this.accessToken;
      }
    }
  }

  async getChannelCalendar(url?: string) {
    if (!url) {
      return [];
    }
    const uri = new URL(url);
    const groupId = uri.searchParams.get("groupId");
    const data = await axios.get<{ value: GraphEvent[] } | MSGraphError>(
      new URL(
        `https://graph.microsoft.com/v1.0/groups/${groupId}/calendar/events?$select=subject,body,bodyPreview,location,attendees,start,end,isAllDay,recurrence,isCancelled&$filter=isCancelled eq false`,
      ).toString(),
      {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          ContentType: "application/json",
        },
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 403;
        },
      },
    );
    if ("error" in data.data) {
      throw new MSGraphAccessDeniedError();
    }
    return data.data.value;
  }

  async resolveShare(link: string): Promise<ResolveShareReturnType> {
    const b64 = btoa(link);
    // remove all equal signs from end of b64
    const b64NoPadding = b64.replaceAll("=", "").replaceAll("/", "_").replaceAll("+", "-");

    const data = await axios.get<OneDriveDriveItemRequest>(
      `https://graph.microsoft.com/v1.0/shares/u!${b64NoPadding}/driveItem`,
      {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          ContentType: "application/json",
        },
        validateStatus: (status) => (status >= 200 && status < 300) || status === 403,
      },
    );
    if ("error" in data.data) {
      throw new MSGraphAccessDeniedError();
    }
    const res = data.data;
    if (data.data.folder) {
      // it's a folder
      const folder = await axios.get<OneDriveChildrenRequest>(
        `https://graph.microsoft.com/v1.0/drives/${res.parentReference.driveId}/items/${res.id}/children?$select=id,parentReference,file,folder,name`,
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            ContentType: "application/json",
          },
          validateStatus: (status) => (status >= 200 && status < 300) || status === 403,
        },
      );
      if ("error" in folder.data) {
        throw new MSGraphAccessDeniedError();
      }
      const files = await Promise.all(
        folder.data.value
          .filter((el) => el.file)
          .map(async (item) => {
            if (item.file) {
              const preview = await this.getPreview(res.parentReference.driveId, item.id);
              return {
                ...item,
                embed: preview,
              };
            }
            return null;
          }),
      );
      return {
        folder: data.data,
        files: files.filter((el) => el !== null) as OneDriveItem[],
      };
    }
    // it's a file
    const preview = await this.getPreview(res.parentReference.driveId, res.id);
    return {
      folder: res.parentReference as unknown as OneDriveItem,
      files: [
        {
          ...res,
          embed: preview,
        },
      ],
    };
  }

  private async getPreview(driveId: string, itemId: string) {
    const url = await axios.post<OneDrivePreviewRequest>(
      `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}/preview?$select=getUrl`,
      {
        chromeless: true,
      },
      {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          ContentType: "application/json",
        },
        validateStatus: (status) => (status >= 200 && status < 300) || status === 403,
      },
    );
    if ("error" in url.data) {
      throw new MSGraphAccessDeniedError();
    }
    return url.data.getUrl;
  }
}
