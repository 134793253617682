import moment, { Moment } from "moment-timezone";
import { computed, ComputedRef, InjectionKey, provide, ref } from "vue";
import injectAsserted from "./assert/injectAsserted";

export default (value: number | Date | Moment, key?: string, tz?: string) => {
  let localValue = value;
  if (value != null && tz != null && moment.tz.zone(tz) != null) {
    const targetTZ = moment(localValue).tz(tz);
    localValue = moment(localValue)
      .add(targetTZ.utcOffset(), "m")
      .subtract(moment(value).utcOffset(), "m");
  }
  if (window.$i18n != null && key != null) {
    return window.$i18n.d(localValue as number | Date, key);
  }
  return "";
};

export const TimeZoneInjectionKey = Symbol("TimeZoneInjectionKey") as InjectionKey<
  ComputedRef<string>
>;

export function useTimeZoneProvide(value: ComputedRef<string | undefined | null>) {
  /**
   * move this inject inside a setup context
   * will remove warnings
   */
  const oldTZ = injectAsserted(TimeZoneInjectionKey);
  return provide(
    TimeZoneInjectionKey,
    computed(() => value.value ?? oldTZ.value),
  );
}

type SelectedSiteType = { id: number; name: string; tz: string };

/**
 * composable to provide timezone on preferredSiteSelect
 * @returns the functions that must be bind to preferredSiteSelect
 */
export function usePreferredSiteSelectTimezoneProvide() {
  const timezone = ref<string>();

  function onSiteSelectDataLoaded(data: { selected: SelectedSiteType }) {
    timezone.value = data.selected.tz;
  }

  function onElementSelected(site: SelectedSiteType) {
    timezone.value = site.tz;
  }

  useTimeZoneProvide(computed(() => timezone.value));

  return {
    onSiteSelectDataLoaded,
    onElementSelected,
  };
}
