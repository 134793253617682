import { Router } from "@/typeDefinitions/custom/Router";

const IHEquipments = () => import("./views/Equipments/IHEquipments.vue");
const CreateIHEquipment = () => import("./views/Equipments/CreateIHEquipment.vue");
const UpdateIHEquipment = () => import("./views/Equipments/UpdateIHEquipment.vue");

const IHMaintenanceSchedule = () => import("./views/IHMaintenanceSchedule.vue");
const IHWeakPointAnalysis = () => import("./views/IHWeakPointAnalysis.vue");
const IHConfigs = () => import("./views/IHConfigs.vue");
const CreateIHConfig = () => import("./views/CreateIHConfig.vue");
const UpdateIHConfig = () => import("./views/UpdateIHConfig.vue");

const router: Router.LicensedRouterExportType = [
  {
    path: "/ih/equipments",
    name: "IHEquipments",
    component: IHEquipments,
    meta: {
      rights: ["ih.readWrite"],
    },
  },
  {
    path: "/ih/equipment",
    name: "CreateIHEquipment",
    component: CreateIHEquipment,
    meta: {
      rights: ["ih.readWrite"],
    },
  },
  {
    path: "/ih/equipment/:id",
    name: "UpdateIHEquipment",
    component: UpdateIHEquipment,
    meta: {
      rights: ["ih.readWrite"],
    },
    props: (route) => ({
      id: Number(route.params.id),
    }),
  },
  {
    path: "/ih/maintenanceSchedule",
    name: "IHMaintenanceSchedule",
    component: IHMaintenanceSchedule,
    meta: {
      rights: ["ih.readWrite"],
    },
  },
  {
    path: "/ih/weakPointAnalysis",
    name: "IHWeakPointAnalysis",
    component: IHWeakPointAnalysis,
    meta: {
      rights: ["ih.readWrite"],
    },
  },
  {
    path: "/ih/IHConfigs",
    name: "IHConfigs",
    component: IHConfigs,
    meta: {
      rights: ["ih.readWrite"],
    },
  },
  {
    path: "/ih/ihConfig",
    name: "CreateIHConfig",
    component: CreateIHConfig,
    meta: {
      rights: ["ih.readWrite"],
    },
  },
  {
    path: "/ih/ihConfig/:id",
    name: "UpdateIHConfig",
    component: UpdateIHConfig,
    props: (route) => ({
      id: route.params?.id ? Number(route.params.id) : undefined,
    }),
    meta: {
      rights: ["ih.readWrite"],
    },
  },
];

export default router;
