import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { ChartTileDisplayType, EventType } from "@/graphql";
import { isSpecialAdmin } from "@/functions/isSpecialAdmin";

export const tileConfigs: TileConfigType = {
  BDEGanttTile: {
    active: isSpecialAdmin,
    defaultProps: {
      name: "BDEGanttTile",
      eventTypes: [EventType.Issue],

      display: ChartTileDisplayType.DEFAULT,
    },
    settingsModule: defineAsyncComponent(
      () => import("./components/Tiles/SQDCBDEGanttTileSettings.vue"),
    ),
    component: defineAsyncComponent(() => import("./components/Tiles/SQDCBDEGanttTile.vue")),
    icon: "fas fa-chart-bar",
  },
};
