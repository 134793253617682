export default {
  endpoints: {
    getStampData: {
      route: "stamp/monthly/:id",
      method: "POST",
    },
    getStampDataDaily: {
      route: "stamp/daily",
      method: "POST",
    },
    getStampDataYearly: {
      route: "stamp/yearly",
      method: "POST",
    },
    changeEntry: {
      route: "stamp/edit",
      method: "POST",
    },
    addStamp: {
      route: "addStamp",
      method: "POST",
    },
  },
};
