<template>
  <ValidationObserver
    v-slot="{ meta, valid: observerValid, dirty: dirtyPrivate, touched: touchedPrivate }"
    :no-propagation="noPropagation"
    :name="observerName"
  >
    <LModalObserverInjector
      :dirty="dirtyPrivate"
      :touched="touchedPrivate"
      :valid="observerValid"
      @update:valid="valid = $event"
      @update:dirty="dirty = $event"
      @update:touched="touched = $event"
    />
    <DialogPrime
      ref="dialog"
      v-model:visible="visibleProp"
      v-bind="$attrs"
      :content-style="modalWidth"
      :style="modalStyle"
      :breakpoints="breakpoints"
      modal
      dismissable-mask
      maximizable
      :keep-in-viewport="false"
      :header="title"
      @show="$emit('show', $event)"
      @paste.stop
    >
      <template v-if="showFooter" #footer>
        <div class="pt-2">
          <slot
            name="footer"
            :valid="observerValid"
            :disabled="loading || disabled"
            :ok-disabled="okDisabled"
            :close="close"
            :hide="close"
            :ok="submitForm"
          >
            <slot name="footerExtra" />
            <LISAButton
              v-if="!okOnly"
              id="modal-button-cancel"
              autofocus
              :disabled="disabled"
              severity="secondary"
              @click="close"
            >
              <slot name="modal-cancel" :disabled="disabled">
                <i class="fas fa-times mr-1" />{{ $t("global.cancel") }}
              </slot>
            </LISAButton>
            <LISAButton
              id="modal-button-submit"
              :severity="okVariant"
              :disabled="loading || disabled || okDisabled || !observerValid"
              @click="submitForm"
            >
              <slot name="modal-ok" :disabled="loading || disabled || okDisabled || !observerValid">
                <i class="fas fa-save mr-1" />{{ $t("global.save") }}
              </slot>
            </LISAButton>
          </slot>
        </div>
      </template>
      <template #header>
        <slot name="header" :valid="observerValid" />
      </template>

      <template #default>
        <form ref="submitElement" @submit.prevent="submitForm">
          <slot name="default" :valid="observerValid" :meta="meta" :submit="submitForm">
            <div v-if="loading" class="flex justify-content-center">
              <ProgressSpinner />
            </div>
          </slot>
          <LISAButton type="submit" style="display: none" />
        </form>
      </template>
    </DialogPrime>
  </ValidationObserver>
</template>

<script lang="ts">
import ProgressSpinner from "primevue/progressspinner";
import { computed, defineComponent, onMounted, PropType, ref, watch } from "vue";
import LISAButton from "@LISAPrime/LISAButton.vue";
import { useI18n } from "vue-i18n";
import Dialog from "primevue/dialog";
import ValidationObserver from "@/modules/veevalidate/components/ValidationObserver.vue";
import LModalObserverInjector from "./shared/utility/LModalObserverInjector.vue";
import { confirmModal } from "./shared/form/ConfirmationModal";

export default defineComponent({
  name: "LModal",
  components: {
    ProgressSpinner,
    LISAButton,
    DialogPrime: Dialog,
    ValidationObserver,
    LModalObserverInjector,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    okVariant: {
      type: String,
      required: false,
      default: "primary",
    },
    size: {
      type: String as PropType<"sm" | "md" | "lg" | "xl" | "xxl">,
      required: false,
      default: "md",
    },
    minHeight: {
      type: String,
      default: "unset",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    okOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPropagation: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    observerName: {
      type: String,
      required: false,
      default: "LModal",
    },
  },
  emits: ["discardChanges", "update:visible", "ok", "show", "abort", "cancel", "observer:valid"],
  setup(props, { emit }) {
    const dirty = ref(false);
    const touched = ref(false);
    const valid = ref(true);
    const { t } = useI18n();
    const visibleProp = computed({
      get() {
        return props.visible;
      },
      set(value: boolean) {
        emit("update:visible", value);
      },
    });

    const dialog = ref<
      | (InstanceType<typeof Dialog> & {
          close: () => void;
          onMaskClick: (e: PointerEvent) => void;
        })
      | null
    >(null);
    onMounted(() => {
      if (dialog.value) {
        dialog.value.onMaskClick = (e: PointerEvent) => {
          const el = document.activeElement;
          /**
           * Due to the portal-function from primevue the active Element, while a "portaled" element is open, is the body.
           * This means we can determine if the user is inside a input field or not and therefor close the modal and vice versa.
           */
          if (
            el?.tagName === "BODY" &&
            (e.target as HTMLDivElement)?.classList?.contains("p-dialog-mask") === true
          ) {
            dialog.value?.close();
          }
        };
        const originalCloseMethod = dialog.value.close;
        dialog.value.close = async function closeFunc(reason?: string | Event) {
          if (dirty.value === true /* && touched.value === true */) {
            confirmModal({
              message: t("components.event-action.TaskModal.confirmText"),
              header: t("global.confirm"),
              acceptLabel: t("global.yes"),
              rejectLabel: t("global.cancel"),
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                emit("discardChanges");
                originalCloseMethod();
                if (typeof reason === "string" && reason === "abort") {
                  emit("abort");
                } else {
                  emit("cancel");
                }
              },
            });
          } else {
            if (typeof reason === "string" && reason === "abort") {
              emit("abort");
            } else {
              emit("cancel");
            }
            originalCloseMethod();
          }
        };
      } else {
        // eslint-disable-next-line no-console
        console.warn("Dialog close function could not be replaced");
      }
    });

    async function submitForm(...args: any) {
      if (valid.value === true) {
        emit("ok", ...args);
        emit("update:visible", false);
      }
    }

    function close() {
      // @ts-expect-error pass parameter event its not allowed (prototype was overridden)
      dialog.value?.close("abort");
    }

    const breakpoints = computed(() => {
      switch (props.size) {
        case "lg":
          return { "991px": "500px" } as Record<string, string>;
        case "xl":
          return { "1199px": "800px", "991px": "500px" } as Record<string, string>;
        case "xxl":
          return { "991px": "500px" } as Record<string, string>;
        default:
          return undefined;
      }
    });

    const modalWidth = computed(() => {
      switch (props.size) {
        case "sm":
          return `width: 100%; min-height: ${props.minHeight};`;
        case "lg":
          return `width: 100%; min-height: ${props.minHeight};`;
        case "xl":
          return `width: 100%; min-height: ${props.minHeight};`;
        case "xxl":
          return `width: 100%; min-height: ${props.minHeight};`;
        default:
          return `width: 100%; min-height: ${props.minHeight};`;
      }
    });

    const modalStyle = computed(() => {
      switch (props.size) {
        case "sm":
          return `width: 300px`;
        case "lg":
          return `width: 800px;`;
        case "xl":
          return `width: 1140px;`;
        case "xxl":
          return `width: 90vw;`;
        default:
          return `width: 500px;`;
      }
    });

    function show() {
      visibleProp.value = true;
    }

    watch(
      valid,
      () => {
        emit("observer:valid", valid.value);
      },
      {
        immediate: true,
      },
    );
    return {
      dirty,
      touched,
      valid,
      show,
      visibleProp,
      breakpoints,
      modalWidth,
      modalStyle,
      dialog,
      submitForm,
      close,
    };
  },
});
</script>

<style lang="scss" scoped></style>
