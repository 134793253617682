export enum ModifierType {
  settings = 1 << 0,
  clippy = 1 << 1,
  unicorn = 1 << 2,
  experimentalFeature = 1 << 3,
  lucasStatusMode = 1 << 4,
  idHighlightWaves = 1 << 5,
  idHighlightBadge = 1 << 6,
  eabPerspective = 1 << 7,
  enableSFX = 1 << 8,
  enableUserThemes = 1 << 9,
  enableChangeRequest = 1 << 10,
  enableSearchBar = 1 << 11,
  enableExcelDownload = 1 << 12,
  bastiPriorityOrderDashboard = 1 << 13,
  theme1 = 1 << 14,
  theme2 = 1 << 15,
  theme3 = 1 << 16,
  theme4 = 1 << 17,
}
