import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { EventType } from "@/graphql";

export const tileConfigs: TileConfigType = {
  ChecklistTile: {
    active: true,
    defaultProps: {
      name: "ChecklistTile",
      eventTypes: [EventType.Issue],
    },
    settingsModule: defineAsyncComponent(() => import("./tiles/ChecklistTileSettings.vue")),
    component: defineAsyncComponent(() => import("./tiles/ChecklistTile.vue")),
    icon: "fas fa-check",
  },
};
