<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useGetLISAThemeQuery, useLISAInstanceThemeUpdatedSubscription } from "../../../graphql";
import { cloneDeep } from "lodash";
import { useStore } from "vuex";
import isModifierSet from "../../../functions/userModifier";
import { ModifierType } from "../../../functions/userModifierEnum";
import { useLISAThemeProviderHelper } from "../helper/useLISAThemeProviderHelper";

const { lisaTheme, loadCurrentTheme } = useLISAThemeProviderHelper();
loadCurrentTheme();
const store = useStore();
const { onResult: onQueryResult } = useGetLISAThemeQuery(() => {
  const userId = Number(store.getters["login/userId"]);
  return {
    userId: isModifierSet(ModifierType.enableUserThemes) ? userId : undefined,
  };
});

onQueryResult((res) => {
  if (res && res.data && res.data.GetLISATheme) {
    lisaTheme.value = cloneDeep(res.data.GetLISATheme.theme);
  }
});

const { onResult } = useLISAInstanceThemeUpdatedSubscription();

onResult((res) => {
  if (
    res &&
    res.data &&
    res.data.LISAInstanceThemeUpdated &&
    isModifierSet(ModifierType.enableUserThemes) === false
  ) {
    lisaTheme.value = cloneDeep(res.data.LISAInstanceThemeUpdated.theme);
  }
});
</script>
