import { Component, defineAsyncComponent } from "vue";
import { ChartInterval, EventType, SQDCTileTypes, SQDCIFrameTileDisplayMethod } from "@/graphql";
import { isModuleActive } from "@/functions/moduleConfig";

export type TileConfigElementType = {
  active: boolean | (() => boolean);
  settingsModule: Component;
  component?: Component;
  defaultProps?: any;
  icon: string;
};

export type TileConfigType = Partial<Record<SQDCTileTypes | "LinkToTile", TileConfigElementType>>;

let licenseTileConfigs: TileConfigType = {};
const modules: Record<string, { tileConfigs: TileConfigType }> = import.meta.glob(
  "../../license/*/tileConfigs.ts",
  {
    eager: true,
  },
);
Object.entries(modules).forEach(([path, module]) => {
  if (isModuleActive(path.slice(2).split("/")[3])) {
    licenseTileConfigs = { ...licenseTileConfigs, ...module.tileConfigs };
  }
});

let moduleTileConfigs: TileConfigType = {};
const moduleTiles: Record<string, { tileConfigs: TileConfigType }> = import.meta.glob(
  "/**/modules/*/tileConfigs.ts",
  {
    eager: true,
  },
);
Object.entries(moduleTiles).forEach(([_path, module]) => {
  moduleTileConfigs = { ...moduleTileConfigs, ...module.tileConfigs };
});

export const tileConfigs: TileConfigType = {
  DepartmentsTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/DepartmentsTileSettings.vue"),
    ),
    defaultProps: {
      name: "DepartmentsTile",
      meta: {},
      departmentConnection: undefined,
      eventTypes: [],
    },
    component: defineAsyncComponent(
      () => import("./components/departmentsTile/DepartmentsTile.vue"),
    ),
    icon: "fas fa-sitemap",
  },
  VitalityTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("@/components/tiles/settingsComponents/VitalityTileSettings.vue"),
    ),
    defaultProps: {
      name: "VitalityTile",
      meta: {
        filter: undefined,
        displayMethod: undefined,
        overdue: undefined,
      },
      vitalityConnections: [],
      vitalityTemplateConnection: {},
      eventTypes: [],
    },
    component: defineAsyncComponent(
      () => import("@/modules/tiles/components/vitalityTile/VitalityTile.vue"),
    ),
    icon: "fas fa-heart-pulse",
  },
  CircleTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/CircleTileSettings.vue"),
    ),
    defaultProps: {
      name: "Circle",
      meta: {
        emptyColor: "#00FF00",
        interval: "shift",
        displayDaysSince: false,
        activeInput: false,
        inputInFuture: true,
        aggregate: false,
      },
      eventTypes: [EventType.Issue],
    },
    component: defineAsyncComponent(() => import("./components/rainbowTile/CircleTile.vue")),
    icon: "fas fa-dot-circle",
  },
  SVGTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/SVGTileSettings.vue"),
    ),

    defaultProps: {
      name: "SVG",
      meta: {
        question: "",
        emptyColor: "#00FF00",
        shiftScheduleId: null,
        displayDaysSince: false,
        interval: "shift",
        activeInput: false,
        inputInFuture: true,
        aggregate: false,
      },
      eventTypes: [EventType.Issue],
    },
    component: defineAsyncComponent(() => import("./components/rainbowTile/SVGTile.vue")),
    icon: "fas fa-image",
  },
  DocumentTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/DocumentTileSettings.vue"),
    ),
    defaultProps: {
      name: "Document",
      meta: {
        interval: "shift",
        trailingStripe: true,
      },
      folderConn: [],
      eventTypes: [EventType.Issue],
    },
    component: defineAsyncComponent(() => import("./components/documentTile/DocumentTile.vue")),
    icon: "fas fa-images",
  },
  DashListTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/DashlistSettings.vue"),
    ),
    defaultProps: {
      name: "DashList",
      meta: {
        templateId: 0,
        shiftScheduleId: 0,
        data: [],
        interval: "shift",
      },
      eventTypes: [EventType.Issue],
    },
    component: defineAsyncComponent(() => import("./components/dashListTile/DashListTile.vue")),
    icon: "fas fa-tasks",
  },
  CalendarTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/CalendarTileSettings.vue"),
    ),
    defaultProps: {
      name: "Calendar",
      meta: {
        interval: "shift",
        mode: "day",
        focusedView: "dayGridMonth",
        type: "INTERNAL",
      },
      eventTypes: [EventType.Issue],
    },
    component: defineAsyncComponent(() => import("./components/calendarTile/CalendarTile.vue")),
    icon: "fas fa-calendar-alt",
  },
  LinkToTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("../../components/tiles/settingsComponents/LinkToTileSettings.vue"),
    ),
    defaultProps: {
      name: "LinkTo",
      meta: {
        tileId: -1,
      },
      eventTypes: [EventType.Issue],
    },
    icon: "fas fa-link",
  },
  IFrameTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("./components/iFrameTile/IFrameTileSettings.vue"),
    ),
    defaultProps: {
      name: "iFrame",
      meta: {
        displayMethodIFrame: SQDCIFrameTileDisplayMethod.IFrame,
      },
      eventTypes: [EventType.Issue],
    },
    component: defineAsyncComponent(() => import("./components/iFrameTile/IFrameTile.vue")),
    icon: "fas fa-person-through-window",
  },
  LayoutTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("./components/layoutTile/LayoutTileSettings.vue"),
    ),
    defaultProps: {
      name: "LayoutTile",
      meta: {
        granularity: ChartInterval.MONTH,
      },
      eventTypes: [],
      layoutConnections: [],
    },
    component: defineAsyncComponent(() => import("./components/layoutTile/LayoutTile.vue")),
    icon: "fas fa-universal-access",
  },
  ...moduleTileConfigs,
  ...licenseTileConfigs,
};

export function getTileIcon(tileType: SQDCTileTypes | "LinkToTile") {
  return tileConfigs[tileType]?.icon ?? "fas fa-question";
}
