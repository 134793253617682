<template>
  <div class="inline-block info-tooltip">
    <div
      class="inline-block"
      @mouseover="showOverlay"
      @touchstart="showOverlay"
      @mouseleave="resetTimer"
    >
      <slot name="activator">
        <i :class="usedIcon" :style="`color: var(--${variant}-color);`" />
      </slot>
    </div>
    <Popover
      v-if="init"
      ref="op"
      :append-to="targetElement"
      :style="`max-width: ${maxWidth}`"
      class="popoverClass"
      :pt="{
        content: {
          class: 'p-0',
        },
      }"
    >
      <div class="titleBackground">
        <h4 class="pt-2 pl-2 pr-2 mt-0">{{ title ?? $t("global.information") }}</h4>
      </div>
      <slot v-if="rerenderContent === true ? visible : true">
        <div class="m-2">{{ text }}</div>
      </slot>
    </Popover>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, onMounted } from "vue";
import { v4 as uuid } from "uuid";
import Popover from "primevue/popover";

export default defineComponent({
  name: "InfoTooltip",
  components: { Popover },
  props: {
    text: { type: String, required: false, default: "" },
    fullWidth: { type: Boolean, required: false, default: false },
    title: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String as PropType<"error" | "warn" | "success" | "secondary" | "primary" | "info">,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: Function as PropType<() => HTMLElement | undefined>,
      default: null,
    },
    rerenderContent: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "450px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const visible = ref(false);
    const usedIcon = computed(() => {
      if (props.icon != null) {
        return props.icon;
      }
      switch (props.variant) {
        case "error":
        case "warn":
          return "fas fa-exclamation-triangle";
        default:
          return "fas fa-info-circle";
      }
    });
    const op = ref<InstanceType<typeof Popover>>();
    const timer = ref<NodeJS.Timeout>();

    function showOverlay(event: Event) {
      if (props.disabled) {
        return;
      }
      clearTimeout(timer.value);
      op.value?.show(event);
      visible.value = true;
    }
    function hideOverlay() {
      op.value?.hide();
      visible.value = false;
    }
    function resetTimer() {
      timer.value = setTimeout(() => {
        hideOverlay();
      }, 700);
    }
    const targetElement = ref<HTMLElement>();
    const init = ref(false);
    onMounted(() => {
      if (props.target != null) {
        targetElement.value = props.target();
      }
      init.value = true;
    });

    return {
      usedIcon,
      op,
      uuid: `d${uuid()}`,
      showOverlay,
      hideOverlay,
      targetElement,
      init,
      resetTimer,
      visible,
    };
  },
});
</script>

<style lang="scss" scoped>
h5 {
  margin-top: 0;
  margin-bottom: 0;
}
.titleBackground {
  background: var(--surface-100);
  height: 35px;
  border-bottom: 1px solid lightgrey;
}
</style>

<style lang="scss">
.p-button.p-disabled .info-tooltip,
.p-button.p-disabled .info-tooltip * {
  pointer-events: all !important;
}
.popoverClass::after {
  border-bottom-color: var(--surface-100) !important;
}
</style>
