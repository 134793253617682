export default {
  endpoints: {
    // Datapoint Type
    checkNameAvailability: {
      route: "checkNameAvailability",
      method: "POST",
    },
    getDatapointTypes: {
      route: "types",
      method: "GET",
    },
    getDatapointType: {
      route: "type/:id",
      method: "GET",
    },
  },
};
