import { palette, updatePreset } from "@primevue/themes";
import { computed, watch } from "vue";
import { ref } from "vue";

const lisaThemeDefault = ref<LisaThemeType>({
  "primary-color": "#3b82f6",
  "secondary-color": "#6b7280",
  "success-color": "#22c55e",
  "warning-color": "#f97316",
  "danger-color": "#ef4444",
  "info-color": "#0ea5e9",

  "border-radius": "4px",

  // veevalidate
  "lisa-valid": "#198754",
  "lisa-invalid": "#dc3545",
});

const lisaThemeLocal = ref<LisaThemeType>({
  ...lisaThemeDefault.value,
});

export type LisaThemeType = {
  ["primary-color"]: string;
  ["secondary-color"]: string;
  ["success-color"]: string;
  ["warning-color"]: string;
  ["danger-color"]: string;
  ["info-color"]: string;
  ["lisa-valid"]: string;
  ["lisa-invalid"]: string;
  ["border-radius"]: string;
};

const lisaThemePrimeVue4 = ref({
  primitive: {
    borderRadius: {
      none: "0",
      xs: "2px",
      sm: "4px",
      md: "4px",
      lg: "4px",
      xl: "6px",
    },
    blue: {
      //primary
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
      950: "#172554",
    },
    slate: {
      //secondary
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#334155",
      800: "#1e293b",
      900: "#0f172a",
      950: "#020617",
    },
    green: {
      //success
      50: "#f0fdf4",
      100: "#dcfce7",
      200: "#bbf7d0",
      300: "#86efac",
      400: "#4ade80",
      500: "#22c55e",
      600: "#16a34a",
      700: "#15803d",
      800: "#166534",
      900: "#14532d",
      950: "#052e16",
    },
    red: {
      //danger
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
      950: "#450a0a",
    },
    sky: {
      //info
      50: "#f0f9ff",
      100: "#e0f2fe",
      200: "#bae6fd",
      300: "#7dd3fc",
      400: "#38bdf8",
      500: "#0ea5e9",
      600: "#0284c7",
      700: "#0369a1",
      800: "#075985",
      900: "#0c4a6e",
      950: "#082f49",
    },
    orange: {
      //warn
      50: "#fff7ed",
      100: "#ffedd5",
      200: "#fed7aa",
      300: "#fdba74",
      400: "#fb923c",
      500: "#f97316",
      600: "#ea580c",
      700: "#c2410c",
      800: "#9a3412",
      900: "#7c2d12",
      950: "#431407",
    },
  },
});

function changeLisaTheme(theme: LisaThemeType) {
  const colorMapping = [
    ["primary-color", "blue"],
    ["success-color", "green"],
    ["secondary-color", "slate"],
    ["warning-color", "orange"],
    ["danger-color", "red"],
    ["info-color", "sky"],
  ] as const;

  colorMapping.forEach(([color, baseColor]) => {
    const colors = palette(theme[color]);
    lisaThemePrimeVue4.value.primitive[baseColor] =
      colors as typeof lisaThemePrimeVue4.value.primitive.blue;
  });

  lisaThemePrimeVue4.value.primitive.borderRadius.sm = theme["border-radius"];
  lisaThemePrimeVue4.value.primitive.borderRadius.md = theme["border-radius"];
  lisaThemePrimeVue4.value.primitive.borderRadius.lg = theme["border-radius"];

  document.documentElement.style.setProperty("--lisa-valid", theme["lisa-valid"]);
  document.documentElement.style.setProperty("--lisa-invalid", theme["lisa-invalid"]);

  // const primevue = usePrimeVue();
  // primevue.config.ripple = false;
}

export function useLISAThemeProviderHelper() {
  const lisaTheme = computed({
    get() {
      return lisaThemeLocal.value;
    },
    set(theme: LisaThemeType) {
      lisaThemeLocal.value = theme;
    },
  });

  watch(
    lisaThemeLocal,
    (theme) => {
      changeLisaTheme(theme);
      updatePreset(lisaThemePrimeVue4.value);
    },
    { deep: true },
  );
  return {
    lisaTheme,
    setDefaultTheme: () => {
      lisaThemeLocal.value = lisaThemeDefault.value;
    },
    loadCurrentTheme: () => {
      changeLisaTheme(lisaThemeLocal.value);
      updatePreset(lisaThemePrimeVue4.value);
    },
  };
}
