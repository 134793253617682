export default {
  endpoints: {
    getForUser: {
      route: "forUser",
      method: "POST",
    },
    updateStatus: {
      route: "task/:id/status/:status",
      method: "PUT",
    },
    getOpenIssues: {
      route: "openCount",
      method: "GET",
    },
  },
};
