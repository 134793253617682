import { computed } from "vue";
import { IterableElement } from "type-fest";
import {
  EisenhowerPriority,
  EventListEventFields_ChangeRequest_Fragment,
  EventListEventFields_HLPIdea_Fragment,
  EventListEventFields_IHFailureNotice_Fragment,
  EventListEventFields_IHMaintenance_Fragment,
  EventListEventFields_Issue_Fragment,
  EventListEventFields_QualityAssurance_Fragment,
  Maybe,
  Scalars,
  SQDCBoardIssueConnectionTypes,
} from "@/graphql";

export type BoardEventConnectionType = {
  createdAt: Scalars["Date"]["output"];
  status: SQDCBoardIssueConnectionTypes;
  reason?: Maybe<Scalars["String"]["output"]>;
};

export enum AbstractIssueModalComponentFields {
  description = "description",
  date = "date",
  owner = "owner",
  flags = "flags",
  creator = "creator",
  eisenhowerPriority = "eisenhowerPriority",
}

export function getBoardEventConnection(
  board:
    | IterableElement<EventListEventFields_Issue_Fragment["boards"]>
    | IterableElement<EventListEventFields_IHFailureNotice_Fragment["boards"]>
    | IterableElement<EventListEventFields_IHMaintenance_Fragment["boards"]>
    | IterableElement<EventListEventFields_HLPIdea_Fragment["boards"]>
    | IterableElement<EventListEventFields_ChangeRequest_Fragment["boards"]>
    | IterableElement<EventListEventFields_QualityAssurance_Fragment["boards"]>,
): BoardEventConnectionType | undefined {
  if ("BoardIssueConnection" in board) {
    if (board.BoardIssueConnection == null) {
      return undefined;
    }
    return {
      ...board.BoardIssueConnection,
    };
  }

  if ("IHSQDCBoardFailureNoticeConnection" in board) {
    if (board.IHSQDCBoardFailureNoticeConnection == null) {
      return undefined;
    }
    return {
      ...board.IHSQDCBoardFailureNoticeConnection,
    };
  }

  if ("IHSQDCBoardMaintenanceConnection" in board) {
    if (board.IHSQDCBoardMaintenanceConnection == null) {
      return undefined;
    }
    return {
      ...board.IHSQDCBoardMaintenanceConnection,
    };
  }

  if ("BoardIdeaConnection" in board) {
    if (board.BoardIdeaConnection == null) {
      return undefined;
    }
    return {
      ...board.BoardIdeaConnection,
    };
  }

  if ("BoardChangeRequestConnection" in board) {
    if (board.BoardChangeRequestConnection == null) {
      return undefined;
    }
    return {
      ...board.BoardChangeRequestConnection,
    };
  }
  if ("SQDCBoardQualityAssuranceConnection" in board) {
    if (board.SQDCBoardQualityAssuranceConnection == null) {
      return undefined;
    }
    return {
      ...board.SQDCBoardQualityAssuranceConnection,
    };
  }
  throw Error("Unknown event connection type");
}

export const getIconClass = (priority: EisenhowerPriority) => {
  switch (priority) {
    case EisenhowerPriority.DO:
      return "fa-solid fa-bolt";
    case EisenhowerPriority.SCHEDULE:
      return "fas fa-calendar";
    case EisenhowerPriority.DELEGATE:
      return "fas fa-user-plus";
    case EisenhowerPriority.DELETE:
      return "fas fa-trash";
    case EisenhowerPriority.NO_PRIORITY:
      return "fa-solid fa-ban";
    default:
      throw Error("invalid eisenhower priority");
  }
};

export const eisenhowerOptions = computed(() => {
  return [
    {
      label: window.$i18n?.t(`components.eventAction.filter.badges.PriorityFilter.DO`),
      value: EisenhowerPriority.DO,
      icon: "fa-solid fa-bolt",
    },
    {
      label: window.$i18n?.t(`components.eventAction.filter.badges.PriorityFilter.SCHEDULE`),
      value: EisenhowerPriority.SCHEDULE,
      icon: "fas fa-calendar",
    },
    {
      label: window.$i18n?.t(`components.eventAction.filter.badges.PriorityFilter.DELEGATE`),
      value: EisenhowerPriority.DELEGATE,
      icon: "fas fa-user-plus",
    },
    {
      label: window.$i18n?.t(`components.eventAction.filter.badges.PriorityFilter.DELETE`),
      value: EisenhowerPriority.DELETE,
      icon: "fas fa-trash",
    },
    {
      label: window.$i18n?.t(`components.eventAction.filter.badges.PriorityFilter.noPriority`),
      value: EisenhowerPriority.NO_PRIORITY,
      icon: "fa-solid fa-ban",
    },
  ];
});

export const getStyle = (modelValue: EisenhowerPriority) => {
  switch (modelValue) {
    case EisenhowerPriority.DO:
      return "background-color: var(--error-color); color: white;";
    case EisenhowerPriority.SCHEDULE:
      return "background-color: var(--warn-color); color: black;";
    case EisenhowerPriority.DELEGATE:
      return "background-color: var(--success-color); color: white;";
    case EisenhowerPriority.DELETE:
      return "background-color: var(--secondary-color); color: white;";
    case EisenhowerPriority.NO_PRIORITY:
      return "background-color: var(--disabled-color); color: black;";
    default:
      return "";
  }
};
