<template>
  <DialogPrime
    v-model:visible="visible"
    modal
    :dismissable-mask="true"
    position="top"
    :close-on-escape="true"
    class="mt-0"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    @hide="visible = false"
    @show="searchText = ''"
  >
    <template #container>
      <CMSearchField
        v-model:search-text="searchText"
        v-model:selected-result="selectedResult"
        :results="results"
        @selected="gotToSelected"
      />
    </template>
  </DialogPrime>
</template>

<script setup lang="ts">
import DialogPrime from "primevue/dialog";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Fuse from "fuse.js";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
import {
  useGSSearchGloballyQuery,
  GSSearchResultType,
  GSSearchResultEventMeta,
  GSSearchResultActionMeta,
  GSSearchResultTileMeta,
} from "@/graphql";
import { useUserMenu } from "@/functions/useUserMenu";
import notEmpty from "@/functions/notEmpty";
import type { ResultEntryType, SearchOptionType } from "../helper/CommandMenuType";
import CMSearchField from "../components/CMSearchField.vue";
import { getSearchResultName } from "../helper/CommandMenuHelper";

const visible = defineModel({
  required: true,
  type: Boolean,
});

const searchText = ref("");

const router = useRouter();

const { t } = useI18n();

const selectedResult = ref<ResultEntryType<any>>();

const { result: backendSearchQueryResult, loading } = useGSSearchGloballyQuery(
  computed(() => ({
    searchString: searchText.value,
  })),
  computed(() => ({
    enabled: searchText.value.length >= 2,
  })),
);

const backendSearchResult = computed(() =>
  searchText.value.length >= 2 ? backendSearchQueryResult.value?.GSSearchGlobally : [],
);

// Menu search
const { menu } = useUserMenu();
const menuOptions = computed<SearchOptionType<GSSearchResultType.MENU>[]>(() => {
  return menu.value.flatMap<SearchOptionType<GSSearchResultType.MENU>>((el) =>
    (
      (el.child?.flatMap<SearchOptionType<GSSearchResultType.MENU> | undefined>((level1) =>
        level1.child?.map<SearchOptionType<GSSearchResultType.MENU>>((level2) => ({
          name: [el.title, level1.title, level2.title].join(" / "),
          searchResultType: GSSearchResultType.MENU,
          meta: level2,
        })),
      ) ?? [
        { name: el.title, searchResultType: GSSearchResultType.MENU, meta: el },
      ]) as SearchOptionType<GSSearchResultType.MENU>[]
    ).filter(notEmpty),
  );
});

// Search function
const results = computed(() => {
  const fuseUserMenu = new Fuse(menuOptions.value, {
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.3,
    ignoreLocation: true,
    minMatchCharLength: 2,
    keys: ["name"],
  });

  const userMenuSearchResults = fuseUserMenu
    .search(searchText.value)
    .map((el, index) => ({ ...el.item, index }))
    .slice(0, 10);

  const resultEntries: ResultEntryType<any>[] = [
    ...userMenuSearchResults,
    ...(backendSearchResult.value?.map((searchResult, index) => ({
      name: getSearchResultName(searchResult, t),
      searchResultType: searchResult.type,
      meta: searchResult.meta,
      index: index + userMenuSearchResults.length,
      overlay:
        searchResult.type === GSSearchResultType.EVENT ||
        searchResult.type === GSSearchResultType.ACTION,
    })) ?? []),
  ];

  if (loading.value) {
    resultEntries.push({
      name: t("global.loading"),
      searchResultType: GSSearchResultType.LOADING,
      meta: null,
      index: resultEntries.length,
    });
  }

  if (searchText.value.toLocaleLowerCase() === "bastian frisch") {
    resultEntries.push({
      name: "Activate Admin rights",
      searchResultType: GSSearchResultType.EASTER_EGG,
      meta: {
        href: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
      },
      index: resultEntries.length,
    });
  }

  return resultEntries;
});

// Handling
function gotToSelected() {
  if (selectedResult.value) {
    visible.value = false;
    /* eslint-disable no-case-declarations */
    switch (selectedResult.value.searchResultType) {
      case GSSearchResultType.MENU:
        const menuMeta = selectedResult.value
          .meta as ResultEntryType<GSSearchResultType.MENU>["meta"];
        router.push({
          path: menuMeta.href!,
        });
        break;
      case GSSearchResultType.EVENT:
        const eventMeta = selectedResult.value.meta as GSSearchResultEventMeta;

        const archiveOptions: { archiveFrom?: string; archiveTo?: string } = {};

        if (eventMeta.deletedAt != null) {
          const archiveDate = DateTime.fromISO(eventMeta.occurrenceDate);
          archiveOptions.archiveFrom = archiveDate.minus({ days: 2 }).toISODate();
          archiveOptions.archiveTo = archiveDate.plus({ days: 2 }).toISODate();
        }

        router.push({
          name: "EventActionBoardNew",
          params: {
            boardId: eventMeta.boardId,
          },
          query: {
            scrollToId: eventMeta.eventId,
            scrollToType: eventMeta.eventType,
            ...archiveOptions,
          },
          force: true,
        });
        break;
      case GSSearchResultType.ACTION:
        const actionMeta = selectedResult.value.meta as GSSearchResultActionMeta;

        router.push({
          name: "EventActionBoardNew",
          params: {
            boardId: actionMeta.boardId,
          },
          query: {
            scrollToId: actionMeta.eventId,
            scrollToType: actionMeta.eventType,
          },
          force: true,
        });
        break;
      case GSSearchResultType.TILE:
        const tileMeta = selectedResult.value.meta as GSSearchResultTileMeta;
        router.push({
          name: "KPIBoardNew",
          params: {
            boardId: tileMeta.boardId,
          },
          force: true,
        });
        break;
      case GSSearchResultType.EASTER_EGG:
        if (selectedResult.value.meta != null && "href" in selectedResult.value.meta) {
          window.open(selectedResult.value.meta.href, "_blank");
        }
        break;
      default:
        break;
    }
    /* eslint-enable no-case-declarations */
  }
}

function globalKeyHandler(e: KeyboardEvent) {
  if ((e.metaKey || e.ctrlKey) && (e.key === "k" || e.key === "K")) {
    visible.value = true;
  } else if (visible.value) {
    if (e.key === "Escape") {
      visible.value = false;
    }
  }
}

onMounted(() => {
  window.addEventListener("keydown", globalKeyHandler, true);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", globalKeyHandler, true);
});
</script>

<style lang="scss" scoped>
.commandMenu {
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .search {
    padding: 1em;
  }

  .results {
    border-top: 1px solid #ccc;
    padding: 1em;
  }
}
</style>
