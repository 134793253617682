import Keycloak from "keycloak-js";
import keycloakConfig from "./KeycloakConfig";

export const keycloak = new Keycloak({
  realm: keycloakConfig.realmName,
  url: keycloakConfig.url,
  clientId: keycloakConfig.clientId,
});

export async function initKeycloak() {
  const auth = await keycloak.init({ onLoad: "login-required" });
  return { auth, keycloak };
}
