import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/chartLayoutOverview",
    name: "chartLayoutOverview",
    component: () => import("./views/ChartLayoutOverview.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/chartLayout/create/:id",
    name: "createChartLayout",
    component: () => import("./views/CreateChartLayout.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/chartLayout/detail/:type/:id",
    name: "updateChartLayout",
    component: () => import("./views/UpdateChartLayout.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
    props: (route) => ({
      chartLayoutId: Number(route.params.id),
      type: route.params.type,
    }),
  },
  {
    path: "/target",
    name: "target",
    component: () => import("./views/TargetList.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
  },
];

export default router;
