import store from "@/store";

export function translationFunctionCode(functionCode: string) {
  if (functionCode === "EABName") {
    const eabName =
      store.getters["systemSettings/getPLBSort"]?.find(
        (entry: { name: string; value: any }) => entry.name === "eabName",
      )?.value ?? "LEGACY";
    return eabName === "LEGACY" ? "views.KPIBoard.psButton" : "views.KPIBoard.actionBoard";
  }
  return undefined;
}
