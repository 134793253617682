import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import { EventType } from "@/graphql";

export const tileConfigs: TileConfigType = {
  AssignmentTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("./components/Tile/Settings/AssignmentTileSettings.vue"),
    ),
    defaultProps: {
      name: "Assignment",
      eventTypes: [EventType.Issue],
      conf: {
        layoutId: null,
      },
    },
    component: defineAsyncComponent(() => import("./components/Tile/AssignmentTile.vue")),
    icon: "far fa-map",
  },
};
