import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/workflowOverview",
    name: "WorkflowOverview",
    component: () => import("./views/WorkflowList.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/createWorkflow",
    name: "CreateWorkflow",
    component: () => import("./views/CreateWorkflow.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/updateWorkflow/:workflowId",
    name: "UpdateWorkflow",
    component: () => import("./views/UpdateWorkflow.vue"),
    meta: {
      rights: ["sqdc.admin"],
    },
    props: (route) => ({
      workflowId: route.params?.workflowId ? Number(route.params.workflowId) : undefined,
    }),
  },
];

export default router;
