import { ActionListActionFieldsFragment, EventListEventFieldsFragment } from "@/graphql";
import { EventFilter, ActionFilter } from "../FilterTypes";
import IDFilterBadge from "../ui/badges/IDFilterBadge.vue";
import IDFilterUI from "../ui/IDFilterUI.vue";

export default class IDFilter implements EventFilter, ActionFilter {
  constructor(
    public readonly ids: number[],
    public readonly type?: string,
    public readonly isNegated?: boolean,
  ) {}

  static readonly Badge = IDFilterBadge;

  static readonly UI = IDFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = (() => {
      return this.filter(event);
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      return this.filter(action);
    })();
    return this.isNegated ? !isActive : isActive;
  }

  filter(item: EventListEventFieldsFragment | ActionListActionFieldsFragment) {
    if (this.type && (item as any).__typename !== this.type) {
      return false;
    }
    return this.ids.length === 0 || this.ids.includes(item.id);
  }
}
