import { defineAsyncComponent } from "vue";
import { TileConfigType } from "@/modules/tiles/tileConfigs";
import {
  ChartTimeFrameStartFormats,
  GeneralAnalyzeType,
  ChartInterval,
  ProductFamilyAnalyzeType,
} from "@/graphql";

export const tileConfigs: TileConfigType = {
  QualityParetoTile: {
    active: true,
    settingsModule: defineAsyncComponent(
      () => import("@/modules/tiles/components/qualityParetoTile/QualityParetoTileSettings.vue"),
    ),
    defaultProps: {
      name: "QualityParetoTile",
      meta: {
        timeFrame: ChartInterval.MONTH,
        timeFrameStart: ChartTimeFrameStartFormats.CURRENT,
        timeFrameCount: 1,
        generalAnalyzeType: GeneralAnalyzeType.PRODUCT_FAMILY,
        productFamilyAnalyzeType: ProductFamilyAnalyzeType.INPUT,
        includingCharacteristic: false,
        allowNullValues: false,
      },
      eventTypes: [],
    },
    component: defineAsyncComponent(
      () => import("@/modules/tiles/components/qualityParetoTile/QualityParetoTile.vue"),
    ),
    icon: "fa-solid fa-yin-yang",
  },
};
