import { Duration } from "luxon";
import { locale } from "@/i18n";

/**
 * converts a milliseconds in an hour string with two digits behind decimal point
 * @param value duration in milliseconds
 * @return value as string with decimal point in corresponding language
 */
export function formatDuration(value: number | null, minuteView = false): string {
  if (value == null) {
    return "";
  }
  if (minuteView) {
    if (value < 0) {
      return `-${Duration.fromMillis(value * -1).toFormat("hh:mm")}`;
    }
    return Duration.fromMillis(value).toFormat("hh:mm");
  }

  let result = "";
  const decimalSeparator =
    Intl.NumberFormat(locale.value)
      .formatToParts(1.1)
      .find((part) => part.type === "decimal")?.value ?? ",";
  const hourString = (Math.abs(value) / 3600000).toFixed(2);
  result = `0${hourString}`.slice(-Math.max(hourString.length, 5)).replace(".", decimalSeparator);

  return value < 0 ? `-${result}` : result;
}
