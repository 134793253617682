import { DateTime, DurationUnits } from "luxon";
import { defineRule, configure } from "vee-validate";
import {
  required,
  email,
  min,
  is_not as isNot,
  one_of as oneOf,
  max,
  length,
  regex,
  numeric,
} from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import de from "@/language/de/veevalidate.json";
import en from "@/language/en/veevalidate.json";
import fr from "@/language/fr/veevalidate.json";
import es from "@/language/es/veevalidate.json";
import hu from "@/language/hu/veevalidate.json";
import pl from "@/language/pl/veevalidate.json";
import pt from "@/language/pt/veevalidate.json";
import it from "@/language/it/veevalidate.json";
import uk_UA from "@/language/uk-UA/veevalidate.json";
import enGB from "@/language/en-GB/veevalidate.json";
import deCH from "@/language/de-CH/veevalidate.json";
import { apolloClient } from "./graphql/apollo";
import { Language } from "./graphql";
import { languageNames } from "./language";

configure({
  generateMessage: localize({
    de,
    de_CH: deCH,
    en,
    fr,
    es,
    hu,
    pl,
    pt,
    it,
    en_GB: enGB,
    uk_UA,
  }),
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  bails: false,
});

defineRule("email", email);
defineRule("numeric", numeric);
defineRule("min", min);
defineRule("max", max);
defineRule("length", length);
defineRule("required", required);
defineRule("isNot", isNot);
defineRule("oneOf", oneOf);
defineRule("regex", regex);
defineRule("requiredIfOtherEmpty", (_value: any, otherFields: any) => {
  if (!Array.isArray(otherFields)) {
    return false;
  }
  const valid = otherFields.some(
    (field) =>
      field != null &&
      (((typeof field === "string" || Array.isArray(field)) && field.length > 0) ||
        (typeof field === "object" && Object.keys(field).length > 0)),
  );
  return valid;
});

defineRule(
  "language",
  (value: Omit<Language, "revision" | "Revisions" | "__typename" | "id">, options: any) => {
    const languageKeys = Object.keys(languageNames);
    if (Array.isArray(options)) {
      return false;
    }
    if (typeof value !== "object") {
      return false;
    }

    const check = Object.entries(value).map((el) => {
      return languageKeys.includes(el[0]?.toString()) && el?.[1]?.toString()?.trim?.()?.length > 0;
    });
    return check.filter((el) => el === true).length >= (options.min || 0);
  },
);

defineRule("minValue", (value: string | number, options: any) => {
  if (value == null || value === "") {
    return false;
  }
  const data = typeof value === "string" ? parseFloat((value ?? "").replace(/,/g, ".")) : value;
  return data >= options[0];
});

defineRule("maxValue", (value: string | number, options: any) => {
  if (value == null || value === "") {
    return false;
  }
  const data = typeof value === "string" ? parseFloat((value ?? "").replace(/,/g, ".")) : value;
  return data <= options[0];
});

defineRule("maxAppearances", (value: any, { values, count }: Record<string, any>) => {
  return values.filter((element: any) => element === value).length <= count;
});

// TODO: typing
defineRule("uniqueAPI", async (value: any, data: any) => {
  const preparedData = {} as Record<string, any>;
  if (Array.isArray(data)) {
    preparedData.document = data[0];
    preparedData.variables = data[1];
    preparedData.key = data[2];
    preparedData.column = data[3];
  } else {
    Object.assign(preparedData, data);
  }
  const result = await apolloClient.query({
    query: preparedData.document,
    variables: {
      ...Object.fromEntries(
        Object.keys(preparedData.variables).map((el) => [
          el,
          // eslint-disable-next-line no-prototype-builtins
          (preparedData.variables?.[el] ?? {}).hasOwnProperty("value")
            ? preparedData.variables[el].value
            : preparedData.variables[el],
        ]),
      ),
      [preparedData.column]: value,
    },
    fetchPolicy: "network-only",
  });
  if (result.data[preparedData.key]) {
    return true;
  }
  return false;
});

defineRule("spaceNotAllowed", (value: any) => {
  return !/^\s|\s$/.test(value);
});

defineRule(
  "manualCheck",
  (_value: any, props: { valid?: boolean; check?: () => boolean; errorMessage?: string }) => {
    const result = (props.valid != null ? !!props.valid : props.check?.()) ?? false;

    return props.errorMessage != null && result === false ? props.errorMessage : result;
  },
);

defineRule("doubleSpaceNotAllowed", (value: any) => {
  return !/\s\s+/g.test(value);
});

defineRule("positive", (value: any) => {
  return typeof value === "number" && value > 0;
});

defineRule("notEmpty", (value: any) => {
  return value?.length > 0;
});

defineRule("requiredArrayEntryIfNot", (value: any, { target }: Record<string, any>) => {
  return value?.length > 0 || !!target;
});

defineRule("isTrue", (value: boolean) => {
  return value === true;
});

defineRule("notOneOf", (value: any, values: any[]) => {
  if (typeof values === "string") {
    return values !== value;
  }
  return values == null || values.indexOf(value) === -1;
});

defineRule("isDateInPast", (value: string | Date) => {
  if (typeof value === "string") {
    return DateTime.fromISO(value) <= DateTime.now();
  }
  return DateTime.fromJSDate(value) <= DateTime.now();
});

defineRule("isEndDateAfterStartDate", (value: Date, { target }: Record<string, any>) => {
  return DateTime.fromJSDate(value) >= DateTime.fromJSDate(target);
});

defineRule(
  "compareDates",
  (
    value: any,
    target: {
      endDate: Date | string;
      diffValue?: number;
      diffUnit?: DurationUnits;
      isDate?: boolean;
    },
  ) => {
    const isDate = target.isDate ?? false;
    let startDate;
    let endDate;
    if (isDate) {
      startDate = DateTime.fromJSDate(value);
      endDate = DateTime.fromJSDate(target.endDate as Date);
    } else {
      startDate = DateTime.fromISO(value);
      endDate = DateTime.fromISO(target.endDate as string);
    }
    const diffUnit = target.diffUnit ?? "days";
    const diffValue = target.diffValue ?? 1;
    return startDate.diff(endDate, diffUnit).toMillis() >= diffValue;
  },
);

defineRule("isDate", (value: string | Date | DateTime) => {
  if (value instanceof Date) {
    return Number.isFinite(value);
  }
  if (value instanceof DateTime) {
    return value.isValid;
  }
  if (typeof value === "string") {
    return DateTime.fromISO(value).isValid;
  }
  return false;
});

defineRule("isDateRangePositive", (value: (Date | string | DateTime)[]) => {
  if (!Array.isArray(value)) {
    return false;
  }
  const [startTime, endTime] = value;
  if (typeof startTime !== typeof endTime) {
    return false;
  }
  if (typeof startTime === "string" && typeof endTime === "string") {
    return new Date(startTime) > new Date(endTime);
  }
  return startTime < endTime;
});

defineRule("double", (value: number) => {
  if (value == null || value?.toString?.()?.length < 1) {
    return false;
  }
  return Number(value) === value || value % 1 === 0;
});

defineRule("isItFromLTreeType", (value: string) => {
  if (value == null || value?.length < 1) {
    return true;
  }
  const ruleRegex = /[{}|()*+\-#?!^$/ /[\]\\äÄöÖüÜß/]/;
  return !ruleRegex.test(value);
});
