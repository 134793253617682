<template>
  <Teleport to="#breadcrumbs">
    <li class="breadcrumb-item active PortalToBreadcrumbScope">
      <slot />
    </li>
  </Teleport>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@import "../../assets/css/globalVariables.scss";

@media (min-width: $breakPointXL) {
  #breadcrumbs {
    min-width: 50ch;
  }
}

#breadcrumbs {
  min-height: calc(2ch + 1rem);
  display: flex;
  justify-content: center;
}
</style>
