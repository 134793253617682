import { createRouter, createWebHistory, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { AccessRight } from "./graphql";
import { isModuleActive } from "./functions/moduleConfig";
import { IDFilter } from "./components/event-action/filter/filterImplementations";
import store from "./store";
import { checkId } from "./mixins/redirectToBoard";

const PageNotFound = () => import("./views/PageNotFound.vue");

const Sites = () => import("./views/SiteList.vue");
const Site = () => import("./views/SiteComponent.vue");

const Departments = () => import("./views/DepartmentList.vue");

const Roles = () => import("./views/RoleList.vue");
const Role = () => import("./views/RoleComponent.vue");
const RoleAssignment = () => import("./views/RoleAssignment.vue");

const Users = () => import("./views/UserList.vue");
const User = () => import("./views/UserComponent.vue");

const TechnicalPlaces = () => import("./modules/organization/views/TechnicalPlaces.vue");
const CostCenters = () => import("./modules/organization/views/CostCenters.vue");
const CreateTechnicalPlace = () => import("./modules/organization/views/CreateTechnicalPlace.vue");
const CreateCostCenter = () => import("./modules/organization/views/CreateCostCenter.vue");
const UpdateTechnicalPlace = () => import("./modules/organization/views/UpdateTechnicalPlace.vue");
const UpdateCostCenter = () => import("./modules/organization/views/UpdateCostCenter.vue");

const ShiftSchedule = () => import("./views/ShiftSchedule.vue");
const ShiftSchedules = () => import("./views/ShiftSchedules.vue");

const DatapointTypes = () => import("./views/DatapointTypes.vue");
const DatapointType = () => import("./views/DatapointType.vue");
const BoardManagement = () => import("./views/BoardManagement.vue");
const BoardTemplates = () => import("./views/BoardTemplates.vue");
const BoardTemplate = () => import("./views/BoardTemplate.vue");

const PSCOverviewA3A5 = () => import("./views/psc/OverviewA3A5.vue");
const PSCOverviewKaizen = () => import("./views/psc/OverviewKaizen.vue");
const PSCArchive = () => import("./views/psc/Archive.vue");
const PSCCreate = () => import("./views/psc/Create.vue");
const PSCCreateKaizen = () => import("./views/psc/CreateKaizen.vue");
const PSCEdit = () => import("./views/psc/Edit.vue");

const Folder = () => import("./views/Folder/FolderComponent.vue");
const Documents = () => import("./views/Documents/DocumentList.vue");
const Document = () => import("./views/Documents/DocumentComponent.vue");

const SettingsColor = () => import("./views/systemSettings/SettingsColor.vue");
const GeneralSystemSettings = () => import("./views/systemSettings/GeneralSystemSettings.vue");
const DashLists = () => import("./views/Dashlist/Dashlists.vue");

const FlagList = () => import("./modules/flags/views/FlagList.vue");
const UpdateFlag = () => import("./modules/flags/views/UpdateFlag.vue");
const CreateFlag = () => import("./modules/flags/views/CreateFlag.vue");

const Changelog = () => import("./views/ChangelogView.vue");

const mailDistribution = () => import("./views/MailDistribution.vue");
const mailDistributions = () => import("./views/MailDistributions.vue");

const Redirect = () => import("./views/RedirectPage.vue");

// import routes from license
const licenseRouterAdditions: RouteRecordRaw[] = [];
const licenseRouters: Record<string, { default: RouteRecordRaw[] }> = import.meta.glob(
  "./license/**/router.ts",
  {
    eager: true,
  },
);

Object.entries(licenseRouters).forEach(([path, module]) => {
  if (isModuleActive(path.slice(2).split("/")[1])) {
    licenseRouterAdditions.push(...module.default);
  }
});

// import routes from modules
const modulesRouterAdditions: RouteRecordRaw[] = [];
const modulesRouters: Record<string, { default: RouteRecordRaw[] }> = import.meta.glob(
  "./modules/**/router.ts",
  {
    eager: true,
  },
);

Object.entries(modulesRouters).forEach(([_path, module]) => {
  modulesRouterAdditions.push(...module.default);
});

const routes: RouteRecordRaw[] = [
  {
    path: "/sites",
    name: "sites",
    component: Sites,
    meta: {
      rights: ["siteOrganization.readWrite"],
    },
  },
  {
    path: "/site/:id",
    name: "site",
    component: Site,
    meta: {
      rights: ["siteOrganization.readWrite"],
    },
  },
  {
    path: "/departments/:id(\\d+)",
    name: "departments",
    component: Departments,
    props: (route) => {
      return {
        id: parseInt(route.params.id.toString(), 10),
      };
    },
  },
  {
    path: "/departments",
    redirect: "/departments/0",
  },
  {
    path: "/user/:id/:createAnonym?",
    component: User,
    name: "User",
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      rights: ["user.write"],
    },
  },
  {
    path: "/kpiboard/:id?",
    name: "KPIBoard",
    redirect: (to) => ({
      name: "KPIBoardNew",
      params: { boardId: to.params.id ?? 1, ...to.params },
    }),
  },
  {
    path: "/sqdcBoard",
    name: "SQDCBoard",
    component: () => import("@/modules/sqdc/views/SQDCBoard.vue"),
    meta: {
      rights: ["sqdc.read"],
    },
    props: (route) => ({
      boardId: route.params?.boardId ? Number(route.params.boardId) : undefined,
    }),
    children: [
      {
        path: "kpi",
        name: "kpi",
        component: () => import("./components/shared/EmptyRouterView.vue"),
        beforeEnter: (to, from, next) => {
          if (["EventActionBoardNew", "eab"].includes(from.name?.toString() ?? "")) {
            to.query.transition = "slide-right";
          }
          next();
        },
        props: (route) => {
          return {
            transitionName: route.query.transition,
          };
        },

        children: [
          {
            path: ":boardId(\\d+)",
            name: "KPIBoardNew",
            component: () => import("./modules/sqdc/views/KPIBoard.vue"),
            props: (route) => ({
              boardId: Number(route.params.boardId),
            }),
          },
        ],
      },
      {
        path: "eab",
        name: "eab",
        component: () => import("./components/shared/EmptyRouterView.vue"),
        beforeEnter: (to, from, next) => {
          if (["KPIBoardNew", "kpi"].includes(from.name?.toString() ?? "")) {
            to.query.transition = "slide-left";
          }
          next();
        },
        props: (route) => {
          return {
            transitionName: route.query.transition,
          };
        },

        children: [
          {
            path: ":boardId(\\d+)",
            name: "EventActionBoardNew",
            component: () => import("./views/event-action/EventActionBoard.vue"),
            props: (route) => {
              let idFilter = {
                ids: [] as string[],
                type: route.query.scrollToType ?? undefined,
              } as { ids: string[]; type?: string } | undefined;

              if (idFilter != null && route.query.scrollToId != null) {
                if (!Array.isArray(route.query.scrollToId)) {
                  idFilter.ids = [route.query.scrollToId];
                } else {
                  idFilter.ids = route.query.scrollToId.filter((el): el is string => el != null);
                }
              } else {
                idFilter = undefined;
              }

              const archiveOptions: {
                archiveFrom?: string;
                archiveTo?: string;
              } = {};

              if (
                route.query.archiveFrom != null &&
                route.query.archiveTo != null &&
                typeof route.query.archiveFrom === "string" &&
                typeof route.query.archiveTo === "string"
              ) {
                archiveOptions.archiveFrom = route.query.archiveFrom;
                archiveOptions.archiveTo = route.query.archiveTo;
              }
              return {
                initialEventFilters: idFilter
                  ? [
                      new IDFilter(
                        idFilter.ids.map((id) => parseInt(id, 10)),
                        idFilter.type,
                      ),
                    ]
                  : [],
                ...archiveOptions,
                boardId: Number(route.params.boardId),
              };
            },
          },
        ],
      },
    ],
  },

  {
    path: "/taskboard/:id?",
    name: "Taskboard",
    redirect: (to) => ({ name: "EventActionBoardNew", params: { boardId: to.params.id } }),
  },
  {
    path: "/shiftSchedules",
    name: "shiftSchedules",
    component: ShiftSchedules,
    meta: {
      rights: ["shiftSchedule.write"],
    },
  },
  {
    path: "/shiftSchedule/:id",
    name: "shiftSchedule",
    component: ShiftSchedule,
    meta: {
      rights: ["shiftSchedule.write"],
    },
  },
  {
    path: "/boardManagement/:id?",
    name: "boardManagement",
    component: BoardManagement,
    meta: {
      rights: ["sqdc.admin"],
    },
    beforeEnter: async (to, from, next) => {
      await checkId(to, from, next);
    },
  },
  {
    path: "/mailDistributions",
    name: "mailDistributions",
    component: mailDistributions,
    meta: {
      rights: ["sqdc.mailDistribution"],
    },
  },
  {
    path: "/mailDistribution/:id",
    name: "mailDistribution",
    component: mailDistribution,
    meta: {
      rights: ["sqdc.mailDistribution"],
    },
  },
  {
    path: "/datapointTypes",
    name: "datapointTypes",
    component: DatapointTypes,
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/datapointType/:id",
    name: "datapointType",
    component: DatapointType,
    meta: {
      rights: ["sqdc.admin"],
    },
    props: (route) => ({ datapointTypeId: route.params.id }),
  },
  {
    path: "/boardTemplates",
    name: "boardTemplates",
    component: BoardTemplates,
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/boardTemplate/:id",
    name: "boardTemplate",
    component: BoardTemplate,
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/psc/overviewA3A5",
    name: "PSCOverviewA3A5",
    component: PSCOverviewA3A5,
    meta: {
      closed: false,
      rights: ["psc.pscActive.read"],
    },
  },
  {
    path: "/psc/overviewKaizen",
    name: "PSCOverviewKaizen",
    component: PSCOverviewKaizen,
    meta: {
      closed: false,
      rights: ["kaizen.kaizenActive.read"],
    },
  },
  {
    path: "/psc/archive",
    name: "PSCArchive",
    component: PSCArchive,
    meta: {
      closed: true,
      accessRightSome: true,
      rights: ["psc.pscInactive.read", "kaizen.kaizenInactive.read"],
    },
  },
  {
    path: "/psc/create",
    name: "PSCCreate",
    component: PSCCreate,
    meta: {
      rights: ["psc.pscActive.write"],
    },
  },
  {
    path: "/psc/createKaizen",
    name: "PSCCreateKaizen",
    component: PSCCreateKaizen,
    meta: {
      rights: ["kaizen.kaizenActive.write"],
    },
  },
  {
    path: "/psc/:id",
    name: "PSCEdit",
    component: PSCEdit,
    meta: {
      accessRightSome: true,
      rights: [
        "psc.pscActive.read",
        "kaizen.kaizenActive.read",
        "psc.pscInactive.read",
        "kaizen.kaizenInactive.read",
      ],
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: Roles,
    meta: {
      rights: ["accessRight.readWrite"],
    },
  },
  {
    path: "/role/:id",
    name: "role",
    component: Role,
    meta: {
      rights: ["accessRight.readWrite"],
    },
    props: true,
  },
  {
    path: "/roleAssignment",
    name: "roleAssignment",
    component: RoleAssignment,
    meta: {
      rights: ["user.role"],
    },
    props: true,
  },
  {
    path: "/documents",
    name: "documents",
    component: Documents,
    meta: {
      rights: ["document.read"],
    },
  },
  {
    path: "/document/:id",
    component: Document,
    meta: {
      rights: ["document.read"],
    },
  },
  {
    path: "/dashlists",
    name: "dashlists",
    component: DashLists,
    meta: {
      rights: ["sqdc.admin"],
    },
  },
  {
    path: "/folder",
    name: "folder",
    component: Folder,
    meta: {
      rights: ["folder.read"],
    },
  },
  {
    path: "/technicalPlaces",
    name: "TechnicalPlaces",
    component: TechnicalPlaces,
    meta: {
      rights: ["systemSettings.write"],
    },
  },
  {
    path: "/costCenters",
    name: "CostCenters",
    component: CostCenters,
    meta: {
      rights: ["systemSettings.write"],
    },
  },
  {
    path: "/technicalPlace",
    name: "CreateTechnicalPlace",
    component: CreateTechnicalPlace,
    meta: {
      rights: ["systemSettings.write"],
    },
  },
  {
    path: "/costCenter",
    name: "CreateCostCenter",
    component: CreateCostCenter,
    meta: {
      rights: ["systemSettings.write"],
    },
  },
  {
    path: "/costCenter/:id(\\d+)",
    name: "UpdateCostCenter",
    component: UpdateCostCenter,
    meta: {
      rights: ["systemSettings.write"],
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: "/technicalPlace/:id(\\d+)",
    name: "UpdateTechnicalPlace",
    component: UpdateTechnicalPlace,
    meta: {
      rights: ["systemSettings.write"],
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      rights: ["systemSettings.write"],
    },
    children: [
      {
        path: "color",
        name: "settingsColor",
        meta: {
          rights: ["systemSettings.write"],
        },
        component: SettingsColor,
      },
      {
        path: "general",
        name: "generalSettings",
        meta: {
          rights: ["systemSettings.write"],
        },
        component: GeneralSystemSettings,
      },
    ],
  },
  {
    path: "/flags",
    name: "flags",
    component: FlagList,
    meta: {
      rights: ["flags.readWrite"],
    },
  },
  {
    path: "/updateFlag/:id(\\d+)",
    name: "UpdateFlag",
    component: UpdateFlag,
    meta: {
      rights: ["flags.readWrite"],
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: "/createFlag",
    name: "CreateFlag",
    component: CreateFlag,
    meta: {
      rights: ["flags.readWrite"],
    },
  },
  {
    // Filler site for menu entries without a link
    path: "/dashboard",
    name: "dashboard",
    component: () => import("./modules/dashboard/views/DBDashboardView.vue"),
  },
  {
    path: "/changelog/:new",
    name: "ChangelogNew",
    component: Changelog,
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog,
  },
  ...licenseRouterAdditions,
  ...modulesRouterAdditions,
  {
    // Filler site for menu entries without a link
    path: "/redirect/:moduleName",
    name: "redirect",
    component: Redirect,
    props: (route) => ({ moduleName: route.params.moduleName }),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const routerPush = router.push;
router.push = function push(to: RouteLocationRaw) {
  return routerPush(to).catch((err) => {
    throw err;
  });
};

const routerReplace = router.replace;
router.replace = function replace(to: RouteLocationRaw) {
  return routerReplace(to).catch((err) => {
    throw err;
  });
};

export default router;

router.afterEach((to, _from) => {
  const softwareName = [import.meta.env.DEV ? "DEV" : "LISA"];
  if (window.$i18n?.te(`router.${to.name?.toString()}`) || import.meta.env.DEV) {
    softwareName.push(` | ${window.$i18n?.t(`router.${to.name?.toString()}`)}`);
  }
  document.title = softwareName.join("");
});

router.beforeEach((to, from, next) => {
  if (to.path === "/") {
    if (store.getters["login/initialPage"] != null && store.getters["login/initialPage"] !== "/") {
      router.push(store.getters["login/initialPage"]);
    }
    return;
  }
  // Check if the user has the needed right to go to his destination
  if (
    to.meta?.rights &&
    Array.isArray(to.meta?.rights) &&
    ((!to.meta?.accessRightSome &&
      !to.meta?.rights.every((right: AccessRight) =>
        store.getters["login/hasAccessRight"](right),
      )) ||
      (to.meta?.accessRightSome &&
        !to.meta?.rights.some((right: AccessRight) =>
          store.getters["login/hasAccessRight"](right),
        )))
  ) {
    // If the user is missing a right he should be redirected to a "neutral" site
    next("/404");
  } else {
    if (!from.meta?.notSaveLastPage) {
      store.dispatch("login/updateLastPage", from.path);
    }
    next();
  }
});
