export default {
  endpoints: {
    getAll: {
      route: "/",
      method: "GET",
    },
    get: {
      route: ":id",
      method: "GET",
    },
    getInFolder: {
      route: "inFolder",
      method: "POST",
    },
    checkName: {
      route: "nameCheck",
      method: "POST",
      ignoreError: true,
    },
    getPDF: {
      route: "pdf/:id",
      method: "GET",
    },
    updateFile: {
      route: "file/:id",
      method: "PUT",
    },
    rotateFile: {
      route: "rotateFile/:id",
      method: "PUT",
    },
    deletePDFPage: {
      route: "deletePDFPage/:id",
      method: "DELETE",
    },
    reorderPDF: {
      route: "reorderPDF",
      method: "PUT",
    },
    create: {
      route: "/",
      method: "POST",
    },
    addFile: {
      route: "file/:id",
      method: "POST",
    },
    update: {
      route: "document/:id",
      method: "PUT",
    },
    deleteFile: {
      route: ":id/file/:fileId",
      method: "DELETE",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
  },
};
